<style type="text/css">
.svg-bg-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 15vw;
  z-index: 1;
  /* set height to pixels if you want angle to change with screen width */
}
.svg-bg-bottom-first {
  height: 25vw;
}
.svg-bg-top {
  position: absolute;
  top: 0;
  width: 100%;
  height: 15vw;
  z-index: 1;
  /* set height to pixels if you want angle to change with screen width */
}
.carousel-cell {
  width: 90vw;
  max-width: 90vw;
  margin-bottom: 40px;
}

/* position dots up a bit */
.flickity-page-dots {
  top: -22px;
  height: 20px;
}
.flickity-page-dots .dot {
  width: 33.33333%;
  height: 3px;
  margin: 0;
  border-radius: 0;
  @apply bg-secondary;
}
@media (min-width: 640px) {
  .carousel-cell {
    width: 50vw;
    max-width: 50vw;
  }
}

.screenshot {
  height: 500px;
}
</style>
<template>
  <div>
    <div class="bg-gradient-to-br from-primary to-secondary relative px-3 md:px-0">
      <div class="flex justify-center my-10">
        <img alt="Samay Logo" src="@/assets/logo.svg" width="140" height="140">
      </div>
      <div class="container mx-auto relative z-10">
          <h1 class="text-xl2 md:text-5xl font-semibold text-center my-6 drop-shadow-md">
            {{$t('lead-title')}}
          </h1>
          <p class="text-center text-xl md:text-2xl mt-8 mb-20" v-html="$t('lead-text')"></p>
      </div>
      <div class="relative z-10">
        <flickity class="carousel-main" ref="flickity" :options="flickityOptions">
          <div class="carousel-cell bg-white shadow-xl rounded-lg mx-10">
            <BrowserBar></BrowserBar>
            <div class="relative pb-2/3">
              <img class="absolute h-full w-full object-contain" src="@/assets/screenshots/preview_dashboard.png" alt="Screenshot Dashboard" >
            </div>
          </div>

          <div class="carousel-cell bg-white shadow-xl rounded-lg mx-10">
            <BrowserBar></BrowserBar>
            <div class="relative pb-2/3">
              <img class="absolute h-full w-full object-contain" src="@/assets/screenshots/preview_profile.png" alt="Screenshot Profile" >
            </div>
          </div>

          <div class="carousel-cell bg-white shadow-xl rounded-lg mx-10">
            <BrowserBar></BrowserBar>
            <div class="relative pb-2/3">
              <img class="absolute h-full w-full object-contain" src="@/assets/screenshots/preview_absences.png" alt="Screenshot Absences" >
            </div>
          </div>
        </flickity>
        </div>
      <svg class="svg-bg-bottom svg-bg-bottom-first text-white dark:text-black fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" preserveAspectRatio="none">
        <path d="M 0 200 V 100 C 100 100 100 0 200 0 V 200"/>
      </svg>
    </div>
    <div id="webapp" class="text-center bg-white dark:bg-black">
      <div class="container mx-auto pt-10 px-2 text-gray-800 dark:text-gray-200">
      <flickity class="text-left" ref="flickityNav" :options="flickityOptionsNav">
          <div class="w-full sm:w-1/3 h-auto py-5 pl-1 pr-8" @mouseover="select(0)">
            <span class="text-xl font-semibold mb-3 inline-block">{{$t('dashboard')}}</span>
            <p class="leading-relaxed text-base">
              {{$t('screenshot-dashboard')}}
            </p>
            <p class="text-sm text-gray-500 mt-8">
              {{$t('interface-hint')}}
            </p>
          </div>

          <div class="w-full sm:w-1/3 py-5 pl-1 pr-8">
          <div @mouseover="select(1)">
            <span class="text-xl font-semibold mb-3 inline-block">{{$t('target-times')}} / {{$t('part-time')}}</span>
            <p class="leading-relaxed text-base" v-html="$t('screenshot-profile')">
            </p>
          </div>
          </div>

          <div class="w-full sm:w-1/3 py-5 pl-1 pr-8"  @mouseover="select(2)">
            <span class="text-xl font-semibold mb-3 inline-block">{{$t('absences-and-holidays')}}</span>
            <p class="leading-relaxed text-base">{{$t('screenshot-absences')}}</p>
          </div>

        </flickity>

        <p class="text-2xl mt-20" v-html="$t('lead-try')">
        </p>
        <router-link class="flex justify-center my-10 h-16 b-10" to="/app">
          <img v-if="$i18n.locale.startsWith('de')" src="@/assets/img/open-webapp-de.png" alt="Öffne die Webapp" class="object-contain h-16">
          <img v-else src="@/assets/img/open-webapp-en.png" alt="Open Webapp" class="object-contain h-16">
        </router-link>
        <div class="h-1"></div>
      </div>
    </div>
    <div id="download" class="bg-gradient-to-br from-secondary to-primary relative">
      <svg class="svg-bg-top text-white dark:text-black fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 0 C50 0 50 100 100 100 L100 0"/>
      </svg>
      <div class="container mx-auto py-48 relative z-10 px-2">
        <div class="flex justify-between items-center flex-wrap sm:flex-no-wrap">
          <div class="text-center w-full">
              <h1 class="text-xl2 md:text-5xl font-semibold mb-5 mt-5 drop-shadow-md">{{$t('no-connection')}}</h1>
              <p class="text-xl md:text-2xl" v-html="$t('lead-no-problem')">
              </p>
              <div class="flex items-center justify-center mt-5">
                <a class="w-1/2 flex justify-end" href="https://apps.apple.com/ch/app/samay/id1441944333" target="_blank">
                  <img v-if="$i18n.locale.startsWith('de')" src="@/assets/img/app-store-badge-de.svg" alt="AppStore Link" class="object-contain h-16">
                  <img v-else src="@/assets/img/app-store-badge-en.svg" alt="AppStore Link" class="object-contain h-16">
                </a>
                <a class="w-1/2 flex justify-start" href="https://play.google.com/store/apps/details?id=ch.samay.samay" target="_blank">
                  <img v-if="$i18n.locale.startsWith('de')" src="@/assets/img/google-play-badge-de.png" alt="PlayStore Link" class="object-contain h-24">
                  <img v-else src="@/assets/img/google-play-badge-en.png" alt="PlayStore Link" class="object-contain h-24">
                </a>
              </div>
          </div>
          <div class="flex items-center justify-center w-full">
              <img src="@/assets/screenshots/preview1.png" alt="Screenshot Preview iOS" width="375" height="">
          </div>
        </div>
      </div>

      <svg class="svg-bg-bottom text-white dark:text-black fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 100 C50 100 50 0 100 0 L100 100"/>
      </svg>
    </div>

    <div id="price" class="text-center bg-white dark:bg-black text-gray-800 dark:text-gray-200">
      <div class="container mx-auto py-10 px-2">
        <h1 class="text-xl2 md:text-5xl font-semibold mb-5">{{$t('lead-title-overview')}}</h1>
        <p class="text-xl md:text-2xl" v-html="$t('lead-text-overview')"></p>

      <div class="flex flex-wrap justify-center mt-4">
        <div class="w-full sm:w-1/2 xl:w-1/3 px-10 py-2 sm:py-2 sm:px-4">
          <div class="shadow-xl rounded-lg bg-primary text-white">
            <div class="text-3xl rounded-t p-3">
              {{$t('team')}}
            </div>
            <div class="px-10 text-left">
              <div class="flex items-center mb-3">
                  <div class="flex items-center">
                    <div class="text-4xl">{{$t('base-price')}}</div>
                    <div class="text-xs text-left ml-3">{{$t('per-user')}} <br> {{$t('and-month')}}</div>
                  </div>
              </div>
              <ul class="list-disc my-3 px-5">
                <li class="py-1">{{$t('list-mobile-edit')}}</li>
                <li class="py-1">{{$t('list-offline')}}</li>
                <li class="py-1">{{$t('list-project')}}</li>
                <li class="py-1">{{$t('list-realtime-sync')}}</li>
                <li class="py-1">{{$t('list-team-management')}}</li>
                <li class="py-1">{{$t('list-part-time-support')}}</li>
                <li class="py-1">{{$t('list-target-time')}}</li>
                <li class="py-1">{{$t('list-absences')}}</li>
                <li class="py-1">{{$t('list-export')}}</li>
              </ul>
              <router-link class="block text-center w-full py-2 my-6 px-4 rounded bg-secondary text-white hover:bg-opacity-75" to="/app">{{$t('try-free')}}</router-link>
              <p class="text-center py-4 text-gray-300 text-xs">
                {{$t('try-7-days')}} <br>
                {{$t('no-creditcard')}}
              </p>
            </div>
          </div>
        </div>
        <div class="w-full sm:w-1/2 xl:w-1/3 px-10 py-2 sm:py-2 sm:px-4">
          <div class="shadow-xl rounded-lg bg-secondary text-white h-full">
            <div class="text-3xl rounded-t p-3">
              {{$t('one-user')}}
            </div>
            <div class="px-10 text-left">
              <div class="text-4xl">
                {{$t('free')}}
              </div>
              <p class="mt-3">
              {{$t('free-text')}}
              </p>
              <div class="mt-5">
                  <a class="flex justify-center items-center" href="https://apps.apple.com/ch/app/samay/id1441944333" target="_blank">
                    <img v-if="$i18n.locale.startsWith('de')" src="@/assets/img/app-store-badge-de.svg" alt="AppStore Link" class="object-contain h-16">
                    <img v-else src="@/assets/img/app-store-badge-en.svg" alt="AppStore Link" class="object-contain h-16">
                  </a>
                  <a class="flex justify-center items-center" href="https://play.google.com/store/apps/details?id=ch.samay.samay" target="_blank">
                    <img v-if="$i18n.locale.startsWith('de')" src="@/assets/img/google-play-badge-de.png" alt="PlayStore Link" class="object-contain h-24">
                    <img v-else src="@/assets/img/google-play-badge-en.png" alt="PlayStore Link" class="object-contain h-24">
                  </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="contact" class="bg-gradient-to-br from-primary to-secondary relative z-40">
      <svg class="svg-bg-top text-white dark:text-black fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 0 C50 0 50 100 100 100 L100 0"/>
      </svg>
      <div class="container mx-auto text-center pt-56 px-2">
        <h1 class="text-xl2 md:text-5xl font-semibold mb-4 mt-2 drop-shadow-md">{{$t('lead-title-not-sure')}}</h1>
        <div class="flex justify-center">
        <a class="bg-secondary w-32 h-32 rounded-full flex items-center justify-center hover:bg-opacity-75" href="mailto:hello@samay.ch">
          <svg class="fill-current h-20 w-20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"/></svg>
        </a>
      </div>
        <div class="mt-6 text-base flex justify-center items-center">
          <a class="p-2 hover:underline" href="https://www.instagram.com/8_samay_8/" target="_blank" rel="noreferrer">{{$t('instagram')}}</a>
          | <router-link class="p-2 hover:underline" to="/privacy">{{$t('privacy')}}</router-link>
          | <router-link class="p-2 hover:underline" to="/terms">{{$t('terms')}}</router-link>
          | <LocaleChanger class="ml-2"></LocaleChanger>
        </div>
            <p class="py-5 text-white text-opacity-50">
              © {{new Date().getFullYear()}} samay.ch
            </p>
        </div>
    </div>

</div>
</template>

<script>
import Flickity from 'vue-flickity'
import 'flickity-as-nav-for'
import BrowserBar from '@/components/BrowserBar'
import LocaleChanger from '@/components/LocaleChanger'

export default {
  components: {
    Flickity,
    BrowserBar,
    LocaleChanger
  },
  data () {
    return {
      flickityOptions: {
        initialIndex: 3,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false
      },
      flickityOptionsNav: {
        asNavFor: '.carousel-main',
        contain: true,
        prevNextButtons: false,
        pageDots: true,
        draggable: false
      }
    }
  },
  methods: {
    select (index) {
      this.$refs.flickity.select(index)
    }
  }
}
</script>
