<style scoped>
.approved {
  background-color: #4cd964;
}
</style>

<template>
<div>
  <div class="flex items-center">
    <span class="text-4xl">{{$t('absences')}}</span>
  </div>
  <ul class="flex mb-4 py-2 border-b-2 border-gray-400">
  <li class="mr-1">
    <a @click="changeTab(1)" class="inline-block px-4 py-1 font-bold text-gray-800 rounded-full hover:bg-primary hover:bg-opacity-25 hover:text-primary"
       :class="{'text-primary' : activeTab === 1}" href="#">
       {{$t('my-absences')}}
    </a>
  </li>
  <li class="mr-1">
    <a @click="changeTab(2)" class="inline-block px-4 py-1 font-bold text-gray-800 rounded-full hover:bg-primary hover:bg-opacity-25 hover:text-primary"
      :class="{'text-primary' : activeTab === 2}" href="#">
      {{$t('overview')}}
    </a>
  </li>
</ul>
  <div class="flex flex-wrap lg:flex-nowrap mb-6">
    <div class="w-full lg:w-9/12">
      <div class="flex my-3 pb-1 border-b border-gray-400">
        <div class="cursor-pointer opacity-50 hover:text-primary hover:bg-primary hover:bg-opacity-25 rounded-full px-2 py-1" @click="updateYear(currentYear-1)">{{currentYear-1}}</div>
        <div class="select-none font-semibold text-primary hover:text-primary hover:bg-primary hover:bg-opacity-25 rounded-full px-2 py-1">{{currentYear}}</div>
        <div class="cursor-pointer opacity-50 hover:text-primary hover:bg-primary hover:bg-opacity-25 rounded-full px-2 py-1" @click="updateYear(currentYear+1)">{{currentYear+1}}</div>
      </div>
      <div v-if="activeTab===2" class="overflow-x-auto">
        <div class="flex my-3 pb-1 border-b border-gray-400">
          <div v-for="month in 12" :key="month" class="font-semibold text-xs cursor-pointer text-gray-800 hover:text-primary hover:bg-primary hover:bg-opacity-25 rounded-full px-2 py-1"
            :class="{'text-primary': month === activeMonth}"
            @click="updateMonth(month)"
          >
            {{monthName(month, currentYear)}}
          </div>
        </div>
        <div>
          <div class="pt-2">
            <div class="flex">
              <div class="shrink-0 w-32"></div>
              <div v-for="day in daysInMonth(activeMonth)" :key="day" class="w-6 h-4 flex items-center justify-center text-xxs select-none" :class="{'mr-3': isSunday(day, activeMonth)}">
                {{dayName(day, activeMonth)}}
              </div>
            </div>
            <div class="flex mt-2 flex-col">
              <div v-for="u in users" :key="u._id" class="flex items-center mb-1">
                <div class="shrink-0 w-32 text-xs px-2 truncate" :class="{'font-bold': u._id === user._id}">
                  {{u.name}}
                </div>
                <div class="flex">
                  <div v-for="day in daysInMonth(activeMonth)" :key="day" class="w-6 h-6 flex items-center justify-center text-xs select-none cursor-default"
                  :class="{
                    'bg-secondary text-white hover:bg-opacity-80 cursor-pointer': hasAbsence(day, activeMonth, 0, u),
                    'rounded-l-full': isLeftRounded(day, activeMonth, u),
                    'rounded-r-full': isRightRounded(day, activeMonth, u),
                    'opacity-25': isWeekend(day, activeMonth),
                    'mr-3': isSunday(day, activeMonth)
                    }"
                      @mouseover="showAt($event, day, activeMonth, true, u);activeIndex=currDate(day, activeMonth)"
                      @mouseout="hideTooltip()"
                    >
                    {{day}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="overflow-x-auto">
        <div class="absolute w-full ml-1 text-ml mb-5 mt-4">
          <span class="font-bold">{{ $t('vacation-balance') }}:</span> {{formatSecondsToH(vacationSaldo)}} ({{formatSecondsToDays(vacationSaldo, team.hoursPerWeek || 42)}})
        </div>
        <div class="mt-16 border border-gray-300 bg-gray-100 dark:border-gray-700 dark:bg-gray-900 rounded inline-block px-4 py-2">
        <div v-for="month in 12" :key="month" class="text-xs" @click="currentMonth=month">
          <div class="font-semibold mt-2 mb-1 ml-1">{{monthName(month, currentYear)}}</div>
          <div class="flex items-start">
            <div v-for="day in daysInMonth(month)" :key="day" class="flex flex-col items-center justify-center">
              <div class="text-xxs mb-1" :class="{'mr-3': isSunday(day, month)}">
                {{dayName(day, month)}}
              </div>
              <div class="text-xs cursor-pointer w-6 h-6 flex items-center justify-center"
                :class="{
                  'opacity-25': isWeekend(day, month),
                  'hover:bg-green-600 hover:text-white': !isWeekend(day, month),
                  'bg-green-600 rounded-none text-white': isInRange(day, month),
                  'rounded-l-full': isLeftRounded(day, month),
                  'rounded-r-full': isRightRounded(day, month),
                  'cursor-not-allowed': isNotAllowed(day, month),
                  'text-white font-bold border border-red-800 rounded-full bg-red-600': isToday(day, month) && !hasAbsence(day, month),
                  'text-green-300': isToday(day, month) && hasAbsence(day, month),
                  'mr-3': isSunday(day, month),
                  'bg-secondary text-white hover:bg-opacity-80': hasAbsence(day, month),
                  'bg-green-700 text-white': getAbsence(day, month) && currRange.indexOf(getAbsence(day, month)._id) > -1,
                  'approved': getAbsence(day, month) && getAbsence(day, month).isApproved
                }"
                @mouseover="showAt($event, day, month);activeIndex=currDate(day, month)"
                @mouseout="hideTooltip()"
                @click="!isWeekend(day, month) ? updateRange() : null"
              >
                {{day}}
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div v-if="isAdmin" class="w-full lg:w-3/12 lg:ml-3 lg:px-3 flex flex-col lg:border-l border-gray-200">
      <div class="flex items-center mt-4 mb-1">
      <span class="inline-block pb-1 mr-2 font-bold">{{$t('absence-type')}}</span>
      <EditItemForm class="" @on-item-added='onItemAdded' :columns="columns" :item="newItem" :small="true" showAddForm/>
      </div>
      <Grid
        :small="true"
        :items="absProjects"
        :columns="columns">
        <template slot="actions" slot-scope="{ item }">
          <EditItemForm v-if="!item.isVacation" @on-item-changed="onItemChanged" :item="item" :columns="columns" :small="true"></EditItemForm>
        </template>
      </Grid>
    </div>
  </div>
  <NewAbsence v-if="showNewAbcence" :open="showNewAbcence" :range="range" @on-close="reset()"></NewAbsence>
  <div v-show="showTooltip" @mouseover="clearTooltipTimeout()" @mouseout="hideTooltip()" ref="tooltip" class="absolute shadow-md rounded mx-2 text-xs bg-gray-700 text-white p-2 leading-relaxed">
    <div class="relative">
      <div class="flex-row justify-center items-center">
        <span>{{currentAbsence ? getProjectById(currentAbsence.projectId).name : ''}}</span>
        <div v-if="!isReadOnlyTooltip" @click="removeAbsence(currentAbsence);showTooltip=false" class="px-1 mt-2 text-xs bg-red-600 rounded cursor-pointer hover:bg-red-500">
          {{ $t('remove-current') }}
        </div>
        <div v-if="!isReadOnlyTooltip" @click="removeAllAbsence(currRange);showTooltip=false" class="mt-1 px-1 bg-red-600 rounded cursor-pointer hover:bg-red-500">
          {{ $t('remove-selection') }}
        </div>
      </div>
    <svg class="absolute left-0 bottom-0 -mb-4 fill-current text-gray-700" width="33" height="10" viewBox="0 0 33 25" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5 24L32 0H0z"/>
    </svg>
    </div>
  </div>
</div>
</template>

<script>
import dayjs from 'dayjs'

import dateMixin from '@/mixin/dateMixin.js'
import NewAbsence from '@/components/absences/NewAbsence'
import EditItemForm from '@/components/EditItemForm.vue'
import Grid from '@/components/Grid.vue'
import { mapActions, mapGetters } from 'vuex'

const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export default {
  components: {
    NewAbsence,
    EditItemForm,
    Grid
  },
  mixins: [dateMixin],
  data () {
    return {
      activeTab: 1,
      columns: [{ key: 'name', value: 'Bezeichnung', class: '' }],
      newItem: {},
      currentAbsence: null,
      showNewAbcence: false,
      isReadOnlyTooltip: false,
      range: null,
      activeIndex: {},
      startIndex: null,
      closeTooltip: null,
      endIndex: null,
      showTooltip: false,
      currentYear: parseInt(dayjs().format('YYYY')),
      vacationTotal: 0,
      currentMonth: dayjs().format('M'),
      activeMonth: parseInt(dayjs().format('M')),
      months: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
      currRangeIds: []
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
      absProjects: 'projects/absences',
      getAbsenceByDate: 'worktimes/getAbsenceByDate',
      getProjectById: 'projects/getById',
      getBalances: 'balances/getVacationByUserAndYear',
      users: 'users/active',
      user: 'user',
      team: 'team',
      abcencesSorted: 'worktimes/allSorted'
    }),
    currentMonthStr () {
      return dayjs().format('MMMM') || ''
    },
    vacationBalances () {
      return this.getBalances(this.user._id, this.currentYear)
    },
    vacationSaldo () {
      return this.vacationBalances * 3600 - this.vacationTotal
    },
    lastMonth () {
      return dayjs().format('M') - 1 || 0
    },
    currRange () {
      return this.currRangeIds
    }
  },
  methods: {
    ...mapActions({
      removeAbsence: 'worktimes/remove',
      removeAllAbsence: 'worktimes/removeAll',
      addProject: 'projects/add',
      updateProject: 'projects/update',
      loadAbsences: 'worktimes/loadAbsences',
      loadMineAbsencesByYear: 'worktimes/loadMineAbsencesByYear',
      totalVacationForRange: 'worktimes/totalVacationForRange'
    }),
    async changeTab (tab) {
      if (tab === 1) {
        this.loadMineAbsencesByYear(this.currentYear)
        const date = dayjs().year(this.currentYear)
        this.vacationTotal = await this.totalVacationForRange({ userId: this.user._id, startDate: date.startOf('year'), endDate: date.endOf('year') })
      } else if (tab === 2) {
        this.loadAbsences({ month: this.currentMonth, year: this.currentYear })
      }
      this.activeTab = tab
    },
    updateMonth (month) {
      this.loadAbsences({ month: month, year: this.currentYear })
      this.activeMonth = month
    },
    async updateYear (year) {
      if (this.activeTab === 1) {
        this.loadMineAbsencesByYear(year)
        const date = dayjs().year(year)
        this.vacationTotal = await this.totalVacationForRange({ userId: this.user._id, startDate: date.startOf('year'), endDate: date.endOf('year') })
      } else if (this.activeTab === 2) {
        this.loadAbsences({ month: this.currentMonth, year: year })
      }
      this.currentYear = year
    },
    hasAbsence (day, month, offset = 0, user = null) {
      const currDate = dayjs(this.currDate(day, month).toString(), 'YYYYMMDD').add(offset, 'day')
      return this.getAbsenceByDate(currDate, user || this.user) !== undefined
    },
    getAbsence (day, month, offset = 0, user = null) {
      const currDate = dayjs(this.currDate(day, month).toString(), 'YYYYMMDD').add(offset, 'day')
      return this.getAbsenceByDate(currDate, user || this.user)
    },
    isNotAllowed (day, month) {
      const curr = this.currDate(day, month)
      return this.isWeekend(day, month) || (this.hasAbsence(day, month) && curr === this.activeIndex && this.startIndex)
    },
    isLeftRounded (day, month, user = null) {
      const curr = this.currDate(day, month)
      return (this.startIndex < this.activeIndex && curr === this.startIndex && !this.hasAbsence(day, month, 0, user)) ||
             (this.startIndex > this.activeIndex && curr === this.activeIndex && !this.hasAbsence(day, month, 0, user)) ||
             (this.endIndex && this.startIndex === curr) ||
             (!this.endIndex && this.startIndex === this.activeIndex && !this.hasAbsence(day, month, 0, user)) ||
             (!this.endIndex && !this.startIndex && this.activeIndex === curr && !this.hasAbsence(day, month, 0, user)) ||
             (this.hasAbsence(day, month, 0, user) && !this.hasAbsence(day, month, -1, user))
    },
    isRightRounded (day, month, user = null) {
      const curr = this.currDate(day, month)
      return (!this.endIndex && this.startIndex > this.activeIndex && curr === this.startIndex && !this.hasAbsence(day, month, 0, user)) ||
             (!this.endIndex && this.startIndex < this.activeIndex && curr === this.activeIndex && !this.hasAbsence(day, month, 0, user)) ||
             (this.endIndex === curr) ||
             (!this.endIndex && this.startIndex === this.activeIndex && !this.hasAbsence(day, month, 0, user)) ||
             (this.hasAbsence(day, month, 0, user) && !this.hasAbsence(day, month, 1, user))
    },
    updateRange () {
      if (!this.startIndex || this.endIndex) {
        this.startIndex = this.activeIndex
        this.endIndex = null
      } else {
        if (this.activeIndex < this.startIndex) {
          this.endIndex = this.startIndex
          this.startIndex = this.activeIndex
        } else {
          this.endIndex = this.activeIndex
        }
        this.range = { from: this.startIndex, to: this.endIndex }
        this.showNewAbcence = true
      }
    },
    currDate (day, month) {
      return parseInt(`${this.currentYear}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`)
    },
    daysInMonth (month) {
      return dayjs(`${month}-${this.currentYear}`, 'M-YYYY').daysInMonth()
    },
    monthName (month, year) {
      return dayjs(`${month}`, 'M').locale(this.$i18n.locale).format('MMMM')
    },
    dayName (day, month) {
      return dayjs(`${day}-${month}-${this.currentYear}`, 'D-M-YYYY').locale(this.$i18n.locale).format('dd')
    },
    isWeekend (day, month) {
      const currDay = dayjs(`${day}-${month}-${this.currentYear}`, 'D-M-YYYY').day()
      return currDay === 0 || currDay === 6
    },
    isSunday (day, month) {
      const currDay = dayjs(`${day}-${month}-${this.currentYear}`, 'D-M-YYYY').day()
      return currDay === 0
    },
    isToday (day, month) {
      return dayjs(`${day}-${month}-${this.currentYear}`, 'D-M-YYYY').isSame(dayjs(), 'day')
    },
    isInRange (day, month) {
      const curr = this.currDate(day, month)

      if (!this.endIndex) {
        if (this.startIndex) {
          return (curr <= this.activeIndex && curr >= this.startIndex) || (curr >= this.activeIndex && curr <= this.startIndex)
        }
        return false
      }

      return (curr >= this.startIndex && curr <= this.endIndex)
    },
    reset () {
      this.startIndex = null
      this.endIndex = null
      this.range = null
      this.showNewAbcence = false
    },
    showAt (e, day, month, readOnly, user = null) {
      const absence = this.getAbsence(day, month, 0, user || this.user)
      if (absence) {
        this.collectAbcensesRange(absence)
        this.clearTooltipTimeout()
        this.showTooltip = true
        this.isReadOnlyTooltip = absence.isApproved || readOnly || false
        this.currentAbsence = absence

        const left = e.target.offsetLeft - 22 + 'px'
        const top = e.target.offsetTop - (readOnly ? 42 : 92) + 'px'

        const div = this.$refs.tooltip

        div.style.left = left
        div.style.top = top
      }
    },
    collectAbcensesRange (abcence) {
      this.currRangeIds = []
      const index = this.abcencesSorted.indexOf(abcence)
      let search = true
      let lastWk = abcence
      this.currRangeIds.push(abcence._id)
      // Search forward
      let ix = index + 1
      while (search) {
        if (ix > this.abcencesSorted.length - 1) {
          search = false
        } else {
          const wk = this.abcencesSorted[ix]
          const wkDate = dayjs(wk.date)
          const diff = Math.abs(wkDate.diff(dayjs(lastWk.date), 'day'))

          if (lastWk.projectId.toString() === wk.projectId.toString() && (diff === 1 || (diff === 3 && wkDate.day() === 1))) {
            this.currRangeIds.push(this.abcencesSorted[ix]._id)
            lastWk = wk
            ix += 1
          } else {
            search = false
          }
        }
      }
      ix = index - 1
      search = true
      lastWk = abcence
      while (search) {
        if (ix < 0) {
          search = false
        } else {
          const wk = this.abcencesSorted[ix]
          const wkDate = dayjs(wk.date)
          const diff = Math.abs(wkDate.diff(dayjs(lastWk.date), 'day'))
          if (lastWk.projectId.toString() === wk.projectId.toString() && (diff === 1 || (diff === 3 && wkDate.day() === 5))) {
            this.currRangeIds.push(this.abcencesSorted[ix]._id)
            lastWk = wk
            ix -= 1
          } else {
            search = false
          }
        }
      }
    },
    hideTooltip () {
      this.clearTooltipTimeout()
      this.closeTooltip = setTimeout(() => {
        this.showTooltip = false
        this.currRangeIds = []
      }, 250)
    },
    clearTooltipTimeout () {
      clearTimeout(this.closeTooltip)
    },
    onItemAdded (item) {
      item.isAbsence = true
      this.addProject(item)
    },
    onItemChanged (item) {
      this.updateProject(item)
    }
  },
  mounted () {
    document.onkeydown = (evt) => {
      evt = evt || window.event
      if (evt.keyCode === 27) {
        this.reset()
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      await vm.loadMineAbsencesByYear(vm.currentYear)
      const date = dayjs().year(vm.currentYear)
      vm.vacationTotal = await vm.totalVacationForRange({ userId: vm.user._id, startDate: date.startOf('year'), endDate: date.endOf('year') })
    })
  }
}
</script>
