<style scoped>
.approved {
  background-color: #4cd964;
}
</style>

<template>
  <div
    class="rounded px-2 py-1 text-white inline-block text-xs select-none w-14 text-center"
    :class="{
      'bg-blue-500' : !isAbsence && !isApproved && isActive,
      'bg-gray-400' : isAbsence && !isApproved && isActive,
      'approved' : isApproved && isActive,
      'bg-gray-200 text-gray-400 dark:bg-gray-800 dark:text-gray-600' : !isActive
      }"
  >
    {{value}}
  </div>
</template>

<script>
export default {
  props: {
    isAbsence: Boolean,
    isApproved: Boolean,
    value: String
  },
  computed: {
    isActive () {
      return this.value !== '00:00'
    }
  }
}
</script>
