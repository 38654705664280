<style scoped>
  .menu a {
    @apply text-gray-800 dark:text-gray-200 cursor-pointer w-full block px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-800 hover:text-primary;
  }
</style>
<template>
  <div>
    <div class="relative flex">
      <router-link to="/app/profile" class="hidden lg:flex text-gray-800 dark:text-gray-200 hover:bg-primary hover:text-primary hover:bg-opacity-25 cursor-pointer rounded-full items-center p-1">
        <Avatar :user="user" :small="true"/>
        <span class="px-2 text-sm font-semibold">{{user.name}}</span>
      </router-link>
      <div ref="dropdown" @click="isOpen = !isOpen" class="text-gray-800 dark:text-gray-200 hover:bg-primary hover:text-primary hover:bg-opacity-25 cursor-pointer flex items-center justify-center h-10 w-10 rounded-full">
        <svg class="fill-current h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/></svg>
      </div>
      <div v-if="isOpen" class="absolute right-0 mt-10 w-48 bg-white dark:bg-black border rounded shadow-lg flex flex-col z-50">

        <router-link to="/app/profile" class="lg:hidden text-gray-800 dark:text-gray-200 hover:bg-gray-200 hover:text-primary cursor-pointer flex items-center p-3">
        <Avatar :user="user" :small="true"/>
        <span class="px-2 text-sm font-semibold ">{{user.name}}</span>
      </router-link>
      <div class="menu">
         <template v-if="isAdmin">
          <div ref="teamcode" class="px-3 py-3 bg-primary text-white text-sm">
            <span class="font-bold mb-1 block">{{$t('samay-code')}}</span>
            <span class="bg-secondary px-2 py-1 rounded text-xs inline-block select-all">{{team.code}}</span>
          </div>
          <div class="md:hidden border-b">
            <template v-if="isAdmin">
              <router-link to="/app">{{$t('dashboard')}}</router-link>
            </template>
            <router-link to="/app/worktimes">{{$t('worktimes')}}</router-link>
            <router-link to="/app/absences">{{$t('absences')}}</router-link>
            <template v-if="isAdmin">
              <router-link to="/app/team">{{$t('team')}}</router-link>
              <router-link to="/app/projects">{{$t('projects')}}</router-link>
              <router-link to="/app/tasks">{{$t('tasks')}}</router-link>
              <router-link to="/app/confirm">{{$t('confirm')}}
                <div v-if="hasOpenConfirmation" class="h-3 w-3 bg-red-600 rounded-full inline-block"></div>
              </router-link>
            </template>
          </div>
          <router-link class="rounded-t" to="/app/settings">{{$t('settings')}}</router-link>
          </template>
          <a :class="{'rounded-t': !isAdmin}" @click="$emit('show-change-profile')" href="#">{{$t('your-profile')}}</a>
          <a @click="$emit('show-change-password')" href="#">{{$t('change-password')}}</a>
          <a class="rounded-b" @click="logoutUser($router)" href="#">{{$t('logout')}}</a>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Avatar from '@/components/Avatar.vue'

export default {
  components: {
    Avatar
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters({ avatar: 'avatar', user: 'user', team: 'team', isAdmin: 'isAdmin', hasOpenConfirmation: 'worktimes/hasOpenConfirmation' })
  },
  methods: {
    ...mapActions(['logoutUser']),
    close (e) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(e.target)) {
        this.isOpen = false
      }
    },
    handleEscape (e) {
      if (e.key === 'Esc' || e.key === 'Escape') {
        this.isOpen = false
      }
    }
  },
  created () {
    document.addEventListener('click', (e) => {
      if (this.$refs.teamcode && !this.$refs.teamcode.contains(e.target)) {
        this.close(e)
      }
      if (this.$refs.teamcode === undefined) {
        this.close(e)
      }
    })
    document.addEventListener('keydown', this.handleEscape)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.close)
    document.removeEventListener('keydown', this.handleEscape)
  }
}
</script>
