
<template>
  <div class="flex items-center justify-between px-3 py-5 bg-white dark:bg-black shadow border-b border-gray-400 dark:border-gray-600">
  <div class="flex items-center">
    <Avatar :user="user" :small="true"/>
    <div class="ml-2 font-bold">
      <span>{{userName()}}</span>
    </div>
  </div>
  <div class="font-bold">
    <span>{{totalTime}}</span>
  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dateMixin from '@/mixin/dateMixin.js'
import Avatar from '@/components/Avatar.vue'

export default {
  components: {
    Avatar
  },
  mixins: [dateMixin],
  props: {
    userId: String,
    worktimes: Array
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      userById: 'users/byId'
    }),
    user: function () {
      return this.userById(this.userId)
    },
    totalTime: function () {
      const sum = this.worktimes.reduce((a, b) => a + (b.seconds || 0), 0)
      return this.formatSecondsToHM(sum)
    }
  },
  methods: {
    userName: function () {
      if (this.user) {
        return this.user.name
      } else {
        return '???'
      }
    }
  }
}
</script>
