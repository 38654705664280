import { BSON } from 'realm-web'
import Vue from 'vue'

// initial state
const state = {
  all: [],
  stream: null
}

const round = function (num) {
  if (isNaN(num)) {
    return { $numberDouble: '0.0' }
  }
  const value = Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100
  return { $numberDouble: value.toString() }
}

// getters
const getters = {
  all: (state) => state.all,
  getByYear: (state) => (year) => {
    return state.all.find(tt => tt.year === parseInt(year))
  }
}

// actions
const actions = {
  async load ({ state, commit, rootGetters }, realm) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const targetTimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('TargetTime')
    targetTimes.find({ _partition: `team=${rootGetters.team._id}` }).then(targetTimes => {
      commit('setTargetTime', targetTimes)
    })
  },
  async add ({ commit, state, rootGetters, dispatch }, item) {
    const newItem = {
      _id: new BSON.ObjectID(),
      _partition: `team=${rootGetters.team._id}`,
      year: item.year,
      jan: round(item.jan),
      feb: round(item.feb),
      mar: round(item.mar),
      apr: round(item.apr),
      may: round(item.may),
      jun: round(item.jun),
      jul: round(item.jul),
      aug: round(item.aug),
      sep: round(item.sep),
      oct: round(item.oct),
      nov: round(item.nov),
      dec: round(item.dec)
    }
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const targetTimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('TargetTime')
    await targetTimes.insertOne(newItem)
    dispatch('load')
  },
  async update ({ commit, dispatch, rootGetters }, item) {
    const updateItem = {
      year: item.year,
      jan: round(item.jan),
      feb: round(item.feb),
      mar: round(item.mar),
      apr: round(item.apr),
      may: round(item.may),
      jun: round(item.jun),
      jul: round(item.jul),
      aug: round(item.aug),
      sep: round(item.sep),
      oct: round(item.oct),
      nov: round(item.nov),
      dec: round(item.dec)
    }
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const targetTimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('TargetTime')
    await targetTimes.updateOne({ _id: new BSON.ObjectId(item._id) }, { $set: updateItem })
    dispatch('load')
  }
}

// mutations
const mutations = {
  setStream (state, stream) {
    state.stream = stream
  },
  setTargetTime (state, tt) {
    state.all = tt
  },
  update (state, item) {
    const index = state.all.findIndex(p => p._id.toString() === item._id.toString())
    Vue.set(state.all, index, item)
  },
  add (state, item) {
    state.all.push(item)
  },
  remove (state, id) {
    const index = state.all.findIndex(p => p._id.toString() === id.toString())
    state.all.splice(index, 1)
  },
  resetState (state) {
    if (state.stream) state.stream.return()
    state.all = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
