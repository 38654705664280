<style scoped>
.timer-font {
  font-family:  "Lucida Console", Monaco, monospace;
}
</style>
<template>
  <div class="flex items-top">
    <span class="timer-font" :class="{'text-white' : !editMode}" v-html="prettyTime"></span>
    <PlayerButton
      v-if="editMode && ((createdBy === undefined) || (user._id === createdBy) || (state === 1))"
      :state="state"
      @on-state-changed="onStateChanged"
      :editMode="editMode">
    </PlayerButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dateMixin from '@/mixin/dateMixin.js'
import PlayerButton from '@/components/buttons/PlayerButton.vue'
import dayjs from 'dayjs'

export default {
  mixins: [dateMixin],
  props: {
    editMode: Boolean,
    state: Number,
    seconds: Number,
    startedAt: Date,
    createdBy: String
  },
  components: {
    PlayerButton
  },
  data () {
    return {
      isRunning: false,
      timer: null,
      time: 0,
      sleepAt: null
    }
  },
  mounted () {
    const self = this
    document.addEventListener('visibilitychange', function () {
      if (self.state === 1) {
        if (document.hidden) {
          if (self.isRunning) {
            self.sleepAt = dayjs()
            clearInterval(self.timer)
          }
        } else {
          const diff = dayjs().diff(self.sleepAt, 'seconds')
          self.time = self.time + diff
          self.timer = setInterval(() => {
            self.time++
          }, 1000)
          self.sleepAt = null
        }
      }
    })
    this.time = this.seconds
    if (this.state === 1) {
      if (this.startedAt !== new Date(null)) {
        const diff = dayjs().diff(this.startedAt, 'seconds')
        this.time = this.time + diff
      }
      this.start(true)
    }
  },
  destroyed () {
    clearInterval(this.interval)
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    prettyTime () {
      return this.formatSecondsToHMS(this.time)
    }
  },
  watch: {
    seconds () {
      this.time = this.seconds
    },
    state () {
      if (this.state === 1) {
        this.start()
      } else {
        this.stop(this.state)
      }
    }
  },
  methods: {
    playerIcon () {
      if (this.isRunning) {
        return 'icon: minus-circle; ratio: 2'
      } else {
        return 'icon: play-circle; ratio: 2'
      }
    },
    stop (state) {
      this.$emit('on-stopped', this.time, state)
      this.isRunning = false
      clearInterval(this.timer)
      this.timer = null
    },
    start (isInit = false) {
      this.isRunning = true
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.time++
        }, 1000)
      }
      if (!isInit) {
        this.$emit('on-started', this.time)
      }
    },
    isTimerActive () {
      return this.state === 1
    },
    isTimerPaused () {
      return this.state === 2
    },
    onStateChanged (state) {
      if (state === 1) {
        this.start()
      } else {
        this.stop(state)
      }
    }
  }
}
</script>
