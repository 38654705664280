<template>
<div v-tooltip="'Archivieren'" @click.stop="disabled ? null : archiveItem(item)" class="bg-red-600 hover:bg-opacity-75 rounded-full w-8 h-8 flex items-center justify-center text-white cursor-pointer" :class="{'cursor-not-allowed opacity-25 hover:bg-opacity-100' : disabled}">
    <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9 19c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5-17v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712zm-3 4v16h-14v-16h-2v18h18v-18h-2z"/></svg>
</div>
</template>

<script>

export default {
  props: {
    item: Object,
    disabled: Boolean,
    tooltip: String
  },
  methods: {
    archiveItem: function (item) {
      this.$emit('on-item-archived', this.item)
    }
  }
}
</script>
