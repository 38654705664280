import Vue from 'vue'

// initial state
const state = {
  all: [],
  me: null,
  stream: null
}

// getters
const getters = {
  me: (state) => state.me,
  byId: (state) => (id) => state.all.find((user) => user._id === id),
  all: (state) => state.all,
  active (state, getters, rootState, rootGetters) {
    return state.all.filter((user) => { return rootGetters.team.members.some((id) => { return id === user._id }) })
  },
  locked (state, getters, rootState, rootGetters) {
    return state.all.filter((user) => { return rootGetters.team.membersArchived.some((id) => { return id === user._id }) })
  }
}

// actions
const actions = {
  async load ({ commit, rootGetters, dispatch }) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const persons = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Person')

    const allUserIds = rootGetters.team.members.concat(rootGetters.team.membersArchived)
    const users = await persons.find({ _id: { $in: allUserIds } })

    const prom = new Promise((resolve) => {
      commit('setUsers', users)
      resolve()
    })
    return prom
  },
  async loadMe ({ commit, rootGetters, dispatch }) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const persons = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Person')

    const user = await persons.findOne({ _id: rootGetters.app.currentUser.id })

    const prom = new Promise((resolve) => {
      commit('setUserMe', user)
      resolve()
    })
    return prom
  },
  async watch ({ state, commit, rootGetters }) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const persons = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Person')
    const stream = persons.watch()
    commit('setStream', stream)
    for await (const change of state.stream) {
      const { operationType } = change
      switch (operationType) {
        case 'insert': {
          commit('add', change.fullDocument)
          break
        }
        case 'update': {
          commit('update', change.fullDocument)
          break
        }
        case 'replace': {
          commit('update', change.fullDocument)
          break
        }
        case 'delete': {
          commit('remove', change.documentKey)
          break
        }
      }
    }
  },
  update ({ rootGetters }, { id, payload }) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const users = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Person')
    return users.updateOne({ _id: id }, { $set: payload })
  }
}

// mutations
const mutations = {
  setStream (state, stream) {
    state.stream = stream
  },
  setUsers (state, users) {
    state.all = users
  },
  setUserMe (state, user) {
    state.me = user
  },
  addUser (state, user) {
    state.all.push(user)
  },
  update (state, user) {
    const index = state.all.findIndex(p => p._id.toString() === user._id.toString())
    Vue.set(state.all, index, user)
    if (user._id === state.me._id) {
      state.me = user
    }
  },
  add (state, user) {
    state.all.push(user)
  },
  remove (state, id) {
    const index = state.all.findIndex(p => p._id.toString() === id.toString())
    state.all.splice(index, 1)
  },
  resetState (state) {
    if (state.stream) state.stream.return()
    state.all = []
    state.me = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
