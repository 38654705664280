<template>
<div>
  <div class="flex items-center justify-between">
    <div class="flex items-center">
      <span class="text-4xl mr-2">{{$t('projects')}} </span>
      <EditItemForm @on-item-added='onItemAdded' :columns="editColumns" :item="newItem" showAddForm/>
    </div>
    <AddMultiProjects></AddMultiProjects>
  </div>
  <ul class="flex mb-4 py-2 border-b-2 border-gray-400 dark:border-gray-600">
  <li class="mr-1">
    <a @click="activeTab=1" class="inline-block px-4 py-1 font-bold text-gray-800 rounded-full hover:bg-primary hover:bg-opacity-25 hover:text-primary"
       :class="{'text-primary' : activeTab === 1}" href="#">
       {{$t('projects-active')}}
    </a>
  </li>
  <li class="mr-1">
    <a @click="activeTab=2" class="inline-block px-4 py-1 font-bold text-gray-800 rounded-full hover:bg-primary hover:bg-opacity-25 hover:text-primary"
      :class="{'text-primary' : activeTab === 2}" href="#">
      {{$t('archive')}}
    </a>
  </li>
  </ul>
  <div class="flex w-full">
  <div v-if="activeTab===1" class="w-full">
    <div v-if="activeProjects.length === 0" class="w-full p-10 bg-gray-200 dark:bg-gray-800 rounded border border-gray-400 dark:border-gray-600 text-gray-600 dark:text-gray-400 mt-2">
      <span class="text-xl">{{$t('no-projects')}}</span>
      <p>{{$t('no-projects-hint')}}</p>
    </div>
    <Grid
      v-else
      :parentKey="'parent'"
      :items="activeProjects"
      :columns="columns">
      <template slot="actions" slot-scope="{ item }">
        <EditItemForm class="mr-1" @on-item-changed="onItemChanged" :item="item" :columns="columns"></EditItemForm>
        <ArchiveButton @on-item-archived="onItemArchived" :item="item"></ArchiveButton>
      </template>
    </Grid>
  </div>
  <div v-if="activeTab===2" class="w-full">
    <div v-if="archivedProjects.length === 0" class="w-full p-10 bg-gray-200 dark:bg-gray-800 rounded border border-gray-400 dark:border-gray-600 text-gray-600 dark:text-gray-400 mt-2">
      <span class="text-xl">{{$t('no-projects')}}</span>
      <p>{{$t('no-archived-projects-hint')}}</p>
    </div>
    <Grid
      v-else
      :parentKey="'parent'"
      :items="archivedProjects"
      :columns="columns">
      <template slot="actions" slot-scope="{ item }">
        <RestoreButton class="mr-1" @on-item-restored="onItemRestored" :item="item"></RestoreButton>
        <DeleteButton @on-item-deleted="onItemDeleted" :item="item" :text="'Willst du das Projekt (inkl. Arbeitszeiten) wirklich löschen? (Das Projekt wird auch bei Arbeitszeiten mit diesem Projekt entfernt)'"></DeleteButton>
      </template>
    </Grid>
  </div>
  </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { BSON } from 'realm-web'
import Grid from '@/components/Grid.vue'
import AddMultiProjects from '@/components/AddMultiProjects.vue'
import EditItemForm from '@/components/EditItemForm.vue'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import ArchiveButton from '@/components/buttons/ArchiveButton.vue'
import RestoreButton from '@/components/buttons/RestoreButton.vue'

export default {
  components: {
    EditItemForm,
    DeleteButton,
    ArchiveButton,
    RestoreButton,
    AddMultiProjects,
    Grid
  },
  data () {
    return {
      newItem: {},
      activeTab: 1
    }
  },
  computed: {
    ...mapGetters({
      activeProjects: 'projects/active',
      archivedProjects: 'projects/archived',
      children: 'projects/children'
    }),
    columns () {
      return [{ key: 'name', value: this.$t('description'), class: '' }]
    },
    editColumns () {
      if (this.activeProjects.length) {
        return [{ key: 'parent', value: this.$t('main-project'), type: 'select', options: this.activeProjects }, { key: 'name', value: this.$t('description') }]
      }
      return [{ key: 'name', value: this.$t('description') }]
    }
  },
  methods: {
    ...mapActions({
      updateWorktimes: 'worktimes/updateMany',
      addProject: 'projects/add',
      updateProject: 'projects/update',
      deleteProject: 'projects/remove',
      updateMultipleProjects: 'projects/update_multiple'
    }),
    onItemAdded (item) {
      this.addProject(item)
    },
    onItemDeleted (item) {
      this.updateWorktimes({ query: { projectId: item._id }, update: { $set: { projectId: new BSON.ObjectId('000000000000000000000000') } } })
      this.deleteProject(item)
    },
    onItemChanged (item) {
      this.updateProject(item)
    },
    onItemArchived (item) {
      const ids = this.findAllChildren(this.children(item)).map(item => item._id)
      ids.push(item._id)
      this.updateMultipleProjects({ ids: ids, isArchived: true })
    },
    onItemRestored (item) {
      const ids = this.findAllChildren(this.children(item)).map(item => item._id)
      ids.push(item._id)
      this.updateMultipleProjects({ ids: ids, isArchived: false })
    },
    findAllChildren (projects) {
      let tmp = []
      projects.forEach(p => {
        tmp = tmp.concat(this.findAllChildren(this.children(p)))
      })
      return tmp.concat(projects)
    }
  }
}
</script>
