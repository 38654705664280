<style scoped>
.approved {
  background-color: #4cd964;
}
</style>

<template>
  <div>
    <span class="text-4xl">Arbeitszeiten / Absenzen quittieren</span>
    <div class="flex my-3 pb-1 border-b border-gray-400 dark:border-gray-600">
      <div class="cursor-pointer opacity-50 hover:text-primary hover:bg-primary hover:bg-opacity-25 rounded-full px-2 py-1"
        @click="year=year-1;reloadWorktimes()">
        {{year-1}}
      </div>
      <div class="select-none font-semibold text-primary hover:text-primary hover:bg-primary hover:bg-opacity-25 rounded-full px-2 py-1">
        {{year}}
      </div>
      <div class="cursor-pointer opacity-50 hover:text-primary hover:bg-primary hover:bg-opacity-25 rounded-full px-2 py-1"
        @click="year=year+1;reloadWorktimes()">
        {{year+1}}
      </div>
    </div>
    <div class="flex flex-wrap">
      <div v-for="user in users" :key="user._id"
       class="m-2 py-3 px-2 border-2 border-gray-300 dark:border-gray-700 rounded-lg"
       >
        <div class="w-full text-center text-lg mb-3 font-bold">{{user.name}}</div>
          <table>
            <thead class="text-xs text-center">
              <th></th>
              <th>Arbeitszeiten</th>
              <th>Absenzen</th>
              <th></th>
            </thead>
            <tbody>
               <tr v-for="month in 12" :key="month">
                <td class="text-xs pr-2">{{monthName(month)}}</td>
                <td>
                  <div class="flex">
                    <ConfirmItem
                      :isAbsence="false"
                      :isApproved="false"
                      :value="getTime(month, user._id, 1, false)"
                      :isActive="false">
                    </ConfirmItem>
                    <ConfirmItem
                      class="ml-1"
                      :isAbsence="false"
                      :isApproved="true"
                      :value="getTime(month, user._id, 1, true)"
                      :isActive="false">
                    </ConfirmItem>
                  </div>
                </td>
                <td>
                  <div class="flex flex-no-wrap">
                    <ConfirmItem
                      class="ml-2"
                      :isAbsence="true"
                      :isApproved="false"
                      :value="getTime(month, user._id, 2, false)"
                      :isActive="false">
                    </ConfirmItem>
                    <ConfirmItem
                      class="ml-1"
                      :isAbsence="true"
                      :isApproved="true"
                      :value="getTime(month, user._id, 2, true)"
                      :isActive="false">
                    </ConfirmItem>
                  </div>
                </td>
                <td>
                  <div
                    v-if="hasData(month, user._id)"
                    v-tooltip="'Quittieren'"
                    class="cursor-pointer ml-1 w-5 h-5 bg-green-600 rounded-full flex items-center justify-center"
                    @click="lockWorktimesForUser(month, user._id)"
                  >
                    <svg class="w-4 h-4 fill-current text-white" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd" viewpor><path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/></svg>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'
import dateMixin from '@/mixin/dateMixin.js'
import ConfirmItem from '@/components/confirm/ConfirmItem.vue'

export default {
  mixins: [dateMixin],
  components: {
    ConfirmItem
  },
  data () {
    return {
      year: parseInt(dayjs().format('YYYY'))
    }
  },
  computed: {
    ...mapGetters({
      byMonthUser: 'worktimes/byMonthUser',
      users: 'users/all'
    }),
    currentMonth () {
      return parseInt(dayjs().format('M'))
    },
    currentYear () {
      return parseInt(dayjs().format('YYYY'))
    }
  },
  methods: {
    ...mapActions({
      lockWorktimes: 'worktimes/lock_multiple',
      loadWorktimes: 'worktimes/load'
    }),
    isPast (month) {
      return month < this.currentMonth || this.currentYear > this.year
    },
    monthName (month) {
      return dayjs(`1-${month}-${this.year}`, 'D-M-YYYY').locale(this.$i18n.locale).format('MMM')
    },
    hasData (month, userId) {
      const key = month
      if (this.byMonthUser[key] === undefined || this.byMonthUser[key][userId] === undefined) {
        return false
      }
      return this.byMonthUser[key][userId].filter(wkt => wkt.isApproved === false).length > 0
    },
    hasDataApprved (month, userId) {
      const key = month
      if (this.byMonthUser[key] === undefined || this.byMonthUser[key][userId] === undefined) {
        return false
      }
      return this.byMonthUser[key][userId].filter(wkt => wkt.isApproved === true).length > 0
    },
    getData (month, userId, type, isApproved) {
      const key = month
      if (this.byMonthUser[key] === undefined || this.byMonthUser[key][userId] === undefined) {
        return undefined
      }
      return this.byMonthUser[key][userId].filter(wkt => wkt.type === type && wkt.isApproved === isApproved)
    },
    getTime (month, userId, type, isApproved) {
      const data = this.getData(month, userId, type, isApproved)
      if (data === undefined) {
        return '00:00'
      }
      const sec = data.reduce((a, b) => a + (b.seconds), 0)
      return this.formatSecondsToHMShort(sec)
    },
    lockWorktimesForUser (month, userId) {
      const key = month
      const wkts = this.byMonthUser[key][userId].filter(wkt => wkt.isApproved === false)
      wkts.forEach(w => { w.isApproved = true })
      this.lockWorktimes(wkts)
    },
    async reloadWorktimes () {
      const query = {
        date: {
          $gte: dayjs().year(this.year).startOf('year').startOf('day').toDate(),
          $lte: dayjs().year(this.year).endOf('year').endOf('day').toDate()
        }
      }
      await this.loadWorktimes(query)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      await vm.reloadWorktimes()
    })
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('worktimes/resetState')
    next()
  }
}
</script>
