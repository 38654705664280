<template>
  <div class="flex items-center">
    <div class="cursor-pointer" @click="navigateTo(-1)">
      <svg class="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
    </div>
    <div class="w-full mx-1">
    <flat-pickr
            v-model="currentDate"
            :config="config"
            class="date-picker"
            placeholder="Select date"
            @on-change="dateChanged"
            name="date">
    </flat-pickr>
    </div>
    <div class="cursor-pointer" @click="navigateTo(1)">
      <svg class="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
    </div>
 </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { German } from 'flatpickr/dist/l10n/de.js'
import dateMixin from '../mixin/dateMixin.js'

export default {
  mixins: [dateMixin],
  components: {
    flatPickr
  },
  data () {
    return {
      date: new Date(),
      config: {
        wrap: false,
        altFormat: 'd.m.Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: German
      }
    }
  },
  computed: {
    currentDate: {
      get: function () {
        return this.formatDateToISO(this.date)
      },
      set: function (date) {
        this.date = new Date(date)
      }
    }
  },
  methods: {
    navigateTo (value) {
      this.date.setDate(this.date.getDate() + value)
      this.currentDate = this.date
    },
    dateChanged (selectedDates, dateStr, instance) {
      this.currentDate = dateStr
      this.$emit('on-date-changed', this.currentDate)
    }
  }
}
</script>
