<template>
 <div class="bg-white dark:bg-black dark:text-white w-11/12 md:max-w-md mx-auto rounded shadow-lg overflow-y-auto px-8 mt-40 pt-6 pb-8">
   <form @submit.prevent="continueTapped">

     <div class="text-2xl text-center">Passwort zurücksetzen</div>
     <div class="mb-2 mt-6">
        <label class="text-sm">{{$t('new-password')}}</label>
        <input type="password" v-model="password" ref="password" :placeholder="$t('password')" />
      </div>
     <p class="text-center mt-6">
        <button type="submit" class="primary px-8" :class="{'opacity-50 cursor-not-allowed': (!this.password)}" :disabled="!this.password">
          {{$t('continue')}}
        </button>
     </p>
     <p class="text-center mt-6">
     <a class="text-primary hover:underline cursor-pointer font-semibold" @click="back">{{$t('back')}}</a>
     </p>
   </form>
 </div>
</template>

<script>
import APIClient from '@/assets/js/APIClient.js'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      password: null,
      id: null,
      token: null
    }
  },
  methods: {
    ...mapActions({ logoutUser: 'logoutUser', loadingBegin: 'loadingBegin', loadingEnd: 'loadingEnd', addNotification: 'addNotification' }),
    back () {
      this.$router.replace('/app/login')
    },
    continueTapped () {
      const pattern = '(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z]).{8,}'
      if (!this.password.match(pattern)) {
        this.addNotification({ message: this.$t('password-requirements'), error: true })
        return
      }
      this.loadingBegin()
      APIClient
        .post('auth/resetPassword', {
          id: this.id,
          token: this.token,
          password: this.password
        })
        .then((response) => {
          this.loadingEnd()
          this.addNotification({ message: 'Das Passwort wurde erfolgreich zurückgesetzt' })
          this.$router.replace('/app/login')
        })
        .catch((err) => {
          this.loadingEnd()
          console.log(err)
          this.addNotification({ message: 'Das Token ist ungültig oder abgelaufen', error: true })
        })
    }
  },
  mounted () {
    this.id = this.$route.query.id
    this.token = this.$route.query.token
    if (!this.id || !this.token) {
      this.$router.replace('/app/login')
    }
  }
}
</script>
