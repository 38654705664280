<template>
  <div v-if="open" class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
    <div class="absolute w-full h-full bg-gray-900 opacity-75"></div>
    <div class="bg-white dark:bg-black w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto px-5 -mt-64">
      <form @submit.prevent="done">
        <h2>Passwort ändern</h2>
        <div class="my-2">
          <label class="text-sm">{{$t('password')}}</label>
          <input type="password" v-model="passwort1" :placeholder="$t('new-password')" />
        </div>
        <div class="my-2">
          <label class="text-sm">{{$t('password-confirm')}}</label>
          <input type="password" v-model="passwort2" :placeholder="$t('password-confirm')"/>
        </div>
         <div class="flex justify-end pt-6 pb-4">
            <button @click="close" type="button">{{$t('cancel')}}</button>
            <button class="primary ml-2" type="submit">{{$t('save')}}</button>
        </div>
      </form>
    </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    open: Boolean
  },
  data () {
    return {
      passwort1: '',
      passwort2: ''
    }
  },
  computed: {
    ...mapGetters(['app'])
  },
  methods: {
    ...mapActions(['addNotification']),
    async done () {
      if (this.passwort1 !== this.passwort2) {
        this.addNotification({ message: this.$t('password-not-match'), error: true })
        return
      }
      if (this.passwort1 === '') {
        this.addNotification({ message: this.$t('password-empty'), error: true })
        return
      }
      const pattern = '(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z]).{8,}'
      if (!this.passwort1.match(pattern)) {
        this.addNotification({ message: this.$t('password-requirements'), error: true })
        return
      }
      const res = await this.app.currentUser.functions.updatePassword(this.passwort1)
      if (res.error) {
        this.addNotification({ message: 'Ooops, da gieng was schief :-(', error: true })
        return
      }
      this.addNotification({ message: this.$t('check') })
      this.close()
    },
    close () {
      this.passwort1 = ''
      this.passwort2 = ''
      this.$emit('on-modal-closed')
    }
  }
}
</script>
