import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
// import Try from '@/views/Try'
import WebApp from '@/views/WebApp'
import DashboardView from '@/views/DashboardView'
import Team from '@/views/team/Team'
import User from '@/views/team/User'
import ProjectsView from '@/views/ProjectsView'
import TasksView from '@/views/TasksView'
import WorktimesView from '@/views/worktime/WorktimesView'
import LoginView from '@/views/auth/LoginView'
import RegisterTeamView from '@/views/auth/RegisterTeamView'
import Settings from '@/views/profile/Settings'
import Privacy from '@/views/Privacy'
import Terms from '@/views/Terms'
import DownloadView from '@/views/DownloadView'
import DeleteAccount from '@/views/DeleteAccount'
import SamayView from '@/views/SamayView'
import SiteNotFound from '@/views/SiteNotFound'
import Profile from '@/views/profile/Profile'
import Absences from '@/views/Absences'
import Confirm from '@/views/worktime/Confirm'
import passwordReset from '@/views/auth/PasswordReset'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: SamayView,
      children: [
        { path: '', component: Home },
        { path: 'privacy', component: Privacy },
        { path: 'terms', component: Terms },
        { path: 'deleteaccount', component: DeleteAccount },
        { path: 'd', component: DownloadView }
      ]
    },
    {
      path: '/app',
      component: WebApp,
      children: [
        { path: '', component: DashboardView, meta: { requiresAuth: true, requiresAdmin: true } },
        { path: 'settings', component: Settings, meta: { requiresAuth: true, requiresAdmin: true } },
        { path: 'team', component: Team, meta: { requiresAuth: true, requiresAdmin: true } },
        { path: 'team/:id', component: User, meta: { requiresAuth: true, requiresAdmin: true } },
        { path: 'projects', component: ProjectsView, meta: { requiresAuth: true, requiresAdmin: true } },
        { path: 'tasks', component: TasksView, meta: { requiresAuth: true, requiresAdmin: true } },
        { path: 'worktimes', component: WorktimesView, meta: { requiresAuth: true } },
        { path: 'confirm', component: Confirm, meta: { requiresAuth: true, requiresAdmin: true } },
        { path: 'profile', component: Profile, meta: { requiresAuth: true } },
        { path: 'absences', component: Absences, meta: { requiresAuth: true } },
        { path: 'login', component: LoginView },
        { path: 'passwordReset', component: passwordReset },
        { path: 'signup/team', component: RegisterTeamView, meta: { requiresAuth: true } }
        // { path: 'try', component: Try }
      ]
    },
    { path: '*', component: SiteNotFound }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  linkExactActiveClass: 'is-active'
})
