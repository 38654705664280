<template>
<div class="pb-6">
    <div class="flex flex-col justify-center items-center">
      <Avatar :user="user"/>
      <span class="text-4xl block">{{user.name}}</span>
      <div class="text-xl ml-2 px-2 h-10 rounded flex items-center">
        <span class="inline-block font-semibold" :class="[currentSaldo >= 0 ? 'text-green-500' : 'text-red-500']">{{saldoStr(currentSaldo)}}</span>
      </div>
    </div>
    <div class="flex my-3 pb-1 border-b border-gray-400">
        <div class="cursor-pointer opacity-50 hover:text-primary hover:bg-primary hover:bg-opacity-25 rounded-full px-2 py-1" @click="updateYear(currentYear-1)">{{currentYear-1}}</div>
        <div class="select-none font-semibold text-primary hover:text-primary hover:bg-primary hover:bg-opacity-25 rounded-full px-2 py-1">{{currentYear}}</div>
        <div v-if="activeYear !== getCurrentYear" class="cursor-pointer opacity-50 hover:text-primary hover:bg-primary hover:bg-opacity-25 rounded-full px-2 py-1" @click="updateYear(currentYear+1)">{{currentYear+1}}</div>
      </div>
    <template v-if="getCurrentYear === activeYear">
    <span class="text-xs font-semibold">
      {{currentMonthStr}} ({{$t('employment')}}: {{activePercentForUser(user._id, getDate(currentMonth, getCurrentYear))}}%)
    </span>
    <div class="flex items-center">
      <HoursInfo :date="getDate(currentMonth, getCurrentYear)" :year="getCurrentYear" :format="'DD.M.YYYY'"></HoursInfo>
      <MonthlyWorkReport :selectedYear="getCurrentYear" :selectedMonth="parseInt(currentMonth)" :userId="user._id"/>
    </div>
    </template>
    <span v-if="lastMonth > 1 || getCurrentYear !== activeYear" class="text-2xl mt-4 block">{{$t('overview-year')}} {{getCurrentYear}}</span>
    <div v-for="month in monthIterator" :key="month + user._id">
        <span class="text-xs font-semibold">{{monthName(month, getCurrentYear)}} ({{$t('employment')}}: {{activePercentForUser(user._id, getDate(month, getCurrentYear))}}%)</span>
        <div class="flex items-center">
          <HoursInfo :date="getDate(month, getCurrentYear)" :year="getCurrentYear" :format="'DD.M.YYYY'"></HoursInfo>
          <MonthlyWorkReport :selectedYear="getCurrentYear" :selectedMonth="parseInt(month)" :userId="user._id"/>
        </div>
    </div>
</div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'
import dateMixin from '@/mixin/dateMixin.js'
import HoursInfo from '@/components/HoursInfo.vue'
import MonthlyWorkReport from '@/components/export/MonthlyWorkReport.vue'
import Avatar from '@/components/Avatar.vue'

export default {
  components: {
    HoursInfo,
    MonthlyWorkReport,
    Avatar
  },
  mixins: [dateMixin],
  data () {
    return {
      saldo: null,
      currentYear: parseInt(dayjs().format('YYYY'))
    }
  },
  computed: {
    ...mapGetters({
      team: 'team',
      user: 'user',
      activePercentForUser: 'employmentlevel/activePercentForUser',
      targetTimesByYear: 'targettimes/getByYear'
    }),
    activeYear () {
      return parseInt(dayjs().format('YYYY'))
    },
    getCurrentYear () {
      return this.currentYear
    },
    currentMonth () {
      return dayjs().format('M')
    },
    currentMonthStr () {
      return dayjs().locale(this.$i18n.locale).format('MMMM')
    },
    lastMonth () {
      return this.currentMonth - 1
    },
    currentSaldo () {
      return this.saldo
    },
    monthIterator () {
      if (this.getCurrentYear === this.activeYear) {
        return this.lastMonth
      } else {
        return 12
      }
    }
  },
  methods: {
    ...mapActions({
      getSaldo: 'getSaldo'
    }),
    async updateYear (year) {
      this.currentYear = year
      let toMonth = this.lastMonth
      if (this.activeYear !== this.currentYear) {
        toMonth = 12
      }
      this.saldo = await this.getSaldo({ userId: this.user._id, fromMonth: 1, toMonth: toMonth, year: this.currentYear })
    },
    saldoStr (saldo) {
      const strDiff = this.formatSecondsToHM(Math.abs(saldo * 3600))
      if (saldo < 0) {
        return `-${strDiff}`
      } else {
        return `+${strDiff}`
      }
    },
    getDate (month, year) {
      return dayjs(`01.${month}.${year}`, 'DD.M.YYYY')
    },
    monthName (month, year) {
      return this.getDate(month, year).startOf('month').locale(this.$i18n.locale).format('MMMM')
    }
  },
  async mounted () {
    this.saldo = await this.getSaldo({ userId: this.user._id, fromMonth: 1, toMonth: this.lastMonth, year: this.currentYear })
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('worktimes/resetState')
    next()
  }
}
</script>
