<style scoped>
.timer-active {
  background-color: #5AC8FA;
}
.timer-paused {
  background-color: #eabb00;
}

.approved {
  background-color: #4cd964;
}
.text-white {
  color: #fff !important;
}

.active:hover{
  @apply bg-opacity-75 cursor-pointer;
}
.timer-paused:hover {
  background-color: #cca300;
}

</style>
<template>
<div>
<div class="my-4">
    <div class="rounded p-2 h-32"
        :class="{
          'timer-active': isTimerActive(),
          'timer-paused': isTimerPaused(),
          'approved': item.isApproved,
          'active': !item.isApproved,
          'animate-wiggle': notAllowed,
          'bg-green-500': item.type === 3,
          'bg-gray-400': item.type === 2,
          'bg-blue-600 bg-opacity-80': item.type === 1
        }"
        @click.stop="editItem()"
    >
      <div class="relative h-full">

          <div class="absolute top-0 bottom-0 m-1">
            <div class="flex flex-col">
              <span v-if="item.projectId && getProjectById(item.projectId)" class="text-white">{{getProjectById(item.projectId).name}}</span>
              <span v-if="item.taskId && getTaskById(item.taskId)" class="text-white">{{getTaskById(item.taskId).name}}</span>
            </div>
          </div>
          <div class="absolute top-0 right-0 m-1">
            <DeleteButton
              v-if="!item.isApproved && !isTimerActive() && !isTimerPaused()"
              @on-item-deleted="onItemDeleted"
              :item="item"
              :text="item.type === 3 ? 'Willst du die Spesen wirklich löschen?' : 'Willst du die Arbeitszeit wirklich löschen?'"
            ></DeleteButton>
            <PlayerButton v-if="(user._id === item.createdBy) && (item.state > 0)" :state="item.state" @on-state-changed="onStateChanged" :editMode="false"></PlayerButton>
          </div>

          <div class="absolute left-0 bottom-0 m-1 flex items-center">
            <ApproveButton class="mr-2" v-if="isDashboardView && !isTimerActive() && !isTimerPaused() && !item.isAbsence" @on-item-lock="onItemLock" @on-item-unlock="onItemUnlock" :item="item"></ApproveButton>
            <div v-if="item.note"
            >
              <svg class="w-5 text-white fill-current hover:opacity-75" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 22v-20h16v11.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362zm18-7.614v-14.386h-20v24h10.189c3.163 0 9.811-7.223 9.811-9.614zm-5-1.386h-10v-1h10v1zm0-4h-10v1h10v-1zm0-3h-10v1h10v-1z"/></svg>
            </div>
          </div>
          <div class="absolute right-0 bottom-0 m-1">
            <div v-if="item.type === 3" class="text-white">
              {{item.expenses}} CHF
            </div>
            <template v-else>
              <span v-if="item.state === 0" class="text-white">{{formatSecondsToHM(item.seconds)}}</span>
              <WorktimeTimer v-else class="" :createdBy="item.createdBy" :state="item.state" :seconds="item.seconds" :startedAt="item.startedAt"></WorktimeTimer>
            </template>
          </div>

      </div>

    </div>
  </div>

</div>
</template>

<script>
import { mapGetters } from 'vuex'
import dateMixin from '@/mixin/dateMixin.js'
import ApproveButton from '@/components/buttons/ApproveButton.vue'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import PlayerButton from '@/components/buttons/PlayerButton.vue'
import WorktimeTimer from './WorktimeTimer.vue'

import dayjs from 'dayjs'
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  mixins: [dateMixin],
  components: {
    ApproveButton,
    DeleteButton,
    PlayerButton,
    WorktimeTimer
  },
  props: {
    item: Object,
    isDashboardView: Boolean
  },
  data () {
    return {
      showEditForm: false,
      notAllowed: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      getProjectById: 'projects/getById',
      getTaskById: 'tasks/getById',
      absProjects: 'projects/absences'
    })
  },
  methods: {
    editItem: function () {
      if (!this.item.isApproved) {
        this.$emit('on-edit-item', this.item)
      } else {
        this.notAllowed = true
        setTimeout(() => {
          this.notAllowed = false
        }, 300)
      }
    },
    isTimerActive: function () {
      return this.item.state === 1
    },
    isTimerPaused: function () {
      return this.item.state === 2
    },
    onItemLock: function (item) {
      this.$emit('on-item-lock', item)
    },
    onItemUnlock: function (item) {
      this.$emit('on-item-unlock', item)
    },
    onItemChanged: function (item) {
      this.$emit('on-item-changed', item)
    },
    onItemDeleted: function (item) {
      this.$emit('on-item-deleted', item)
    },
    onStopped: function (seconds, state) {
      const tmpItem = JSON.parse(JSON.stringify(this.item))
      tmpItem.state = state
      tmpItem.startedAt = new Date(null)
      tmpItem.seconds = seconds
      this.$emit('on-item-changed', tmpItem)
    },
    onStateChanged: function (state) {
      const tmpItem = JSON.parse(JSON.stringify(this.item))
      tmpItem.state = state
      if (tmpItem.startedAt && new Date(tmpItem.startedAt).getTime() !== new Date(null).getTime()) {
        const diff = dayjs().utc().diff(dayjs(tmpItem.startedAt), 'seconds')
        tmpItem.seconds = tmpItem.seconds + diff
      }
      if (state === 1) {
        tmpItem.startedAt = new Date()
      } else {
        tmpItem.startedAt = new Date(null)
      }
      this.$emit('on-item-changed', tmpItem)
    }
  }
}
</script>
