<style type="text/css" scoped>
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
<template>
<div>
  <div class="flex items-center">
    <span class="inline-block text-4xl mr-2">{{$t('target-times')}} </span>
    <EditItemForm v-if="years.length" @on-item-added='onItemAdded' :columns="columns" :item="newYearItem" showAddForm/>
  </div>
  <div class="flex flex-col mt-3 mb-6">
    <label class="font-bold">{{$t('hours-per-week')}}</label>
    <div class="flex">
      <input class="w-48" type="number" v-model="hoursPerWeek" max="80" :class="{'bg-red-200 dark:bg-red-800 border border-red-400 dark:border-red-600': !team.hoursPerWeek}">
      <button @click="saveHoursPerWeek()" class="ml-3 primary" v-if="isSaveVisible">{{$t('save')}}</button>
    </div>
  </div>

  <p>
   {{$t('target-time-description')}}
  </p>

  <div v-if="targetTimes.length === 0" class="w-full p-10 bg-gray-200 dark:bg-gray-800 rounded border border-gray-400 dark:bordger-gray-600 text-gray-600 dark:text-gray-400 mt-2">
    <span class="text-xl">{{$t('no-target-times')}}</span>
    <p>{{$t('no-target-times-hint')}}</p>
  </div>
  <table v-else class="table-auto w-full">
    <thead>
      <tr>
        <th class="p-1 text-left uppercase text-gray-600 dark:text-gray-400 text-sm font-normal py-3">{{$t('year')}}</th>
        <th class="p-1 uppercase text-gray-600 dark:text-gray-400 text-sm font-normal" v-for="month in 12" :key="month">{{getMonthName(month)}}</th>
        <th class="p-1 uppercase text-gray-600 dark:text-gray-400 text-sm font-normal"></th>
      </tr>
    </thead>
    <tbody>
      <tr class="hover:bg-gray-100 dark:hover:bg-gray-800 even:bg-gray-100 dark:even:bg-gray-900" v-for="(time, index) in targetTimes" :key="time.year">
        <td class="p-1 text-xs">{{time.year}}</td>
        <td class="text-center text-xs" v-for="(month) in months" :key="month">
          <input v-if="editRow===index" v-model="time[month]" class="text-center text-xs p-0 py-1" type="number">
          <span v-else>{{time[month]}}</span>
        </td>
        <td class="w-16">
          <div v-if="editRow===-1" @click="edit(time, index)" class="bg-primary hover:bg-opacity-75 rounded-full w-6 h-6 flex items-center justify-center text-white cursor-pointer">
            <svg class="h-3 w-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"/></svg>
          </div>
          <div class="flex">
            <div v-if="editRow===index" @click="cancel(index)" class="bg-red-500 hover:bg-opacity-75 rounded-full w-6 h-6 flex items-center justify-center text-white cursor-pointer">
              <svg class="w-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
            </div>
            <div v-if="editRow===index" @click="save(time)" class="ml-1 bg-primary hover:bg-opacity-75 rounded-full w-6 h-6 flex items-center justify-center text-white cursor-pointer">
              <svg class="w-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z"/></svg>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'
import EditItemForm from '@/components/EditItemForm.vue'
export default {
  components: {
    EditItemForm
  },
  data () {
    return {
      editRow: -1,
      hoursPerWeek: 0,
      currentYear: dayjs().format('YYYY'),
      newYearItem: { },
      months: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
    }
  },
  computed: {
    ...mapGetters({
      targetTimes: 'targettimes/all',
      team: 'team'
    }),
    columns () {
      return [{ key: 'year', value: 'Jahr', type: 'select', options: this.years, class: '' }]
    },
    years () {
      const year = parseInt(dayjs().format('YYYY')) + 1
      let years = Array.from({ length: year - 2018 }, (value, index) => year - index)
      years = years.filter(y => this.targetTimes.find(tt => tt.year === y) === undefined)
      return years.map(y => { return { _id: y, name: y } })
    },
    isSaveVisible () {
      return parseFloat(this.hoursPerWeek) !== parseFloat(this.team.hoursPerWeek)
    }
  },
  methods: {
    ...mapActions({
      updateTeam: 'teams/update',
      add: 'targettimes/add',
      update: 'targettimes/update'
    }),
    getMonthName (month) {
      return dayjs().month(month - 1).locale(this.$i18n.locale).format('MMM')
    },
    edit (item, index) {
      this.editItem = JSON.parse(JSON.stringify(item))
      this.editRow = index
    },
    cancel (index) {
      this.targetTimes[index] = this.editItem
      this.editItem = {}
      this.editRow = -1
    },
    async saveHoursPerWeek () {
      await this.updateTeam({ id: this.team._id, payload: { hoursPerWeek: { $numberDouble: this.hoursPerWeek.toString() } } })
      this.$store.dispatch('teams/load')
    },
    save (item) {
      this.update(item)
      this.editItem = {}
      this.editRow = -1
    },
    onItemAdded (item) {
      this.add(item)
    }
  },
  mounted () {
    this.hoursPerWeek = this.team.hoursPerWeek
  }
}

</script>
