import dayjs from 'dayjs'

export default {
  methods: {
    formatDateToISO: function (date) {
      let dd = date.getDate()
      let mm = date.getMonth() + 1
      const yyyy = date.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      const str = yyyy + '-' + mm + '-' + dd
      return str
    },
    formatDate: function (dateStr) {
      const d = new Date(dateStr)
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      if (this.$i18n.locale === 'en') {
        return d.toLocaleDateString('en-US', options)
      }
      return d.toLocaleDateString('de-CH', options)
    },
    formatDateCustom (dateStr, format) {
      return dateStr === null ? '-' : dayjs(dateStr).locale(this.$i18n.locale).format(format)
    },
    formatSecondsToHM: function (seconds) {
      let h = Math.floor(seconds / 60 / 60)
      let m = Math.round(seconds / 60 % 60)
      if (m === 60) {
        h += 1
        m = 0
      }
      let result = ''
      if (h > 0) {
        result = `${h} ${this.$t('hour-short')}`
      }
      result = `${result} ${m} Min.`
      return result
    },
    formatSecondsToHMShort: function (seconds) {
      let h = Math.floor(seconds / 60 / 60)
      let m = Math.round(seconds / 60 % 60)
      if (m === 60) {
        h += 1
        m = 0
      }
      if (h < 10) {
        h = '0' + h
      }
      if (m < 10) {
        m = '0' + m
      }
      return `${h}:${m}`
    },
    formatSecondsToH: function (seconds) {
      let h = (seconds / 60 / 60)
      h = Math.round((h + Number.EPSILON) * 100) / 100
      return `${h} ${this.$t('hour-short')}`
    },
    formatSecondsToDays: function (seconds, hoursPerWeek) {
      let h = (seconds / 60 / 60 / (hoursPerWeek / 5))
      h = Math.round((h + Number.EPSILON) * 100) / 100
      return `${h} ${this.$t('days')}`
    },
    formatSecondsToHMS (seconds) {
      const h = Math.floor(seconds / 3600)
      const m = Math.floor((seconds % 3600) / 60)
      const s = (seconds % 3600) % 60

      const htext = h.toString().padStart(2, '0')
      const mtext = m.toString().padStart(2, '0')
      const stext = s.toString().padStart(2, '0')

      return `${htext}:${mtext}:${stext}`
    }
  }
}
