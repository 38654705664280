<template>
<div>
</div>
</template>
<script>

export default {
  mounted: function () {
    if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
      window.location.href = 'https://play.google.com/store/apps/details?id=ch.samay.samay'
    } else if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
      window.location.href = 'https://apps.apple.com/ch/app/samay/id1441944333'
    } else {
      this.$router.replace('/#download')
    }
  }
}
</script>
