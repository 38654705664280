<template>
<div>
  <div v-if="isDashboardView">
    <span v-for="(worktime, key, index) in worktimesGrouped" :key="index">
      <div class="font-bold px-5 shadow py-5 sticky top-0 z-10 bg-gray-100 dark:bg-gray-800 border-b border-gray-400 dark:border-gray-600 mb-3">
        <span>{{formatDate(key)}}</span>
      </div>
      <span v-for="(item, key) in groupBy(worktime, 'createdBy')" :key="key">
        <UserInfo :userId="key" :worktimes="item"></UserInfo>
        <span v-for="(wt, key) in item" :key="key">
        <WorktimeItemView :item="wt" @on-item-changed="onItemChanged" @on-item-deleted="onItemDeleted" @on-item-lock="onItemLock" @on-item-unlock="onItemUnlock"  @on-edit-item="onEditItem" isDashboardView></WorktimeItemView>
      </span>
      </span>
    </span>
  </div>
  <div v-else>
    <div v-if="trialExpired && !team.activated && !user.isAdmin" class="bg-red-200 border border-red-400 roudned p-4 text-red-700 rounded">
        {{$t('trialExpired')}}
    </div>
    <div v-else class="justify-center md:flex items-center">
      <div class="flex items-center">
        <span class="text-4xl mr-2">{{$t('worktimes')}}</span>
        <a class="cursor-pointer" @click="editItem=null, showEditForm=true, isExpenses=false">
          <svg class="h-8 w-8 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"/></svg>
        </a>
      </div>
      <DatePicker class="shrink-0 sm:mx-6" @on-date-changed='onDateChanged'/>
      <div class="w-full lg:flex lg:items-center">
      <div @click="updateOverviewTyp" class="cursor-pointer flex flex-wrap w-full my-2">
        <div class="w-full flex items-center justify-center">
          <div class="text-xs mx-2 font-bold">
            {{overviewStr}}
          </div>
        </div>
        <HoursInfo :type="overviewType" class="" :date="currentDateDayJs" :year="parseInt(year)"></HoursInfo>
      </div>
      <div class="h-6 py-1 mt-1 rounded-md hover:bg-opacity-70 bg-secondary text-white text-xs whitespace-nowrap cursor-pointer text-center lg:ml-2 lg:w-44 lg:h-6 lg:mt-4 lg:flex lg:items-center lg:justify-center"
       @click="editItem=null, showEditForm=true,isExpenses=true">
        {{ $t('add-expences') }}
      </div>
      </div>
  </div>
    <span v-for="(item, key) in worktimes" :key="key">
      <WorktimeItemView :item="item" @on-item-changed="onItemChanged" @on-item-deleted="onItemDeleted" @on-edit-item="onEditItem"></WorktimeItemView>
    </span>
    <div v-if="!worktimes.length" class="p-10 bg-gray-200 dark:bg-gray-800 rounded border border-gray-400 dark:border-gray-600 text-gray-600 dark:text-gray-400 mt-3">
      <span class="text-xl">{{$t('no_worktimes')}}</span>
      <p>{{$t('no_worktimes_text')}}</p>
    </div>
  </div>
  <WorktimeEditForm
    v-if="showEditForm"
    @on-item-added='onItemAdded'
    :columns="columns"
    :item="editableItem"
    :open="showEditForm"
    @on-cancel="onCancel"
    @on-item-changed="onItemChanged"
    :showAddForm="!editItem"
    :isExpenses="isExpenses || (editItem && editItem.type ===3)"
  />
</div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters, mapState } from 'vuex'
import WorktimeItemView from './WorktimeItemView.vue'
import WorktimeEditForm from './WorktimeEditForm.vue'
import DatePicker from '@/components/DatePicker.vue'
import HoursInfo from '@/components/HoursInfo.vue'
import dateMixin from '@/mixin/dateMixin.js'
import UserInfo from '../UserInfo.vue'

export default {
  props: {
    isDashboardView: Boolean
  },
  mixins: [dateMixin],
  components: {
    DatePicker,
    WorktimeEditForm,
    WorktimeItemView,
    UserInfo,
    HoursInfo
  },
  data () {
    return {
      columns: { project: 'Projekt', task: 'Tätigkeit', seconds: 'Dauer' },
      currentDate: this.formatDateToISO(new Date()),
      showEditForm: false,
      editItem: null,
      overviewType: 0,
      groupedWorktimes: [],
      isExpenses: false
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters({ worktimes: 'worktimes/all', trialExpired: 'trialExpired', team: 'team' }),
    editableItem () {
      if (this.editItem) {
        return this.editItem
      }
      return { date: this.currentDate, seconds: 0, projectId: null, taskId: null, state: 0 }
    },
    year () {
      const year = dayjs().format('YYYY')
      return year
    },
    isAdmin () {
      return this.$store.state.user.isAdmin
    },
    worktimesGrouped () {
      return this.groupedWorktimes
    },
    currentDateDayJs () {
      return dayjs(this.currentDate, 'YYYY-MM-DD')
    },
    overviewStr () {
      if (this.overviewType === 1) {
        return this.$t('week').toUpperCase()
      }
      if (this.overviewType === 2) {
        return this.$t('day').toUpperCase()
      }
      return this.$t('month').toUpperCase()
    }
  },
  methods: {
    ...mapActions({
      addWorktime: 'worktimes/add',
      removeWorktime: 'worktimes/remove',
      updateWorktime: 'worktimes/update',
      lockWorktime: 'worktimes/lock',
      unlockWorktime: 'worktimes/unlock',
      checkActiveWorktime: 'worktimes/checkActive',
      loadMineByDate: 'worktimes/loadMineByDate'
    }),
    updateOverviewTyp () {
      this.overviewType = (this.overviewType + 1) % 3
      localStorage.setItem('SAMAY_OVERVIEW_TYPE', this.overviewType)
    },
    groupBy (objectArray, property) {
      const result = objectArray.reduce(function (acc, obj) {
        const key = obj[property]
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
      return result
    },
    onItemLock (item) {
      this.lockWorktime(item)
    },
    onItemUnlock (item) {
      this.unlockWorktime(item)
    },
    async onItemAdded (item) {
      this.showEditForm = false
      this.isExpenses = false
      const addedWk = await this.addWorktime(item)
      if (addedWk.state === 1) {
        this.checkActiveWorktime(addedWk)
      }
      this.$root.$emit('updateWorktime')
    },
    onItemDeleted (item) {
      this.removeWorktime(item)
      this.$root.$emit('updateWorktime')
    },
    onItemChanged: async function (item) {
      if (item.state === 1) {
        this.checkActiveWorktime(item)
      }
      this.updateWorktime(item)
      this.showEditForm = false
      this.isExpenses = false
      this.$root.$emit('updateWorktime')
    },
    async onDateChanged (newDate) {
      this.currentDate = newDate
      await this.loadMineByDate(this.currentDateDayJs)
      this.$root.$emit('updateWorktime')
    },
    onCancel () {
      this.showEditForm = false
    },
    onEditItem (item) {
      this.editItem = item
      this.showEditForm = true
    },
    renderText (key, value) {
      switch (key) {
        case 'project':
          return value.name
        case 'task':
          return value.name
        case 'seconds':
          return this.formatMinutes(value.seconds * 60)
        default:
          return value
      }
    }
  },
  watch: {
    worktimes (value, oldValue) {
      this.groupedWorktimes = this.groupBy(value, 'date')
    }
  },
  async mounted () {
    if (this.isDashboardView) {
      this.groupedWorktimes = this.groupBy(this.worktimes, 'date')
    } else {
      const defaultTyp = localStorage.getItem('SAMAY_OVERVIEW_TYPE')
      if (defaultTyp !== null) {
        this.overviewType = parseInt(defaultTyp)
      }
      await this.loadMineByDate(this.currentDateDayJs)
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('worktimes/resetState')
    next()
  }
}
</script>
