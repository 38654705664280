<template>
<div>
  <span class="text-4xl">{{$t('team')}}</span>
  <p>
    {{$t('invite-team-text')}} <a class="text-primary hover:font-bold" :href="mailToText()" >{{$t('send-invitations')}}</a>
  </p>
  <Grid
    class="overflow-x-auto"
    :canSelect="true"
    :items="users"
    :columns="columns"
    @row-selected="onSelectRow"
  >
    <template slot="row" slot-scope="{ item, value, keyName }">
        <input v-if="keyName=='isAdmin'" type="checkbox" :checked="isAdmin(item)" :disabled="isMe(item)" @click.stop="onIsAdminChanged(item)">
        <span v-else-if="keyName === 'percent'">{{activePercentForUser(item._id)}}%</span>
        <span v-else>{{value}}</span>
    </template>
    <template slot="actions" slot-scope="{ item }">
      <div v-if="isLoading(item)" uk-spinner="ratio: 0.75"></div>
      <div class="flex items-center">
      <MonthlyWorkReport :userId="item._id"/>
      <NotifyButton class="ml-1" @on-item-notify="onItemNotify" :disabled="!item.fcmToken || isLoading(item)" :item="item"></NotifyButton>
      <ArchiveButton class="ml-1" @on-item-archived="onItemArchived" :disabled="isMe(item) || isAdmin(item)" :item="item" :tooltip="'Benutzerkonto deaktivieren'"></ArchiveButton>
      <svg class="w-3 h-3 ml-4 fill-current text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
      </div>
    </template>
  </Grid>
  <template v-if="lockedUsers.length > 0">
  <h2>{{$t('deactivated-employees')}}</h2>
  <Grid
    :items="lockedUsers"
    :columns="columnsLocked">
    <template slot="row" slot-scope="{ item, value, keyName }">
        <input class="cursor-pointer" v-if="keyName=='isAdmin'" type="checkbox" :checked="isAdmin(item)" :disabled="isMe(item)" @click.stop="onIsAdminChanged(item)">
        <span v-else>{{value}}</span>
    </template>
    <template slot="actions" slot-scope="{ item }">
      <div v-if="isLoading(item)" uk-spinner="ratio: 0.75"></div>
      <RestoreButton @on-item-restored="onItemRestored" :item="item"></RestoreButton>
      <DeleteButton class="ml-1" @on-item-deleted="onItemDeleted" :disabled="isMe(item) || isAdmin(item)" :item="item" :tooltip="'Benutzerkonto löschen'" :text="'Willst du der Mitarbeiter endgültig löschen?'"></DeleteButton>
    </template>
  </Grid>
  </template>

</div>
</template>

<script>
import Grid from '@/components/Grid.vue'
import { mapActions, mapGetters } from 'vuex'
import ArchiveButton from '@/components/buttons/ArchiveButton.vue'
import RestoreButton from '@/components/buttons/RestoreButton.vue'
import NotifyButton from '@/components/buttons/NotifyButton.vue'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import MonthlyWorkReport from '@/components/export/MonthlyWorkReport.vue'

export default {
  components: {
    Grid,
    ArchiveButton,
    RestoreButton,
    NotifyButton,
    DeleteButton,
    MonthlyWorkReport
  },
  data () {
    return {
      columnsUser: [],
      loadingItems: []
    }
  },
  computed: {
    ...mapGetters({
      app: 'app',
      users: 'users/active',
      userById: 'users/byId',
      lockedUsers: 'users/locked',
      team: 'team',
      user: 'user',
      activePercentForUser: 'employmentlevel/activePercentForUser'
    }),
    columns () {
      return [{ key: 'name', value: this.$t('name'), class: 'w-1/3' }, { key: 'email', value: this.$t('email'), class: 'w-1/3' }, { key: 'percent', value: this.$t('employment'), class: 'w-1/6 text-center' }, { key: 'isAdmin', value: this.$t('administrator'), class: 'w-1/6 text-center' }]
    },
    columnsLocked () {
      return [{ key: 'name', value: this.$t('name'), class: 'w-1/3' }, { key: 'email', value: this.$t('email'), class: 'w-1/3' }]
    }
  },
  methods: {
    ...mapActions({
      addNotification: 'addNotification',
      updateTeam: 'teams/update'
    }),
    onSelectRow (item) {
      this.$router.push({ path: `/app/team/${item._id}` })
    },
    mailToText: function () {
      if (!this.team) return ''
      return this.$t('invitation-mail', { code: this.team.code, name: this.team.name })
    },
    isMe: function (item) {
      return item._id === this.user._id
    },
    isAdmin: function (item) {
      return this.team.membersAdmin.includes(item._id)
    },
    async onIsAdminChanged (item) {
      const membersAdmin = this.team.membersAdmin
      if (!this.isAdmin(item)) {
        membersAdmin.push(item._id)
      } else {
        const index = membersAdmin.indexOf(item._id)
        if (index !== -1) membersAdmin.splice(index, 1)
      }
      await this.updateTeam({ id: this.team._id, payload: { membersAdmin: membersAdmin } })
      this.$store.dispatch('teams/load')
    },
    async onItemArchived (item) {
      if (!this.team) return null
      const members = this.team.members
      const index = members.findIndex(m => m === item._id.toString())
      if (index > -1) {
        const membersArchived = this.team.membersArchived
        membersArchived.push(item._id)
        members.splice(index, 1)
        await this.updateTeam({ id: this.team._id, payload: { members: members, membersArchived: membersArchived } })
        this.$store.dispatch('teams/load')
      }
    },
    async onItemRestored (item) {
      if (!this.team) return null
      const members = this.team.members
      members.push(item._id)
      const membersArchived = this.team.membersArchived
      const index = membersArchived.indexOf(item)
      membersArchived.splice(index, 1)
      await this.updateTeam({ id: this.team._id, payload: { members: members, membersArchived: membersArchived } })
      this.$store.dispatch('teams/load')
    },
    async onItemDeleted (item) {
      if (!this.team) return null
      // remove team from user
      const user = this.userById(item._id)
      const teams = user.teams
      let index = teams.indexOf(this.team._id)
      teams.splice(index, 1)
      const membersArchived = this.team.membersArchived
      index = membersArchived.indexOf(item)
      membersArchived.splice(index, 1)
      await this.updateTeam({ id: this.team._id, payload: { membersArchived: membersArchived } })
      this.$store.dispatch('teams/load')
    },
    async onItemNotify (item) {
      this.loadingItems.push(item)
      const res = await this.app.currentUser.functions.sendNotification(item.fcmToken, this.$root.$i18n.locale)
      this.loadingItems = this.loadingItems.filter(v => v !== item)
      if (res.error) {
        this.addNotification({ message: 'Ooops, da gieng was schief :-(', error: true })
        this.addNotification({ message: this.$t('message-sent') })
        return
      }
      this.addNotification({ message: this.$t('message-sent') })
    },
    isLoading (item) {
      return this.loadingItems.indexOf(item) > -1
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      await vm.$store.dispatch('users/load')
    })
  }
}
</script>
