<template>
 <div class="bg-white dark:bg-black dark:text-white w-11/12 md:max-w-md mx-auto rounded shadow-lg overflow-y-auto px-8 mt-40 pt-6 pb-8">
   <form @submit.prevent="continueTapped">

     <div class="text-2xl text-center">{{$t('hello')}} {{user.name}}!</div>
     <p class="text-center pt-2 pb-5" v-html="$t('signup-text')"> </p>
     <label class="text-sm">{{$t('samay-code')}}</label>
     <input v-on:input="clearTeam()" v-model="code" style="text-transform:uppercase" type="text" :placeholder="$t('code')"/>
     <h4 class="text-center"><span>{{$t('OR')}}</span></h4>
     <p class="py-3 text-center">{{$t('are-you-the-boss')}} </p>

     <label class="text-sm">{{$t('team-name')}}</label>
     <input v-on:input="clearCode()" v-model="teamname" type="text" :placeholder="$t('your-team-name')"/>
     <p class="text-center mt-6">
        <button type="submit" class="primary px-8" :class="{'opacity-50 cursor-not-allowed': (this.code === '') && (this.teamname === '')}" :disabled="(this.code === '') && (this.teamname === '')">
          {{$t('continue')}}
        </button>
     </p>
     <p class="text-center mt-6">
     <a class="text-primary hover:underline cursor-pointer font-semibold" @click="back">{{$t('back')}}</a>
     </p>
   </form>
 </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      code: '',
      teamname: ''
    }
  },
  computed: {
    ...mapGetters({ user: 'user', team: 'teams/active', app: 'app' })
  },
  methods: {
    ...mapActions({ logoutUser: 'logoutUser', loadingBegin: 'loadingBegin', loadingEnd: 'loadingEnd', addNotification: 'addNotification', initialize: 'initialize' }),
    clearCode () {
      this.code = ''
    },
    clearTeam () {
      this.teamname = ''
    },
    back () {
      this.code = ''
      this.teamname = ''
      this.logoutUser()
    },
    async continueTapped () {
      this.loadingBegin()
      if (this.code !== '') {
        const res = await this.app.currentUser.functions.joinTeam(this.code)
        if (res.error) {
          this.addNotification({ message: 'Ooops, dieser Samay Code existiert nicht', error: true })
          this.loadingEnd()
          return
        }
        this.initialize().then(() => {
          this.loadingEnd()
          this.$router.replace('/app/worktimes')
        })
      } else {
        const res = await this.app.currentUser.functions.createTeam(this.teamname)
        if (res.error) {
          this.addNotification({ message: 'Ooops, da gieng was schief :-(', error: true })
          this.loadingEnd()
          return
        }
        this.initialize().then(() => {
          this.loadingEnd()
          this.$router.replace('/app/')
        })
      }
    }
  }
}
</script>
