<style scoped>
.approved {
  background-color: #4cd964;
}
</style>

<style>
.flatpickr-day.selected, .flatpickr-day.selected:hover {
  @apply bg-primary border-primary;
}
</style>
<template>
  <div>
    <div class="flex w-full flex-wrap md:flex-nowrap justify-between items-end border-b pb-4 border-gray-500">
      <div class="flex w-full md:w-auto flex-wrap items-center">
        <div class="w-full md:w-auto md:mr-2">
            <label class="text-xs">{{$t('time-range')}}</label>
              <div class="relative flex gap-1 w-full">
                <v-select class="w-1/2 md:w-40" :options="dateRanges" :mandatory="true" :disableSearch="true" v-model="dateRange" @input="reloadWorktimes(true)">
                  <template v-slot:default="prop">
                    {{prop.item ? prop.item.name : $t('all')}}
                  </template>
                </v-select>
                <flat-pickr
                      ref="flatpickr"
                      v-model="filterRange"
                      :config="config"
                      class="ml-1 py-1 px-2 text-xs w-1/2 md:w-48 cursor-pointer text-ellipsis pr-6"
                      :placeholder="filterRangePlaceholder"
                      @on-open="onOpenPickr"
                      name="date">
                </flat-pickr>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 dark:text-gray-200">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>

        </div>

        <div class="w-1/2 md:w-40">
          <label class="text-xs">{{$t('project')}}</label>
          <v-select class="mr-1 md:pr-2 md:w-40" :options="projects" v-model="activeProject" @input="reloadWorktimes">
            <template v-slot:default="prop">
              {{prop.item ? prop.item.name : $t('all')}}
            </template>
          </v-select>
        </div>

        <div class="w-1/2 md:w-40">
          <label class="text-xs">{{$t('absences')}}</label>
          <v-select class="mr-1 md:pr-2 md:w-40" :options="absProjects" v-model="activeProject" @input="reloadWorktimes">
            <template v-slot:default="prop">
              {{prop.item ? prop.item.name : $t('all')}}
            </template>
          </v-select>
        </div>

        <div class="w-1/2 md:w-40">
          <label class="text-xs">{{$t('task')}}</label>
          <v-select class="mr-1 md:pr-2 md:w-40" :options="tasks" v-model="activeTask" @input="reloadWorktimes">
            <template v-slot:default="prop">
              {{prop.item ? prop.item.name : $t('all')}}
            </template>
          </v-select>
        </div>

        <div class="w-1/2 md:w-40">
          <label class="text-xs">{{$t('users')}}</label>
          <v-select class="mr-1 md:pr-2 md:w-40" :options="users" v-model="activeUser" @input="reloadWorktimes">
            <template v-slot:default="prop">
              {{prop.item ? prop.item.name : $t('all')}}
            </template>
          </v-select>
        </div>

        <div class="w-1/2 md:w-40">
          <label class="text-xs">{{$t('type')}}</label>
          <v-select class="ml-1 md:ml-0 md:w-40" :options="types" v-model="activeType" @input="reloadWorktimes">
            <template v-slot:default="prop">
              {{prop.item ? prop.item.name : $t('all')}}
            </template>
          </v-select>
        </div>

         <div v-if="isFilterActive()"><br>
          <button class="primary h-6 p-0 text-xs px-2" @click="resetFilter">{{$t('reset')}}</button>
         </div>
      </div>
      <ExportCsv class="mt-2 w-1/2 md:mt-0 md:w-auto" :worktimes="worktimes"></ExportCsv>
  </div>

    <div class="flex items-end flex-wrap-reverse mt-2">
      <div v-if="worktimes.length === 0" class="w-full p-10 bg-gray-200 dark:bg-gray-800 rounded border border-gray-400 dark:border-gray-600 text-gray-600 dark:text-gray-400">
        <span class="text-xl">{{$t('no-worktimes')}}</span>
        <p>{{$t('no-worktimes-hint')}}</p>
      </div>
      <template v-else>
      <div class="w-full mt-6 sm:mt-0 sm:w-1/2 sm:pr-5">
        <WorktimesView isDashboardView></WorktimesView>
      </div>
      <div class="w-full sm:w-1/2 shadow sm:sticky sm:top-0">
        <div class="flex justify-between items-center text-center border-b border-gray-400 dark:border-gray-600 mb-2">
            <a class="w-full py-2 border-b-2 border-white dark:border-gray-800  hover:bg-gray-100 dark:hover:bg-gray-900" :class="{'border-primary dark:border-primary' : activeTab===1}" href="#" @click="activeTab=1">
              💼 <br> <span class="text-sm text-gray-600 dark:text-gray-400 uppercase">{{$t('projects')}}</span>
            </a>
            <a class="w-full py-2 border-b-2 border-white dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-900" :class="{'border-primary dark:border-primary' : activeTab===2}" href="#" @click="activeTab=2">
              🏋🏽‍♂️<br> <span class="text-sm text-gray-600 dark:text-gray-400 uppercase">{{$t('tasks')}}</span>
            </a>
            <a class="w-full py-2 border-b-2 border-white dark:border-gray-800  hover:bg-gray-100 dark:hover:bg-gray-900" :class="{'border-primary dark:border-primary' : activeTab===3}" href="#" @click="activeTab=3">
              👷🏽‍♀️ <br><span class="text-sm text-gray-600 dark:text-gray-400 uppercase">{{$t('employees')}}</span>
            </a>
        </div>
          <div v-if="activeTab===1">
            <Grid
              class="h-96 overflow-y-scroll"
              :small="true"
              :items="worktimesByProject"
              :columns="projectColumns">
              <template slot="row" slot-scope="{ value, keyName }">
                {{renderText(keyName, value)}}
              </template>
              <template slot="footer" slot-scope="{ index }">
                <tr class="border-t border-gray-400 hover:bg-gray-100 dark:hover:bg-black-800 text-sm" :class="{'bg-gray-100 dark:bg-gray-800' : (index % 2) === 0}"><td class="p-2"><b>Total</b></td><td class="p-2 whitespace-nowrap"><b>{{formatSecondsToHM(totalSeconds)}}</b></td></tr>
              </template>
            </Grid>
          </div>
          <div v-if="activeTab===2">
            <Grid
              :small="true"
              :items="worktimesByTask"
              :columns="taskColumns">
              <template slot="row" slot-scope="{ value, keyName }">
                {{renderText(keyName, value)}}
              </template>
              <template slot="footer" slot-scope="{ index }">
                <tr class="border-t border-gray-400 hover:bg-gray-100 dark:hover:bg-black-800 text-sm" :class="{'bg-gray-100 dark:bg-gray-800' : (index % 2) === 0}"><td class="p-2"><b>Total</b></td><td class="p-2 whitespace-nowrap"><b>{{formatSecondsToHM(totalSeconds)}}</b></td></tr>
              </template>
            </Grid>
          </div>
          <div v-if="activeTab===3">
          <Grid
            :small="true"
            :items="worktimesByUser"
            :columns="userColumns">
            <template slot="row" slot-scope="{ value, keyName }">
              {{renderText(keyName, value)}}
            </template>
            <template slot="footer" slot-scope="{ index }">
              <tr class="border-t border-gray-400 hover:bg-gray-100 dark:hover:bg-black-800 text-sm" :class="{'bg-gray-100 dark:bg-gray-800' : (index % 2) === 0}"><td class="p-2"><b>Total</b></td><td class="p-2 whitespace-nowrap"><b>{{formatSecondsToHM(totalSeconds)}}</b></td></tr>
            </template>
          </Grid>
        </div>
      </div>
    </template>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import flatPickr from 'vue-flatpickr-component'

import { German } from 'flatpickr/dist/l10n/de.js'
import dateMixin from '@/mixin/dateMixin.js'
import Grid from '@/components/Grid.vue'
import WorktimesView from './worktime/WorktimesView.vue'
import ExportCsv from '@/components/export/ExportCsv.vue'
import VSelect from '@/components/v-select'
import dayjs from 'dayjs'
import { BSON } from 'realm-web'

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  require('flatpickr/dist/themes/dark.css')
} else {
  require('flatpickr/dist/flatpickr.css')
}

const quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)
export default {
  mixins: [dateMixin],
  components: {
    Grid,
    ExportCsv,
    WorktimesView,
    flatPickr,
    'v-select': VSelect
  },
  data () {
    return {
      activeTab: 1,
      activeProject: null,
      activeTask: null,
      activeUser: null,
      activeType: null,
      dateRange: 1,
      types: [
        { _id: 1, name: 'Arbeitszeiten' },
        { _id: 2, name: 'Absenzen' },
        { _id: 3, name: 'Spesen' }
      ],

      startEndDateStr: '',
      config: {
        mode: 'range',
        weekNumbers: true,
        wrap: true,
        altFormat: 'd.m.Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        defaultDate: [
          dayjs().startOf('month').format('YYYY-MM-DD'),
          dayjs().format('YYYY-MM-DD')
        ],
        locale: German,
        disable: [{
          from: dayjs().add(1, 'day').format('YYYY-MM-DD'),
          to: dayjs().add(99, 'year').format('YYYY-MM-DD')
        }]
      }
    }
  },
  computed: {
    ...mapGetters({
      worktimes: 'worktimes/all',
      projects: 'projects/active',
      absProjects: 'projects/absences',
      tasks: 'tasks/active',
      userById: 'users/byId',
      users: 'users/active',
      getProjectById: 'projects/getById',
      getTaskById: 'tasks/getById'
    }),
    projectColumns () { return [{ key: 'project', value: this.$t('projects'), class: 'w-full' }, { key: 'seconds', value: this.$t('time'), class: 'whitespace-nowrap' }] },
    taskColumns () { return [{ key: 'task', value: this.$t('task'), class: 'w-full' }, { key: 'seconds', value: this.$t('time'), class: 'whitespace-nowrap' }] },
    userColumns () { return [{ key: 'user', value: this.$t('employees'), class: 'w-full' }, { key: 'seconds', value: this.$t('time'), class: 'whitespace-nowrap' }] },
    dateRanges () {
      const startLastMonth = dayjs().month(dayjs().month() - 1).startOf('month')
      const startLastQuarter = dayjs().quarter(dayjs().quarter() - 1).startOf('quarter')
      const startLastYear = dayjs().year(dayjs().year() - 1).startOf('year')

      const tmp = [
        { _id: 1, name: 'Laufender Monat', startDate: dayjs().startOf('month'), endDate: dayjs() },
        { _id: 2, name: 'Laufendes Quartal', startDate: dayjs().startOf('quarter'), endDate: dayjs() },
        { _id: 3, name: 'Laufendes Jahr', startDate: dayjs().startOf('year'), endDate: dayjs() },
        { _id: 4, name: 'Letzter Monat', startDate: startLastMonth, endDate: startLastMonth.endOf('month') },
        { _id: 5, name: 'Letztes Quartal', startDate: startLastQuarter, endDate: startLastQuarter.endOf('quarter') },
        { _id: 6, name: 'Letzes Jahr', startDate: startLastYear, endDate: startLastYear.endOf('year') },
        { _id: 7, name: 'Custom' }
      ]
      let start = dayjs().startOf('month').month(dayjs().month() - 2)
      let tmpMonth = start.month()
      for (let i = 0; i < 10; i++) {
        start = start.month(tmpMonth).startOf('month')
        tmp.push({ _id: 8 + i, name: start.format('MMM YYYY'), startDate: start, endDate: start.endOf('month') })
        tmpMonth = start.month() - 1
        if (tmpMonth < 0) {
          tmpMonth = 11
          start = start.year(start.year() - 1)
        }
      }
      return tmp
    },
    filterRange: {
      get: function () {
        return this.startEndDateStr
      },
      set: function (value) {
        this.startEndDateStr = value
        if (this.dateRange === 7) {
          this.onDateChange()
        }
      }
    },
    filterRangePlaceholder () {
      return this.$t('choose-time-range')
    },
    startEndDate: function () {
      return this.startEndDateStr.split(' ')
    },
    worktimesGrouped: function () {
      return this.groupedWorktimes
    },
    worktimesByTask: function () {
      return this.worktimes.reduce((acc, obj) => {
        const id = (obj.taskId.toString() !== '000000000000000000000000' ? obj.taskId : -1)
        const name = (obj.taskId.toString() !== '000000000000000000000000' ? this.getTaskById(obj.taskId).name : this.$t('none'))
        const ix = acc.findIndex(elem => elem.task_id === id.toString())
        if (!~ix) {
          acc.push({ task_id: id.toString(), task: name, seconds: obj.seconds })
        } else {
          acc[ix].seconds += obj.seconds
        }
        return acc
      }, [])
    },
    worktimesByProject: function () {
      const projects = this.worktimes.reduce((acc, obj) => {
        const id = (obj.projectId.toString() !== '000000000000000000000000' ? obj.projectId.toString() : -1)
        const name = (obj.projectId.toString() !== '000000000000000000000000' && this.getProjectById(obj.projectId) ? this.getProjectById(obj.projectId).name : this.$t('none'))
        const ix = acc.findIndex(elem => elem.project_id === id.toString())
        if (!~ix) {
          acc.push({ project_id: id.toString(), project: name, seconds: obj.seconds })
        } else {
          acc[ix].seconds += obj.seconds
        }
        return acc
      }, [])
      return projects.sort((a, b) => a.project.localeCompare(b.project, undefined, { numeric: true, sensitivity: 'base' }))
    },
    worktimesByUser: function () {
      return this.worktimes.reduce((acc, obj) => {
        const ix = acc.findIndex(elem => elem.createdBy === obj.createdBy)
        if (!~ix) {
          const user = this.userById(obj.createdBy)
          let u = { name: '???', email: '' }
          if (user) {
            u = { name: user.name, email: user.email }
          }
          acc.push({ createdBy: obj.createdBy, user: u, seconds: obj.seconds })
        } else {
          acc[ix].seconds += obj.seconds
        }
        return acc
      }, [])
    },
    totalSeconds: function () {
      let result = 0
      this.worktimesByUser.forEach((item) => {
        result += item.seconds
      })
      return result
    }
  },
  methods: {
    ...mapActions({
      loadWorktimes: 'worktimes/load'
    }),
    isFilterActive: function () {
      return this.activeProject || this.activeTask || this.activeUser || this.dateRange !== 1
    },
    formatUser: function (value) {
      return value.name ? value.name : value.email
    },
    resetFilter: function () {
      this.dateRange = 1
      this.activeProject = null
      this.activeTask = null
      this.activeUser = null
      this.activeType = null
      this.startEndDateStr = ''
      this.reloadWorktimes()
    },
    renderText: function (key, value) {
      switch (key) {
        case 'seconds':
          return this.formatSecondsToHM(value)
        case 'user':
          return value.name ? value.name : value.email
        default:
          return value
      }
    },
    onOpenPickr () {
      this.dateRange = 7
    },
    onClosePickr () {
      if (this.startEndDate.length < 2) {
        this.filterRange = dayjs().startOf('month').format('YYYY-MM-DD') + ' bis ' + dayjs().format('YYYY-MM-DD')
      }
    },
    onDateChange () {
      if (this.startEndDate.length > 1) {
        this.reloadWorktimes()
      }
    },
    reloadWorktimes (open) {
      const selectedDateRange = this.dateRanges.find(d => d._id === this.dateRange)
      let startDate = selectedDateRange.startDate
      let endDate = selectedDateRange.endDate
      if (this.dateRange === 7) {
        if (open === true) {
          this.$refs.flatpickr.fp.open()
        }
        startDate = this.startEndDate[0] ? dayjs(this.startEndDate[0], 'YYYY-MM-DD') : dayjs().startOf('month')
        endDate = this.startEndDate[2] ? dayjs(this.startEndDate[2], 'YYYY-MM-DD') : dayjs()
      }
      if (this.dateRange !== 7) {
        if (this.$refs.flatpickr.fp) {
          this.$refs.flatpickr.fp.close()
        }
        this.filterRange = startDate.format('YYYY-MM-DD') + ' bis ' + endDate.format('YYYY-MM-DD')
      }
      const query = {
        date: {
          $gte: startDate.toDate(),
          $lte: endDate.endOf('day').toDate()
        }
      }
      if (this.activeProject) {
        query.projectId = new BSON.ObjectId(this.activeProject)
      }
      if (this.activeTask) {
        query.taskId = new BSON.ObjectId(this.taskId)
      }
      if (this.activeUser) {
        query.createdBy = this.activeUser
      }
      if (this.activeType) {
        query.type = this.activeType
      }
      this.loadWorktimes(query)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      await vm.reloadWorktimes()
    })
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('worktimes/resetState')
    next()
  },
  async mounted () {

  }
}
</script>
