import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router.js'
import VueI18n from 'vue-i18n'
import messages from '@/assets/lang'
import SimpleAnalytics from 'simple-analytics-vue'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import VTooltip from 'v-tooltip'
import VueCookies from 'vue-cookies'

Vue.use(VTooltip)
Vue.use(VueCookies)
Vue.$cookies.config('9999d')

Vue.use(SimpleAnalytics, { skip: process.env.VUE_APP_URL !== 'https://www.samay.ch' })

Vue.config.productionTip = false

Vue.use(VueI18n)

router.beforeEach(async (to, from, next) => {
  if (to.path.includes('app')) {
    // user is logged in
    if (store.state.app.currentUser) {
      // Load data if not yet initialized
      if (!store.getters.initialized) {
        await store.dispatch('initialize')
      }
      // check if has a team
      if (!store.getters.team) {
        if (to.path !== '/app/signup/team') {
          next({ path: '/app/signup/team' })
        } else {
          next()
        }
        return
      }
      // check if team is active or trial
      if (store.getters.trialExpired && !store.getters.team.activated && (to.path !== '/app/settings')) {
        if (store.getters.isAdmin) {
          next({ path: '/app/settings' })
          return
        }
      }

      store.dispatch('teams/checkUserIsActive', store.getters.team)
      // prevent go to signup team if already in team
      if (store.getters.team && (to.path === '/app/signup/team')) {
        next({ path: '/app' })
        return
      }
      // prevent go to login page if already logged in
      if ((to.path === '/app/login')) {
        next({ path: '/app' })
        return
      }
      // redirect non admins to app worktimes
      if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (!store.getters.isAdmin) {
          next({ path: '/app/worktimes' })
          return
        }
      }
    // user not logged in yet
    } else {
      if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.app.currentUser) {
          next({ path: '/app/login' })
          return
        }
      }
    }
  }
  next()
})

const locale = Vue.$cookies.get('samay_locale')

export const i18n = new VueI18n({
  locale: locale || navigator.language.substring(0, 2),
  fallbackLocale: 'de',
  messages
})

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
