import { BSON } from 'realm-web'
import { i18n } from '@/main.js'

// initial state
const state = {
  team: null,
  account: null,
  stream: null
}

// getters
const getters = {
  active: state => state.team,
  account: state => state.account
}

// actions
const actions = {
  checkUserIsActive ({ commit, rootGetters, dispatch }, team) {
    if (team.membersArchived.find(id => id === rootGetters.user._id)) {
      dispatch('addNotification', { title: i18n.t('useraccount-deactivated'), message: i18n.t('useraccount-deactivated-text'), error: true, permanent: true }, { root: true })
      dispatch('logoutUser', null, { root: true })
      return false
    }
    return true
  },
  checkUserIsAdmin ({ commit, rootGetters, dispatch }, team) {
    const isAdmin = team.membersAdmin.find(id => id === rootGetters.user._id) !== undefined
    if (!rootGetters.isAdmin && isAdmin) {
      dispatch('addNotification', { title: i18n.t('new-admin'), message: i18n.t('new-admin-text'), permanent: true }, { root: true })
      dispatch('initialize', null, { root: true })
    } else if (rootGetters.isAdmin && !isAdmin) {
      dispatch('addNotification', { title: i18n.t('admin-removed'), message: i18n.t('admin-removed-text'), permanent: true }, { root: true })
      dispatch('initialize', null, { root: true })
    }
    return true
  },
  async load ({ commit, dispatch, state, rootGetters }) {
    let team = null
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const teams = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Team')
    if (rootGetters.user === null || rootGetters.user.teams === undefined) {
      dispatch('logoutUser', null, { root: true })
      return
    }
    team = await teams.findOne({ _id: new BSON.ObjectId(rootGetters.user.teams[0]) })
    const prom = new Promise((resolve) => {
      commit('setTeam', team)
      resolve()
    })
    return prom
  },
  async watch ({ state, commit, rootGetters }) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const teams = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Team')
    const stream = teams.watch()
    commit('setStream', stream)
    for await (const change of state.stream) {
      const { operationType } = change
      switch (operationType) {
        case 'update': {
          commit('setTeam', change.fullDocument)
          break
        }
      }
    }
  },
  update ({ rootGetters }, { id, payload }) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const teams = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Team')
    return teams.updateOne({ _id: new BSON.ObjectId(id) }, { $set: payload })
  },
  async loadAccount ({ commit, rootGetters, dispatch }) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const accounts = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Account')
    const account = await accounts.findOne({ _partition: `account=${rootGetters.team._id}` })
    const prom = new Promise((resolve) => {
      commit('setAccount', account)
      resolve()
    })
    return prom
  },
  async watchAccount ({ commit, rootGetters }) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const teams = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Account')
    for await (const change of teams.watch()) {
      const { operationType } = change
      switch (operationType) {
        case 'update': {
          commit('setAccount', change.fullDocument)
          break
        }
      }
    }
  }
}

// mutations
const mutations = {
  setStream (state, stream) {
    state.stream = stream
  },
  setTeam (state, team) {
    state.team = team
  },
  setAccount (state, account) {
    state.account = account
  },
  resetState (state) {
    if (state.stream) state.stream.return()
    state.team = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
