import { BSON } from 'realm-web'
import dayjs from 'dayjs'
import Vue from 'vue'

// initial state
const state = {
  all: [],
  stream: null
}

// getters
const getters = {
  all: (state) => state.all,
  getByUser: (state) => (userId) => {
    return state.all.filter(balance => balance.userId === userId)
  },
  getVacationByUserAndYear: (state) => (userId, year) => {
    const b = state.all.find(balance => balance.userId === userId && balance.year === parseInt(year))
    if (b !== undefined) {
      return b.balanceVacation
    }
    return 0
  },
  getWorktimeByUserAndYear: (state) => (userId, year) => {
    const b = state.all.find(balance => balance.userId === userId && balance.year === parseInt(year))
    if (b !== undefined) {
      return b.balanceWorktime
    }
    return 0
  }
}

// actions
const actions = {
  async load ({ state, commit, rootGetters }) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const balances = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Balance')
    balances.find({ _partition: `team=${rootGetters.team._id}` }).then(balances => {
      commit('setBalances', balances)
    })
    // const stream = balances.watch()
    // commit('setStream', stream)
    // for await (const change of state.stream) {
    //   const { operationType } = change
    //   switch (operationType) {
    //     case 'insert': {
    //       commit('add', change.fullDocument)
    //       break
    //     }
    //     case 'update': {
    //       commit('update', change.fullDocument)
    //       break
    //     }
    //     case 'replace': {
    //       commit('update', change.fullDocument)
    //       break
    //     }
    //     case 'delete': {
    //       commit('remove', change.documentKey)
    //       break
    //     }
    //   }
    // }
  },
  async add ({ commit, dispatch, rootGetters }, userId) {
    const item = {
      _id: new BSON.ObjectID(),
      _partition: `team=${rootGetters.team._id}`,
      userId: userId,
      year: parseInt(dayjs().format('YYYY')),
      balanceWorktime: { $numberDouble: '0.0' },
      balanceVacation: { $numberDouble: '0.0' }
    }
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const balances = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Balance')
    await balances.insertOne(item)
    dispatch('load')
  },
  async remove ({ dispatch, rootGetters }, item) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const balances = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Balance')
    await balances.deleteOne({ _id: item._id })
    dispatch('load')
  },
  async update ({ dispatch, rootGetters }, item) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const balances = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Balance')
    const updateItem = {
      userId: item.userId,
      year: item.year,
      balanceWorktime: { $numberDouble: item.balanceWorktime.toString() },
      balanceVacation: { $numberDouble: item.balanceVacation.toString() }
    }
    await balances.updateOne({ _id: new BSON.ObjectId(item._id) }, { $set: updateItem })
    dispatch('load')
  }
}

// mutations
const mutations = {
  setBalances (state, balances) {
    state.all = balances
  },
  update (state, item) {
    const index = state.all.findIndex(p => p._id.toString() === item._id.toString())
    Vue.set(state.all, index, item)
  },
  add (state, item) {
    state.all.push(item)
  },
  remove (state, id) {
    const index = state.all.findIndex(p => p._id.toString() === id.toString())
    state.all.splice(index, 1)
  },
  setStream (state, stream) {
    state.stream = stream
  },
  resetState (state) {
    if (state.stream) state.stream.return()
    state.all = []
  },
  resetBalanceForUser (state, item) {
    const index = state.all.findIndex(o => o._id === item._id)
    Vue.set(state.all, index, item)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
