<template>
  <div v-if="open" class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
    <div class="absolute w-full h-full bg-gray-900 opacity-75"></div>
    <div class="bg-white dark:bg-black w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto px-5 -mt-64">
      <form @submit.prevent="done">
        <h2>{{$t('your-profile')}}</h2>
        <div class="relative flex items-center justify-center">
          <Avatar :user="user" :image="image"/>
          <div @click="onPickFile" class="cursor-pointer flex justify-center items-center absolute w-20 h-20 text-xs bg-primary bg-opacity-50 p-1 rounded-full text-white opacity-0 hover:opacity-100">
            <span class="block"> Bearbeiten </span>
          </div>
          <input
            type="file"
            style="display: none"
            ref="fileInput"
            accept="image/*"
            @change="onFilePicked"/>
        </div>
        <div>
        <div class="my-3">
          <label class="text-sm">{{$t('name')}}</label>
          <input type="text" v-model="newName" :placeholder="$t('your-name')"/>
        </div>
        <div class="my-3">
          <label class="text-sm">Email</label>
          <input type="email" v-model="newEmail" :placeholder="$t('your-email')"/>
        </div>
      </div>
      <div class="flex justify-end pt-6 pb-4">
            <button @click="close" type="button">{{$t('cancel')}}</button>
            <button class="primary ml-2" type="submit">{{$t('save')}}</button>
        </div>
      </form>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import APIClient from '@/assets/js/APIClient.js'
import Avatar from '@/components/Avatar.vue'

export default {
  components: {
    Avatar
  },
  props: {
    open: Boolean,
    email: String,
    name: String
  },
  data () {
    return {
      newName: this.name,
      newEmail: this.email,
      image: null,
      mimeType: null
    }
  },
  computed: {
    ...mapGetters({ user: 'user', app: 'app' })
  },
  methods: {
    ...mapActions({
      addNotification: 'addNotification',
      updateUser: 'users/update',
      updateAvatar: 'updateAvatar'
    }),
    done () {
      if (this.newName === '') {
        this.addNotification({ message: this.$t('error-enter-name'), error: true })
        return
      }
      if (this.newEmail === '') {
        this.addNotification({ message: this.$t('error-enter-email'), error: true })
        return
      }
      APIClient
        .get(`auth/checkEmail?email=${this.newEmail}`)
        .then(r => r.data)
        .then(result => {
          if (!result.emailValid) {
            this.addNotification({ message: this.$t('error-invalid-email'), error: true })
            return
          }
          if (this.newEmail !== this.user.email) {
            if (result.userExist) {
              this.addNotification({ message: this.$t('error-email-exists'), error: true })
              return
            }
          }
          this.changeProfile()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    async changeProfile () {
      let changed = false
      if (this.newEmail !== this.user.email) {
        const res = await this.app.currentUser.functions.updateUserEmail(this.user.email, this.newEmail)
        if (res.error) {
          this.addNotification({ message: 'Ooops, da gieng was schief :-(', error: true })
          return
        }
        changed = true
      }
      if (this.newName !== this.user.name) {
        changed = true
      }
      if (this.image) {
        this.app.currentUser.functions.uploadAvatar(this.image, this.mimeType).then(r => {
          const payload = { userId: this.user._id, filename: r, data: this.image }
          this.updateAvatar(payload)
        })
      }
      if (changed) {
        await this.updateUser({ id: this.user._id, payload: { name: this.newName, email: this.newEmail } })
        await this.$store.dispatch('users/loadMe')
        this.addNotification({ message: 'Check!' })
      }
      this.close()
    },
    close () {
      this.$emit('on-modal-closed')
    },
    onPickFile () {
      this.$refs.fileInput.click()
    },
    onFilePicked (event) {
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        const base64ContentArray = fileReader.result.split(',')
        this.mimeType = base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0]
        this.image = base64ContentArray[1]
      })
      fileReader.readAsDataURL(files[0])
    }
  }
}
</script>
