<template>
  <div class="flex items-center">
    <div class="cursor-pointer hover:text-white" :class="{'text-white' : $i18n.locale.startsWith('de')}" @click="change('de')">DE</div>
    <div class="cursor-pointer ml-2 hover:text-white" :class="{'text-white' : $i18n.locale.startsWith('en')}"  @click="change('en')">EN</div>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  methods: {
    change (lang) {
      this.$root.$i18n.locale = lang
      this.$cookies.set('samay_locale', lang)
    }
  }
}
</script>
