<template>
  <div class="">
    <form v-if="showSearch">
    <input :placeholder="$t('filter_placeholder')" name="query" v-model="filterKey" class="">
    </form>
    <table class="w-full table-auto">
      <thead>
        <tr class="">
          <th :class="[small ? 'p-2 text-sm' : 'px-4 py-3', entry.class]" class="text-left font-normal uppercase text-sm text-gray-600 dark:text-gray-400" v-for="(entry) in columns" :key="entry.key">
            {{ entry.value }}
          </th>
          <th v-if="hasActionsSlot"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(entry, index) in filteredItems">
        <tr v-if="flat || !entry.parent || entry.parent.toString() === '000000000000000000000000' || isExpanded(entry.parent)" :key="index"
          class="border-t border-gray-400 dark:border-gray-600 hover:bg-blue-200 hover:bg-opacity-25 dark:hover:bg-gray-800 even:bg-gray-100 dark:even:bg-gray-900"
          @click="onClickRow(entry)"
          :class="[
            hasChilds(entry._id) || canSelect ? 'cursor-pointer' : ''
          ]"
        >
            <td v-for="(value) in columns" :key="value.key"
             :class="[small ? 'p-2 text-sm' : 'p-4', value.class, entry.parent && entry.parent.toString() !== '000000000000000000000000' ? (findParent(entry.parent.toString()).parent.toString() !== '000000000000000000000000' ? 'pl-10' : 'pl-6') : '']">
             <div class="flex items-center">
              <div v-if="!flat && hasChilds(entry._id)">
                  <svg class="w-3 mr-2 dark:text-white fill-current" :class="{'transform rotate-90' : isExpanded(entry._id)}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
              </div>
              <div class="w-full">
                <slot name="row" :item="entry" :key-name="value.key" :value="entry[value.key]">{{entry[value.key]}}</slot>
              </div>
             </div>
            </td>
            <td v-if="hasActionsSlot" class="w-1">
              <div class="flex justify-end mr-2">
                <slot name="actions" :item="entry"></slot>
              </div>
            </td>
        </tr>
        </template>
          <slot name="footer" :index="filteredItems.length"></slot>
       </tbody>
     </table>
   </div>
</template>

<script>

export default {
  props: {
    items: Array,
    columns: Array,
    basePath: String,
    showSearch: Boolean,
    canSelect: Boolean,
    small: Boolean,
    parentKey: String,
    flat: Boolean
  },
  data () {
    return {
      filterKey: '',
      expanded: []
    }
  },
  computed: {
    hasActionsSlot () {
      return this.$scopedSlots.actions
    },
    columnCount () {
      let result = Object.keys(this.columns).length
      if (this.hasActionsSlot) {
        result += 1
      }
      return result
    },
    filteredItems () {
      const filterKey = this.filterKey && this.filterKey.toLowerCase()
      let items = this.items
      if (filterKey) {
        items = items.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
          })
        })
      }
      return items
    }
  },
  methods: {
    onClickRow (item) {
      if (this.hasChilds(item._id)) {
        this.expandRow(item)
      } else if (this.canSelect) {
        this.$emit('row-selected', item)
      }
    },
    expandRow (item) {
      const index = this.expanded.indexOf(item._id.toString())
      if (index > -1) {
        this.expanded.splice(index, 1)
      } else {
        this.expanded.push(item._id.toString())
      }
    },
    isExpanded (itemId) {
      return this.expanded.indexOf(itemId.toString()) > -1
    },
    hasChilds (itemId) {
      return this.items.find(item => item.parent && item.parent.toString() !== '000000000000000000000000' && (item.parent.toString() === itemId.toString())) !== undefined
    },
    findParent (itemId) {
      return this.items.find(item => item._id.toString() === itemId.toString())
    }
  }
}
</script>
