<style scoped>
 .dropdown {
   max-height: 300px;
 }
</style>
<template>
<div>
  <div v-if="showMenu" class="top-0 left-0 z-50 fixed w-full h-screen" @click="close()"></div>
  <div
    class="inline-block relative w-full cursor-pointer"
    :class="{'cursor-not-allowed' : disabled}"
    @click.stop="!disabled ? open(): null"
  >
    <div class="bg-gray-200 dark:bg-gray-600 px-2 py-1 inline-flex items-center justify-between w-full rounded" :class="{'ring-2 ring-primary border-transparent': showMenu}">
      <div class="whitespace-nowrap text-xs truncate">
      <slot v-bind:item="selected">{{selected}}</slot>
      </div>
      <svg class="fill-current h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
    </div>
    <div class="absolute left-0 z-50 shadow-lg mt-1 rounded bg-white dark:bg-black min-w-min w-full" :class="{ 'hidden': !showMenu }">
      <div class="relative">
        <div v-if="(options.length > 5) && !disableSearch" class="absolute top-0 left-0 w-full p-1 bg-gray-200 dark:bg-gray-800">
          <input autofocus v-model="filterKey" @click.stop="" ref="search" class="text-xs bg-white border-gray-500 dark:bg-black dark:text-gray-300" placeholder="Suchen...">
        </div>
        <div class="dropdown overflow-y-auto min-w-full border border-gray-300 dark:border-gray-700" :class="{'pt-12' : (options.length > 5) && !disableSearch}">
          <div
            v-for="(item, index) in filteredOptions" :key="index"
            class="flex items-center even:bg-gray-100 dark:even:bg-gray-900  hover:bg-primary dark:hover:bg-primary hover:text-white"
            :class="[isActive(item) ? 'font-bold' : '', wasActive(item) ? 'bg-primary even:bg-primary text-white' : '']"
          >
            <a
              :class="[item.parent && item.parent.toString() !== '000000000000000000000000' ? (item.parent.parent ? 'pl-10' : 'pl-6') : '']"
              class="text-xs px-2 py-1 block whitespace-nowrap cursor-pointer truncate w-full"
              href="#"
              @click.stop.prevent="itemChanged(item)"
              @mouseover="activeHelper = false"
            >
              <slot v-bind:item="item">
                {{item}}
              </slot>
            </a>
            <svg v-if="isActive(item)" class="h-5 w-5 mr-2" x-description="Heroicon name: check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  props: ['value', 'options', 'disabled', 'disableSearch', 'mandatory'],
  data () {
    return {
      showMenu: false,
      activeHelper: true,
      filterKey: null
    }
  },
  computed: {
    selected () {
      if (!this.value) {
        return false
      }
      return this.options.find(o => o._id.toString() === this.value.toString())
    },
    filteredOptions () {
      let items = this.options
      if (this.filterKey) {
        const filterKey = this.filterKey.toLowerCase()
        items = items.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
          })
        })
      }
      return items.length ? items : this.options
    }
  },
  methods: {
    itemChanged (item) {
      this.showMenu = false
      this.activeHelper = true
      this.filterKey = null
      if (this.isActive(item) && !this.mandatory) {
        this.$emit('input', null)
      } else {
        this.$emit('input', item._id)
      }
    },
    isActive (item) {
      return this.value === item._id
    },
    wasActive (item) {
      return this.value === item._id && this.activeHelper
    },
    open () {
      window.addEventListener('keyup', this.closeListener)
      this.showMenu = !this.showMenu
      this.$nextTick(() => {
        this.setFocus()
      })
    },
    close () {
      this.showMenu = false
      this.activeHelper = true
      this.filterKey = null
    },
    setFocus (e) {
      if (this.$refs.search !== undefined && document.activeElement !== this.$refs.search) {
        this.$refs.search.focus()
      }
    },
    closeListener (e) {
      if (this.showMenu && e.key === 'Escape') {
        e.stopImmediatePropagation()
        this.close()
      }
    }
  },
  mounted () {
    window.addEventListener('keyup', this.closeListener)
  }
}
</script>
