<template>
<div>
  <div class="flex items-center">
    <span class="text-4xl mr-2">{{$t('tasks')}}</span>
    <EditItemForm @on-item-added='onItemAdded' :columns="columns" :item="newItem" showAddForm/>
  </div>
  <ul class="flex mb-4 py-2 border-b-2 border-gray-400">
  <li class="mr-1">
    <a @click="activeTab=1" class="inline-block px-4 py-1 font-bold text-gray-800 rounded-full hover:bg-primary hover:bg-opacity-25 hover:text-primary"
       :class="{'text-primary' : activeTab === 1}" href="#">
       {{$t('task-active')}}
    </a>
  </li>
  <li class="mr-1">
    <a @click="activeTab=2" class="inline-block px-4 py-1 font-bold text-gray-800 rounded-full hover:bg-primary hover:bg-opacity-25 hover:text-primary"
      :class="{'text-primary' : activeTab === 2}" href="#">
      {{$t('archive')}}
    </a>
  </li>
  </ul>
  <div class="flex w-full">
  <div v-if="activeTab===1" class="w-full">
    <div v-if="activeTasks.length === 0" class="w-full p-10 bg-gray-200 dark:bg-gray-800 rounded border border-gray-400 dark:border-gray-600 text-gray-600 dark:text-gray-400 mt-2">
      <span class="text-xl">{{$t('no-tasks')}}</span>
      <p>{{$t('no-tasks-hint')}}</p>
    </div>
    <Grid
      v-else
      :items="activeTasks"
      :columns="columns">
        <template slot="actions" slot-scope="{ item }">
          <EditItemForm @on-item-changed="onItemChanged" :item="item" :columns="columns"></EditItemForm>
          <ArchiveButton class="ml-1" @on-item-archived="onItemArchived" :item="item"></ArchiveButton>
        </template>
    </Grid>
  </div>
  <div v-if="activeTab===2" class="w-full">
    <div v-if="archivedTasks.length === 0" class="w-full p-10 bg-gray-200 dark:bg-gray-800 rounded border border-gray-400 dark:border-gray-600 text-gray-600 dark:text-gray-400 mt-2">
      <span class="text-xl">{{$t('no-tasks')}}</span>
      <p>{{$t('no-archive-tasks-hint')}}</p>
    </div>
    <Grid
      v-else
      :items="archivedTasks"
      :columns="columns">
      <template slot="actions" slot-scope="{ item }">
        <RestoreButton @on-item-restored="onItemRestored" :item="item"></RestoreButton>
        <DeleteButton class="ml-1" @on-item-deleted="onItemDeleted" :item="item" :text="'Willst du die Tätigkeit wirklich löschen? (Die Tätigkeit wird auch bei Arbeitszeiten mit dieser Tätigkeit entfernt)'"></DeleteButton>
      </template>
    </Grid>
  </div>
  </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { BSON } from 'realm-web'
import Grid from '@/components/Grid.vue'
import EditItemForm from '@/components/EditItemForm.vue'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import ArchiveButton from '@/components/buttons/ArchiveButton.vue'
import RestoreButton from '@/components/buttons/RestoreButton.vue'

export default {
  components: {
    EditItemForm,
    DeleteButton,
    ArchiveButton,
    RestoreButton,
    Grid
  },
  data () {
    return {
      activeTab: 1,
      newItem: { }
    }
  },
  computed: {
    ...mapGetters({
      activeTasks: 'tasks/active',
      archivedTasks: 'tasks/archived'
    }),
    columns () {
      return [{ key: 'name', value: this.$t('description'), class: '' }]
    }
  },
  methods: {
    ...mapActions({
      updateWorktimes: 'worktimes/updateMany',
      addTask: 'tasks/add',
      updateTask: 'tasks/update',
      deleteTask: 'tasks/remove'
    }),
    onItemAdded (item) {
      this.addTask(item)
    },
    onItemDeleted (item) {
      this.updateWorktimes({ query: { taskId: item._id }, update: { $set: { taskId: new BSON.ObjectId('000000000000000000000000') } } })
      this.deleteTask(item)
    },
    onItemChanged (item) {
      this.updateTask(item)
    },
    onItemArchived (item) {
      item.isArchived = true
      this.updateTask(item)
    },
    onItemRestored (item) {
      item.isArchived = false
      this.updateTask(item)
    }
  }
}
</script>
