
<template>
  <div>
    <div v-if="open" class="absolute left-0 top-0 w-screen h-screen backdrop-blur-sm z-50"></div>
    <div v-if="open" class="fixed w-full h-full top-0 left-0 flex items-start justify-center z-50 overflow-auto">

    <div class="bg-white dark:bg-black w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 px-5 mt-10">
      <div>
        <h2 v-if="showAddForm">
          <span v-if="isExpenses">{{$t('new-expenses')}}</span>
          <span v-else>{{$t('new-worktime')}}</span>
        </h2>
        <h2 v-else-if="isAbsence">{{$t('edit-absence')}}</h2>
        <h2 v-else-if="isExpenses">{{$t('edit-expenses')}}</h2>
        <h2 v-else>{{$t('edit-worktime')}}</h2>
      </div>
    <div class="flex flex-col">
      <template v-if="isExpenses">
        <label class="text-sm">{{$t('amount')}}</label>
        <input v-model="editItem.expenses" type="number" step="0.01" class="mb-3">
      </template>
        <label class="text-sm">{{$t('project')}}</label>
          <v-select :options="projects" v-model="editItem.projectId" :disabled="editItem.state > 0">
            <template v-slot:default="prop">
              {{prop.item ? prop.item.name : $t('choose-project')}}
            </template>
          </v-select>

        <template v-if="!isAbsence">
          <template v-if="!isExpenses">
            <label class="text-sm mt-3">{{$t('task')}}</label>
            <v-select :options="tasks" v-model="editItem.taskId" :disabled="editItem.state > 0">
              <template v-slot:default="prop">
                {{prop.item ? prop.item.name : $t('choose-task')}}
              </template>
            </v-select>
          </template>
          <label class="text-sm mt-3">{{$t('note')}}</label>
          <textarea v-model="editItem.note" :disabled="editItem.state > 0" class="p-2 text-sm bg-gray-200 dark:bg-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent">
          </textarea>
      </template>
      <template v-if="!isExpenses">
        <label class="text-sm mt-3">{{$t('time')}}</label>
        <div class="relative">
        <flat-pickr
            v-model="currentTime"
            :config="config"
            name="date"
            :disabled="editItem.state > 0">
          </flat-pickr>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
          </div>
      </template>
      <template v-if="!isAbsence && !isExpenses">
        <template v-if="(user._id === item.createdBy) || ((showAddForm) && (editItem.projectId)) || (editItem.state === 1)">
          <label class="text-sm mt-3">{{$t('timer')}}</label>
          <WorktimeTimer
            :createdBy="editItem.createdBy"
            :state="editItem.state"
            :seconds="editItem.seconds"
            :startedAt="editItem.startedAt"
            @on-started="onStarted"
            @on-stopped="onStopped"
            editMode>
          </WorktimeTimer>
        </template>
      </template>

    </div>
       <div class="flex justify-end pt-6 pb-4">
            <button @click="cancel" type="button">{{$t('cancel')}}</button>
            <button @click="done()" class="primary ml-2" :class="{'cursor-not-allowed opacity-25' : (editItem.state === 1) || (editItem.projectId === undefined)}" :disabled="(editItem.state === 1) || (editItem.projectId === undefined)">
              {{$t('save')}}
            </button>
      </div>
    </div>
</div>
</div>
</template>

<script>
import { BSON } from 'realm-web'
import WorktimeTimer from './WorktimeTimer'
import { mapGetters } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import VSelect from '@/components/v-select'

import dayjs from 'dayjs'
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

function pad (value, size) {
  let s = String(value)
  while (s.length < (size || 2)) {
    s = '0' + s
  }
  return s
}

export default {
  components: {
    flatPickr,
    WorktimeTimer,
    'v-select': VSelect
  },
  props: {
    item: Object,
    showAddForm: Boolean,
    open: Boolean,
    isExpenses: Boolean
  },
  data () {
    return {
      name: '',
      tmpItem: Object,
      config: {
        minuteIncrement: 15,
        time_24hr: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        minTime: '00:00'
      },
      time: 0
    }
  },
  computed: {
    ...mapGetters({
      projects: 'projects/active',
      absProjects: 'projects/absences',
      tasks: 'tasks/active',
      user: 'user'
    }),
    editItem: {
      get () {
        return this.tmpItem
      },
      set (item) {
        this.tmpItem = JSON.parse(JSON.stringify(item))
        this.tmpItem.date = new Date(this.tmpItem.date)
        this.tmpItem.createdAt = new Date(this.tmpItem.createdAt)
        this.tmpItem.startedAt = this.tmpItem.startedAt ? new Date(this.tmpItem.startedAt) : null
        if (this.isExpenses) {
          this.tmpItem.type = 3
        }
      }
    },
    isAbsence () {
      const ids = this.absProjects.map(p => p._id)
      return ids.indexOf(this.item.projectId) > -1
    },
    currentTime: {
      get () {
        const h = pad(Math.floor(this.time / 60 / 60), 2)
        const m = pad(this.time / 60 % 60, 2)
        return `${h}:${m}`
      },
      set (value) {
        const tmp = value.split(':')
        const h = parseInt(tmp[0])
        this.time = parseInt(tmp[1] * 60) + (h * 60 * 60)
        if (this.editItem.state === 0) {
          this.editItem.seconds = this.time
        }
      }
    }
  },
  methods: {
    done () {
      this.editItem.seconds = this.time
      if (this.showAddForm) {
        localStorage.setItem('SAMAY_PROJECT_ID', this.editItem.projectId)
        this.$emit('on-item-added', this.editItem)
        this.editItem = this.item
        this.currentTime = '00:00'
      } else {
        this.$emit('on-item-changed', this.editItem)
      }
    },
    cancel () {
      this.$emit('on-cancel')
    },
    onStarted (seconds) {
      if (this.showAddForm) {
        this.showEditForm = false
        this.editItem.seconds = this.time
        this.editItem.state = 1
        this.editItem.startedAt = new Date()
        this.$emit('on-item-added', this.editItem)
        this.editItem = this.item
        this.currentTime = '00:00'
      } else {
        this.time = seconds
        this.editItem.seconds = seconds
        this.editItem.state = 1
        this.editItem.startedAt = new Date()
        this.$emit('on-item-changed', this.editItem)
      }
    },
    onStopped (seconds, state) {
      this.time = seconds
      this.editItem.state = state
      this.editItem.startedAt = new Date(null)
      this.editItem.seconds = seconds
    }
  },
  mounted () {
    if (this.showAddForm) {
      const defaultProjectId = localStorage.getItem('SAMAY_PROJECT_ID')
      if (defaultProjectId !== null) {
        this.editItem.projectId = new BSON.ObjectID(defaultProjectId)
      }
    }

    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.cancel()
      }
    })
  },
  created () {
    this.editItem = this.item
    this.time = this.editItem.seconds
  }
}
</script>
