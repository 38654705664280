import { BSON } from 'realm-web'
import Vue from 'vue'
import dateMixin from '../../mixin/dateMixin.js'
import dayjs from 'dayjs'
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)

// initial state
const state = {
  all: [],
  abcenses: [],
  stream: null,
  hasOpenConfirmation: false
}
// getters
const getters = {
  all: state => state.all,
  allSorted: state => state.abcenses.sort((a, b) => a.date - b.date),
  hasOpenConfirmation: state => state.hasOpenConfirmation,
  byUserId: (state, getters, rootState) => (userId) => {
    return state.all.filter(w => w.createdBy === userId)
  },
  byUserIdRange: (state, getters, rootState) => (userId, from, to) => {
    return state.all.filter(w => w.createdBy === userId && dayjs(w.date).isSameOrAfter(from, 'day') && dayjs(w.date).isSameOrBefore(to, 'day'))
  },
  byMonthUser: (state, getters, rootState) => {
    return state.all.reduce((acc, obj) => {
      const month = dayjs(obj.date).get('month') + 1
      const key = `${month}`
      if (acc[key] === undefined) {
        acc[key] = {}
      }
      if (acc[key][obj.createdBy] === undefined) {
        acc[key][obj.createdBy] = []
      }
      acc[key][obj.createdBy].push(obj)
      return acc
    }, {})
  },
  mineByDate: (state, getters, rootState, rootGetters) => {
    return date => state.all.filter((worktime) => { return (dateMixin.methods.formatDateToISO(new Date(worktime.date)) === date) && (worktime.createdBy === rootGetters.user._id) })
  },
  getAbsenceByDate: (state, getters, rootState, rootGetters) => (date, user) => {
    return state.abcenses.find(abs => { return abs.type === 2 && date.isSame(abs.date, 'day') && abs.createdBy === user._id })
  }
}

// actions
const actions = {
  async checkOpenConfirmation ({ rootGetters, commit }) {
    if (rootGetters.team === null) return
    const query = {
      _partition: `worktime=${rootGetters.team._id}`,
      isApproved: false,
      date: {
        $lte: dayjs().subtract(1, 'month').endOf('month').toDate()
      }
    }
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    const items = await worktimes.find(query)

    commit('setOpenConfirmation', items.length > 0)
  },
  async totalVacationForRange ({ rootGetters }, { userId, startDate, endDate }) {
    const projectsIds = rootGetters['projects/absences'].filter(p => p.isVacation).map(p => p._id)

    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    const partition = rootGetters.isAdmin ? `worktime=${rootGetters.team._id}` : `user_worktime=${userId}`

    const value = await worktimes.aggregate(
      [
        {
          $match: {
            createdBy: userId,
            _partition: partition,
            projectId: { $in: projectsIds },
            date: {
              $gte: startDate.toDate(),
              $lte: endDate.toDate()
            }
          }
        }, {
          $group: {
            _id: null,
            total: { $sum: '$seconds' }
          }
        }
      ]
    )
    return value.length === 0 ? 0 : value[0].total
  },
  async totalForRange ({ rootGetters }, { userId, startDate, endDate }) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    const partition = rootGetters.isAdmin ? `worktime=${rootGetters.team._id}` : `user_worktime=${userId}`
    const value = await worktimes.aggregate(
      [
        {
          $match: {
            createdBy: userId,
            _partition: partition,
            date: {
              $gte: startDate.toDate(),
              $lte: endDate.toDate()
            }
          }
        }, {
          $group: {
            _id: null,
            total: { $sum: '$seconds' }
          }
        }
      ]
    )
    return value.length === 0 ? 0 : value[0].total
  },
  async loadMineByDate ({ commit, dispatch, rootGetters }, nowDate) {
    dispatch('loadingBegin', null, { root: true })
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    const items = await worktimes.find({
      _partition: `user_worktime=${rootGetters.user._id}`,
      date: {
        $gte: nowDate.startOf('day').toDate(),
        $lte: nowDate.endOf('day').toDate()
      }
    })
    commit('setWorktimes', items)
    dispatch('loadingEnd', null, { root: true })
  },
  async loadMineAbsencesByYear ({ commit, dispatch, rootGetters }, year) {
    dispatch('loadingBegin', null, { root: true })
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    const items = await worktimes.find({
      _partition: `user_worktime=${rootGetters.user._id}`,
      type: 2,
      date: {
        $gte: dayjs().year(year).startOf('year').startOf('day').toDate(),
        $lte: dayjs().year(year).endOf('year').endOf('day').toDate()
      }
    })
    commit('setAbsences', items)
    dispatch('loadingEnd', null, { root: true })
  },
  async loadAbsences ({ state, commit, rootGetters, dispatch }, { month, year }) {
    dispatch('loadingBegin', null, { root: true })
    const query = {}
    query._partition = `absence=${rootGetters.team._id}`
    query.date = {
      $gte: dayjs().year(year).month(month - 1).startOf('month').startOf('day').toDate(),
      $lte: dayjs().year(year).month(month - 1).endOf('month').endOf('day').toDate()
    }
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    worktimes.find(query).then(worktimes => {
      commit('setAbsences', worktimes)
      dispatch('loadingEnd', null, { root: true })
    })
  },
  async load ({ state, commit, rootGetters, dispatch }, queryPayload) {
    dispatch('loadingBegin', null, { root: true })
    const query = queryPayload || {}
    if (rootGetters.isAdmin) {
      query._partition = `worktime=${rootGetters.team._id}`
    } else {
      query._partition = `user_worktime=${rootGetters.user._id}`
    }
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    worktimes.find(query).then(worktimes => {
      worktimes.sort((a, b) => b.date - a.date)
      commit('setWorktimes', worktimes)
      dispatch('loadingEnd', null, { root: true })
    })

    // const stream = worktimes.watch()
    // commit('setStream', stream)
    // for await (const change of state.stream) {
    //   const { operationType } = change
    //   switch (operationType) {
    //     case 'insert': {
    //       if (change.fullDocument._partition === partition || change.fullDocument._partition === `absence=${rootGetters.team._id}`) {
    //         commit('add', change.fullDocument)
    //       }
    //       break
    //     }
    //     case 'update': {
    //       commit('update', change.fullDocument)
    //       break
    //     }
    //     case 'replace': {
    //       commit('update', change.fullDocument)
    //       break
    //     }
    //     case 'delete': {
    //       commit('remove', change.documentKey._id)
    //       break
    //     }
    //   }
    // }
  },
  async add ({ dispatch, commit, rootGetters }, worktime) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')

    const newWorktime = {
      _id: new BSON.ObjectID(),
      _partition: `user_worktime=${rootGetters.user._id}`,
      createdBy: rootGetters.user._id,
      created: new Date(),
      date: dayjs(worktime.date).utc().startOf('day').toDate(),
      seconds: worktime.seconds,
      isApproved: false,
      state: worktime.state || 0,
      projectId: worktime.projectId || rootGetters.nullId,
      type: worktime.type || 1,
      triggered: false,
      teamId: new BSON.ObjectID(rootGetters.team._id),
      taskId: worktime.taskId || rootGetters.nullId,
      startedAt: worktime.startedAt || new Date(null),
      note: worktime.note || '',
      expenses: { $numberDouble: worktime.expenses || '0.0' }
    }
    await worktimes.insertOne(newWorktime)
    newWorktime.expenses = worktime.expenses || 0.0
    commit('add', newWorktime)
    return newWorktime
  },
  add_absences ({ dispatch, commit, rootGetters }, payload) {
    const worktimesInput = payload.map(worktime => {
      return {
        _id: new BSON.ObjectID(),
        _partition: `user_worktime=${rootGetters.user._id}`,
        createdBy: rootGetters.user._id,
        created: new Date(),
        date: dayjs(worktime.date).utc().startOf('day').toDate(),
        seconds: worktime.seconds,
        isApproved: false,
        state: 0,
        projectId: new BSON.ObjectID(worktime.projectId),
        type: 2,
        triggered: false,
        teamId: new BSON.ObjectID(rootGetters.team._id),
        note: '',
        startedAt: new Date(null),
        taskId: rootGetters.nullId,
        expenses: { $numberDouble: '0.0' }
      }
    })
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    worktimes.insertMany(worktimesInput)
    worktimesInput.forEach(w => commit('add', w))
  },
  lock_multiple ({ dispatch, commit, rootGetters }, payload) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    const ids = payload.map(_ => _._id)
    worktimes.updateMany({ _id: { $in: ids } }, { $set: { isApproved: true, triggered: false } })
    payload.forEach(w => commit('update', w))
    dispatch('checkOpenConfirmation')
  },
  unlock_multiple ({ dispatch, commit, rootGetters }, payload) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    const ids = payload.map(_ => _._id)
    worktimes.updateMany({ _id: { $in: ids } }, { $set: { isApproved: false, triggered: false } })
    payload.forEach(w => commit('update', w))
    dispatch('checkOpenConfirmation')
  },
  lock ({ dispatch, commit, rootGetters }, worktime) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    worktimes.updateOne({ _id: worktime._id }, { $set: { isApproved: true, triggered: false } })
    worktime.isApproved = true
    commit('update', worktime)
    dispatch('checkOpenConfirmation')
  },
  unlock ({ dispatch, commit, rootGetters }, worktime) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    worktimes.updateOne({ _id: worktime._id }, { $set: { isApproved: false, triggered: false } })
    commit('update', worktime)
    worktime.isApproved = false
    dispatch('checkOpenConfirmation')
  },
  async update ({ rootGetters, commit }, worktime) {
    const editWorktime = {
      seconds: worktime.seconds,
      state: worktime.state,
      projectId: new BSON.ObjectId(worktime.projectId),
      triggered: false,
      note: worktime.note || ''
    }
    if (worktime.taskId) {
      editWorktime.taskId = new BSON.ObjectId(worktime.taskId)
    }
    if (worktime.startedAt) {
      editWorktime.startedAt = dayjs(worktime.startedAt).toDate()
    }
    if (worktime.expenses) {
      editWorktime.expenses = { $numberDouble: worktime.expenses.toString() }
    }

    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    const query = { _id: new BSON.ObjectId(worktime._id) }
    await worktimes.updateOne(query, { $set: editWorktime })
    const up = await worktimes.findOne(query)
    commit('update', up)
  },
  remove ({ commit, rootGetters }, worktime) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    worktimes.deleteOne({ _id: worktime._id })
    commit('remove', worktime._id)
  },
  removeAll ({ commit, rootGetters }, worktimesIds) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    worktimes.deleteMany({ _id: { $in: worktimesIds } })
    worktimesIds.forEach(id => commit('remove', id))
  },
  updateMany ({ rootGetters }, payload) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    worktimes.updateMany(payload.query, payload.update)
  },
  async checkActive ({ dispatch, getters, rootGetters }, current) {
    const query = {
      _partition: `user_worktime=${rootGetters.user._id}`,
      state: 1,
      createdBy: rootGetters.user._id,
      _id: { $ne: current._id }
    }
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const worktimes = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Worktime')
    const active = await worktimes.findOne(query)

    if (active) {
      const activeWkt = JSON.parse(JSON.stringify(active))
      activeWkt.state = 2
      if (activeWkt.startedAt && activeWkt.startedAt !== new Date(null)) {
        const diff = dayjs().utc().diff(dayjs(activeWkt.startedAt), 'seconds')
        activeWkt.seconds = activeWkt.seconds + diff
      }
      activeWkt.startedAt = new Date(null)
      dispatch('update', activeWkt)
    }
  }
}

// mutations
const mutations = {
  setOpenConfirmation (state, value) {
    state.hasOpenConfirmation = value
  },
  setWorktimes (state, worktimes) {
    state.all = worktimes
  },
  setAbsences (state, worktimes) {
    state.abcenses = worktimes
  },
  update (state, worktime) {
    if (worktime.type === 2) {
      const index = state.abcenses.findIndex(p => p._id.toString() === worktime._id.toString())
      Vue.set(state.abcenses, index, worktime)
    }
    const index = state.all.findIndex(p => p._id.toString() === worktime._id.toString())

    Vue.set(state.all, index, worktime)
  },
  add (state, worktime) {
    if (worktime.type === 2) {
      const index = state.abcenses.findIndex(p => p._id.toString() === worktime._id.toString())
      if (index === -1) {
        state.abcenses.push(worktime)
      }
    } else {
      const index = state.all.findIndex(p => p._id.toString() === worktime._id.toString())
      if (index === -1) {
        state.all.push(worktime)
      }
    }
  },
  remove (state, id) {
    const index = state.all.findIndex(p => p._id.toString() === id.toString())
    if (index > -1) {
      state.all.splice(index, 1)
    } else {
      const index = state.abcenses.findIndex(p => p._id.toString() === id.toString())
      if (index > -1) {
        state.abcenses.splice(index, 1)
      }
    }
  },
  setStream (state, stream) {
    state.stream = stream
  },
  resetState (state) {
    if (state.stream) state.stream.return()
    state.all = []
    state.abcenses = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
