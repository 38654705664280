<template>
 <div>
<div class="flex justify-center my-10">
    <router-link to="/">
    <img src="@/assets/logo.svg" width="40"></router-link>
  </div>
 <div class="bg-white dark:bg-black dark:text-white w-11/12 md:max-w-md mx-auto rounded shadow-lg overflow-y-auto px-8 mt-10 pt-6 pb-8">
   <form class="login" @submit.prevent="login">
     <template v-if="!userExist">
       <div class="text-2xl text-center">{{$t('setup')}}</div>
        <p class="text-center text-gray-600 dark:text-gray-300 my-6">{{$t('setup-hint')}}</p>
     </template>
     <template v-else>
        <div class="text-2xl text-center">{{$t('login')}}</div>
        <p class="text-center text-gray-600 dark:text-gray-300 my-6" v-html="$t('login-text', { email: email })"></p>
     </template>

     <template v-if="!userExist" >
     <label class="text-sm">{{$t('email')}}</label>
     <input required v-model="email" type="email" :placeholder="$t('your-email')" :disabled="createNewAccount" :class="{'bg-gray-400 dark:text-gray-200 cursor-not-allowed': createNewAccount}"/>
   </template>
     <template v-if="createNewAccount" >
        <div class="my-2">
          <label class="text-sm">{{$t('password')}}</label>
          <input type="password" v-model="password" ref="password" :placeholder="$t('password')" />
        </div>
        <div class="my-2">
          <label class="text-sm">{{$t('name')}}</label>
          <input required v-model="name" type="text" ref="name" :placeholder="$t('your-name')"/>
        </div>
     </template>
     <template v-if="userExist" >
     <label class="text-sm">{{$t('password')}}</label>
     <input required v-model="password" type="password" ref="password" :placeholder="$t('password')"/>
     </template>
     <div class="flex justify-center mt-6">
      <button type="submit" class="primary px-8">{{$t('continue')}}</button>
     </div>
     <p class="text-center my-6">
     <a class="text-primary hover:underline cursor-pointer font-semibold" v-if="userExist" @click="forgetPassword">
       {{$t('forgot-password')}}
      </a>
   </p>
   <p class="text-center">
     <a class="text-primary hover:underline cursor-pointer font-semibold" v-if="userExist || createNewAccount" @click="back">{{$t('back')}}</a>
     </p>
   </form>
 </div>
</div>
</template>

<script>
import * as Realm from 'realm-web'
import APIClient from '@/assets/js/APIClient.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      response: null,
      email: '',
      password: '',
      name: '',
      userExist: false,
      createNewAccount: false
    }
  },
  computed: {
    ...mapGetters(['app', 'team'])
  },
  methods: {
    ...mapActions(['initialize', 'loadingBegin', 'loadingEnd', 'addNotification', 'logoutUser']),
    back () {
      this.userExist = false
      this.email = ''
      this.createNewAccount = false
      this.name = ''
    },
    forgetPassword () {
      this.loadingBegin()
      APIClient
        .post('auth/forgotPassword', {
          email: this.email
        })
        .then((response) => {
          this.loadingEnd()
          this.addNotification({ message: 'Guck in deine Mailbox!' })
          this.$router.replace('/app/login')
        })
        .catch((err) => {
          this.loadingEnd()
          console.log(err)
          this.addNotification({ message: 'Ups, da gieng was schief...', error: true })
        })
    },
    signUp () {
      this.loadingBegin()
      const payload = {
        email: this.email,
        password: this.password
      }
      const credentials = Realm.Credentials.function(payload)

      this.app.logIn(credentials).then(result => {
        this.app.currentUser.functions.createUser(this.email, this.name, false).then(result => {
          if (result.error) {
            console.log(result.error)
            this.logoutUser()
            this.loadingEnd()
            return
          }
          this.loadingEnd()
          this.$router.replace('/app/signup/team')
        })
      }).catch((err) => {
        this.loadingEnd()
        console.log(err)
        this.addNotification({ message: 'Ups, da gieng was schief...', error: true })
      })
    },
    login () {
      if (this.createNewAccount) {
        const pattern = '(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z]).{8,}'
        if (!this.password.match(pattern)) {
          this.addNotification({ message: this.$t('password-requirements'), error: true })
          return
        }
        this.signUp()
      } else if (this.userExist) {
        this.loadingBegin()
        const payload = {
          email: this.email,
          password: this.password
        }
        const credentials = Realm.Credentials.function(payload)

        this.app.logIn(credentials).then(result => {
          this.loadingEnd()
          this.initialize().then(() => {
            if (!this.team) {
              this.$router.replace('/app/signup/team')
            } else {
              this.$router.replace('/app/')
            }
          })
        }).catch((err) => {
          this.loadingEnd()
          console.log(err)
          this.addNotification({ message: 'Ups, da gieng was schief...', error: true })
        })
      } else {
        APIClient
          .get(`auth/checkEmail?email=${this.email}`)
          .then(r => r.data)
          .then(result => {
            if (!result.emailValid) {
              this.addNotification({ message: 'Ungültige E-Mail 😥', error: true })
            } else if (result.userExist) {
              this.userExist = true
              this.$nextTick(() => this.$refs.password.focus())
            } else {
              this.createNewAccount = true
              this.$nextTick(() => this.$refs.password.focus())
            }
          })
          .catch((err) => {
            alert(err)
            console.log(err)
          })
      }
    }
  }
}
</script>
