import Vue from 'vue'
import { BSON } from 'realm-web'
import Vuex from 'vuex'
import * as Realm from 'realm-web'
import projects from './modules/projects.js'
import tasks from './modules/tasks.js'
import worktimes from './modules/worktimes.js'
import targettimes from './modules/targettimes.js'
import employmentlevel from './modules/employmentlevel.js'
import balances from './modules/balances.js'
import users from './modules/users.js'
import teams from './modules/teams.js'
import router from '../router.js'

import dayjs from 'dayjs'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    projects: projects,
    tasks: tasks,
    worktimes: worktimes,
    users: users,
    teams: teams,
    targettimes: targettimes,
    employmentlevel: employmentlevel,
    balances: balances
  },
  state: {
    app: new Realm.App({ id: process.env.VUE_APP_REALM_ID }),
    months: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
    loading: 0,
    initialized: false,
    notifications: [],
    avatars: [],
    nullId: new BSON.ObjectID('000000000000000000000000')
  },
  getters: {
    app: state => state.app,
    nullId: state => state.nullId,
    initialized: state => state.initialized,
    isLoggedIn: (state, getters) => getters.app.currentUser !== null && getters.user !== null,
    user: (state, getters) => getters['users/me'],
    team: (state, getters) => getters['teams/active'],
    trialExpired: (state, getters) => dayjs(dayjs(getters['teams/active'].trialEnds).format('YYYY-MM-DD 23:59:59')).isBefore(),
    isLoading: state => state.loading > 0,
    isAdmin: (state, getters) => getters.team && getters.team.membersAdmin && getters.team.membersAdmin.indexOf(getters.user._id) > -1,
    notifications: state => state.notifications,
    findAvatar: state => userId => state.avatars.find(a => a.userId === userId)
  },
  mutations: {
    updateAvatar (state, payload) {
      const index = state.avatars.findIndex(a => a.userId === payload.userId)
      if (index > -1) {
        Vue.set(state.avatars, index, payload)
      } else {
        state.avatars.push(payload)
      }
    },
    setInitialized (state, flag) {
      state.initialized = flag
    },
    setLoading (state, value) {
      state.loading = state.loading + value
    },
    addNotification (state, obj) {
      state.notifications.push(obj)
    },
    removeNotification (state, index) {
      if (index !== -1) state.notifications.splice(index, 1)
    }
  },
  actions: {
    addAvatar ({ commit }, payload) {
      commit('addAvatar', payload)
    },
    updateAvatar ({ commit }, payload) {
      commit('updateAvatar', payload)
    },
    addNotification ({ commit }, payload) {
      commit('addNotification', payload)
    },
    loadingBegin ({ commit }) {
      commit('setLoading', 1)
    },
    loadingEnd ({ commit }) {
      commit('setLoading', -1)
    },
    async getSaldo ({ state, dispatch, getters }, { userId, fromMonth, toMonth, year }) {
      const round = (number, decimalPlaces) => Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces)
      let diff = 0
      for (let index = fromMonth - 1; index < toMonth; index++) {
        const date = dayjs(`01.${index + 1}.${year}`, 'DD.M.YYYY')

        const tt = getters['targettimes/getByYear'](year)
        const monthHours = (tt === undefined) ? 0 : tt[state.months[index]] * (getters['employmentlevel/activePercentForUser'](userId, date) / 100)

        const totalSeconds = await dispatch('worktimes/totalForRange', { userId: userId, startDate: date.startOf('month'), endDate: date.endOf('month') })
        diff = diff + (round(totalSeconds / 3600, 2) - monthHours)
      }
      const balance = getters['balances/getWorktimeByUserAndYear'](userId, parseInt(year))
      return diff + balance
    },
    async initialize ({ commit, dispatch, state, getters }) {
      dispatch('loadingBegin')
      await dispatch('users/loadMe')
      await dispatch('teams/load')
      if (getters.team) {
        await dispatch('users/load')
        dispatch('targettimes/load')
        dispatch('employmentlevel/load')
        dispatch('balances/load')
        dispatch('projects/load')
        dispatch('tasks/load')
        // dispatch('teams/watch')
        // dispatch('users/watch')
        if (getters.isAdmin) {
          await dispatch('teams/loadAccount')
          // dispatch('teams/watchAccount')
        }
      }

      const prom = new Promise((resolve) => {
        commit('setInitialized', true)
        dispatch('loadingEnd')
        resolve()
      })
      return prom
    },
    logoutUser ({ commit, dispatch, state }) {
      commit('setInitialized', false)
      commit('worktimes/resetState')
      commit('users/resetState')
      commit('tasks/resetState')
      commit('projects/resetState')
      commit('teams/resetState')
      commit('targettimes/resetState')
      commit('employmentlevel/resetState')
      commit('balances/resetState')
      if (state.app.currentUser) {
        state.app.removeUser(state.app.currentUser)
      }
      router.replace('/app/login', () => {})
    }
  }
})
