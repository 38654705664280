<template>
<div>
  <div class="flex items-center mt-10">
    <span class="text-lg block mr-2">{{$t('employment')}}</span>
    <a @click="addLevel(user._id)" class="cursor-pointer">
      <svg class="h-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"/></svg>
    </a>
  </div>
  <p>
    {{$t('employment-level-description')}}
  </p>
  <div v-if="levelByUser(user._id).length === 0" class="w-full py-2 px-4 bg-gray-200 rounded border border-gray-400 text-gray-600 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 mt-2">
    <span class="mt-1 block">{{$t('no-employment')}}</span>
    <p class="text-xs">{{$t('no-employment-hint')}}</p>
  </div>
  <table v-else class="table-auto w-full">
    <thead>
      <tr>
        <th class="p-1 text-left uppercase text-gray-600 dark:text-gray-400 text-sm font-normal py-3">{{$t('from')}}</th>
        <th class="p-1 text-left uppercase text-gray-600 dark:text-gray-400 text-sm font-normal py-3">{{$t('to')}}</th>
        <th class="p-1 text-left uppercase text-gray-600 dark:text-gray-400 text-sm font-normal py-3">{{$t('employment')}}</th>
        <th class="p-1 text-left uppercase text-gray-600 dark:text-gray-400 text-sm font-normal py-3"></th>
      </tr>
    </thead>
    <tbody>
      <tr class="border-t border-gray-400 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-800 even:bg-gray-100 dark:even:bg-gray-900" v-for="(level, index) in levelByUser(user._id)" :key="index">
        <td class="p-2 text-xs w-24">
          <flat-pickr  v-if="editRow===index" v-model="level.from" :config="config" class="p-1 text-xs"></flat-pickr>
          <span v-else>{{formatDateCustom(level.from, 'MMM YYYY')}}</span>
        </td>
        <td class="p-2 text-xs w-24">
          <div class="relative" v-if="editRow===index">
            <flat-pickr v-model="level.to" :config="config" :placeholder="$t('today')" class="p-1 text-xs"></flat-pickr>
            <div v-if="level.to" @click="level.to = null" class="absolute z-10 top-0 right-0 w-6 h-6 flex items-center justify-center text-gray-600 cursor-pointer shrink-0">
              <svg class="w-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
            </div>
          </div>
          <span v-else>{{new Date(level.to).getTime() != new Date(null).getTime() ? formatDateCustom(level.to, 'MMM YYYY') : $t('today')}}</span>
        </td>
        <td class="p-2 text-xs w-24">
          <input v-if="editRow===index" v-model="levelByUser(user._id)[index].percent" class="text-center text-xs p-1 w-12" max="100" min="0" type="number">
          <span v-else>{{level.percent}} %</span>
        </td>
        <td class="">
          <div class="flex">
          <div v-if="editRow===-1" @click="editLevel(level, index)" class="bg-primary hover:bg-opacity-75 rounded-full w-6 h-6 flex items-center justify-center text-white cursor-pointer">
            <svg class="h-3 w-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"/></svg>
          </div>
          <div v-if="editRow===-1" @click="deleteLevel(level)" class="ml-1 bg-red-600 hover:bg-opacity-75 rounded-full w-6 h-6 flex items-center justify-center text-white cursor-pointer">
            <svg class="h-3 w-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9 19c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5-17v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712zm-3 4v16h-14v-16h-2v18h18v-18h-2z"/></svg>
          </div>

          <div v-if="editRow===index" @click="cancelLevel(true)" class="bg-red-500 hover:bg-opacity-75 rounded-full w-6 h-6 flex items-center justify-center text-white cursor-pointer">
            <svg class="w-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
          </div>
          <div v-if="editRow===index" @click="updateLevel(level);editRow = -1" class="ml-1 bg-primary hover:bg-opacity-75 rounded-full w-6 h-6 flex items-center justify-center text-white cursor-pointer">
            <svg class="w-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z"/></svg>
          </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dateMixin from '@/mixin/dateMixin.js'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import 'flatpickr/dist/flatpickr.css'
import { German } from 'flatpickr/dist/l10n/de.js'
export default {
  mixins: [dateMixin],
  components: {
    flatPickr
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      config: {
        locale: German,
        altInput: true,
        plugins: [
          // eslint-disable-next-line new-cap
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: 'Y-m-d',
            altFormat: 'M Y'
          })
        ]
      },
      editRow: -1,
      editLevelItem: {}
    }
  },
  computed: {
    ...mapGetters({
      levelByUser: 'employmentlevel/getByUser'
    })
  },
  methods: {
    ...mapActions({
      addLevel: 'employmentlevel/add',
      deleteLevel: 'employmentlevel/remove',
      updateLevel: 'employmentlevel/update'
    }),
    editLevel (item, index) {
      this.editLevelItem = JSON.parse(JSON.stringify(item))
      const toDate = this.levelByUser(this.user._id)[index].to
      if (new Date(toDate).getTime() === new Date(null).getTime()) {
        this.levelByUser(this.user._id)[index].to = new Date()
      }
      this.editRow = index
    },
    cancelLevel (reset) {
      if (reset) {
        this.$store.commit('employmentlevel/resetLevelForUser', this.editLevelItem)
      }
      this.editLevelItem = {}
      this.editRow = -1
    }
  }
}
</script>
