<template>
  <div>
    <a v-if="showAddForm" @click="open=true" class="cursor-pointer">
      <svg :class="[small ? 'h-6 w-6' : 'w-8 h-8']" class="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"/></svg>
    </a>
    <div v-else @click="open=true" :class="[small ? 'h-6 w-6' : 'w-8 h-8']" class="bg-primary hover:bg-opacity-75 rounded-full  flex items-center justify-center text-white cursor-pointer">
      <svg :class="[small ? 'h-3 w-3' : 'h-4 w-4']" class="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"/></svg>
    </div>

  <div v-if="open" class="fixed w-full h-full top-0 left-0 flex items-center justify-center">
    <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-75"></div>
    <div class="modal-container bg-white dark:bg-black w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 px-5 -mt-64">
      <form @submit.prevent="done">
        <div class="">
          <h2 v-if="showAddForm" class="">{{$t('new')}}</h2>
          <h2 v-else class="">{{$t('edit')}}</h2>
        </div>
        <div class="">
        <div class="flex flex-col my-2" v-for="(value) in columns" :key="value.key">
          <label  v-if="value.type === 'checkbox'"  class="inline-flex justify-start items-center">
            <span class="text-base">{{value.value}}</span>
            <input class="cursor-pointer w-10" type="checkbox" v-model="editItem[value.key]">
          </label>
          <template v-else-if="value.type === 'select'">
          <label class="text-sm">{{value.value}}</label>
          <v-select :options="value.options" v-model="editItem[value.key]">
            <template v-slot:default="prop">
              {{prop.item ? prop.item.name : $t('please_chooose')}}
            </template>
          </v-select>
          </template>
          <template v-else>
            <label class="text-sm">{{value.value}}</label>
            <input type="text" class="" :placeholder="value.value" :name="value.key" v-model="editItem[value.key]"/>
          </template>
        </div>
        <slot name="content" :editItem="editItem"></slot>
        </div>
        <div v-if="!simple" class="flex justify-end pt-6 pb-4">
            <button class="" @click="open=false" type="button">{{$t('cancel')}}</button>
            <button class="primary ml-2" type="submit">{{$t('save')}}</button>
        </div>
        <div v-else class="flex justify-end pt-6 pb-4">
            <button class="primary ml-2" :class="{'cursor-not-allowed bg-opacity-50' : cancelDisabled}" type="submit" :disabled="cancelDisabled">{{$t('close')}}</button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import VSelect from '@/components/v-select'

export default {
  components: {
    'v-select': VSelect
  },
  props: {
    columns: Array,
    item: Object,
    showAddForm: Boolean,
    simple: Boolean,
    small: Boolean,
    cancelDisabled: Boolean
  },
  data () {
    return {
      name: '',
      open: false,
      editItem: JSON.parse(JSON.stringify(this.item))
    }
  },
  methods: {
    done () {
      this.open = false
      if (this.simple) {
        this.$emit('on-cancel')
      } else {
        if (this.showAddForm) {
          this.$emit('on-item-added', this.editItem)
          this.editItem = JSON.parse(JSON.stringify(this.item))
        } else {
          this.$emit('on-item-changed', this.editItem)
        }
      }
    }
  }
}
</script>
