import { BSON } from 'realm-web'
import dayjs from 'dayjs'
import Vue from 'vue'

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)
// initial state
const state = {
  all: [],
  state: null
}

// getters
const getters = {
  all: (state) => state.all,
  getByUser: (state) => (userId) => {
    return state.all.filter(level => level.userId === userId)
  },
  activePercentForUser: (state) => (userId, date) => {
    if (!date) {
      date = dayjs()
    }
    const level = state.all.find(level => {
      const isSameUser = level.userId === userId
      const isDateAfterFrom = date.isSameOrAfter(dayjs(level.from), 'day')
      const isDateBeforeTo = new Date(level.to).getTime() === new Date(null).getTime() || date.isSameOrBefore(dayjs(level.to), 'day')
      return isSameUser && isDateAfterFrom && isDateBeforeTo
    })

    return (level !== undefined) ? level.percent : 100
  }
}

// actions
const actions = {
  async load ({ commit, rootGetters }) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const employmentLevels = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('EmploymentLevel')
    employmentLevels.find({ _partition: `team=${rootGetters.team._id}` }).then(employmentLevels => {
      commit('setEmploymentLevel', employmentLevels)
    })
  },
  async add ({ commit, dispatch, rootGetters }, userId) {
    const item = {
      _id: new BSON.ObjectID(),
      _partition: `team=${rootGetters.team._id}`,
      userId: userId,
      from: dayjs().startOf('month').toDate(),
      to: new Date(null),
      percent: 100
    }
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const employmentLevels = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('EmploymentLevel')
    await employmentLevels.insertOne(item)
    dispatch('load')
  },
  async remove ({ dispatch, rootGetters }, item) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const employmentLevels = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('EmploymentLevel')
    await employmentLevels.deleteOne({ _id: item._id })
    dispatch('load')
  },
  async update ({ dispatch, rootGetters }, item) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const employmentLevels = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('EmploymentLevel')
    const updateItem = {
      userId: item.userId,
      from: dayjs(item.from).startOf('month').toDate(),
      to: item.to ? dayjs(item.to).endOf('month').toDate() : new Date(null),
      percent: { $numberLong: item.percent.toString() }
    }
    await employmentLevels.updateOne({ _id: new BSON.ObjectId(item._id) }, { $set: updateItem })
    dispatch('load')
  }
}

// mutations
const mutations = {
  setEmploymentLevel (state, tt) {
    state.all = tt
  },
  update (state, item) {
    const index = state.all.findIndex(p => p._id.toString() === item._id.toString())
    Vue.set(state.all, index, item)
  },
  add (state, item) {
    state.all.push(item)
  },
  remove (state, id) {
    const index = state.all.findIndex(p => p._id.toString() === id.toString())
    state.all.splice(index, 1)
  },
  setStream (state, stream) {
    state.stream = stream
  },
  resetState (state) {
    if (state.stream) state.stream.return()
    state.all = []
  },
  resetLevelForUser (state, item) {
    const index = state.all.findIndex(o => o._id.toString() === item._id.toString())
    Vue.set(state.all, index, item)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
