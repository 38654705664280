<template>
<div>
  <div v-if="selectedMonth !== undefined && selectedYear !== undefined"
    @click="runExport(selectedMonth, selectedYear)"
    v-tooltip="$t('monthly-work-report')"
    class="ml-2 cursor-pointer flex items-center justify-center hover:text-secondary"
  >
    <svg class="fill-current h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 22v-20h16v11.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362zm18-7.614v-14.386h-20v24h10.189c3.163 0 9.811-7.223 9.811-9.614zm-5-1.386h-10v-1h10v1zm0-4h-10v1h10v-1zm0-3h-10v1h10v-1z"/></svg>
  </div>
  <div v-else>
    <v-select-month v-tooltip="$t('monthly-work-report')" @input="runExportWith"></v-select-month>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import vSelectMonth from '@/components/v-select-month.vue'

export default {
  components: { vSelectMonth },
  props: {
    selectedMonth: Number,
    selectedYear: Number,
    userId: String
  },
  data () {
    return {
      month: 0,
      year: 0,
      months: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
    }
  },
  computed: {
    ...mapGetters({
      worktimes: 'worktimes/all',
      userById: 'users/byId',
      getProjectById: 'projects/getById',
      activePercentForUser: 'employmentlevel/activePercentForUser',
      targetTimesByYear: 'targettimes/getByYear',
      getVacationByUserAndYear: 'balances/getVacationByUserAndYear',
      getWorktimeByUserAndYear: 'balances/getWorktimeByUserAndYear'
    }),
    currentEmploymentLevel () {
      return this.activePercentForUser(this.userId, this.dateDayJs)
    },
    user () {
      return this.userById(this.userId)
    },
    maxDays () {
      return this.dateDayJs.daysInMonth()
    },
    dateDayJs () {
      return dayjs(`${this.month}-${this.year}`, 'M-YYYY')
    },
    endDate () {
      return this.dateDayJs.endOf('month').format('DD.MM.YYYY')
    },
    startDate () {
      return this.dateDayJs.startOf('month').format('DD.MM.YYYY')
    },
    targetHour () {
      const tt = this.targetTimesByYear(this.year)
      if (tt) {
        return this.round(tt[this.months[this.month - 1]] * (this.currentEmploymentLevel / 100))
      }
      return 0
    },
    worktimesGrouped () {
      const wkt = this.worktimes.filter(wt => wt.type !== 3)
      const items = wkt.reduce((acc, obj) => {
        const id = (obj.projectId ? obj.projectId : -1)
        const name = (this.getProjectById(obj.projectId) ? this.getProjectById(obj.projectId).name : this.$t('none'))
        let ix = acc.findIndex(elem => elem.project_id.toString() === id.toString())
        if (!~ix) {
          acc.push({ project_id: id, project: name, total: 0, data: {} })
          ix = acc.length - 1
        }
        acc[ix].total += this.roundSecondsToH(obj.seconds)
        const day = dayjs(obj.date).get('D')
        if (acc[ix].data[day] === undefined) {
          acc[ix].data[day] = this.roundSecondsToH(obj.seconds)
        } else {
          acc[ix].data[day] += this.roundSecondsToH(obj.seconds)
        }
        return acc
      }, [])
      items.sort((a, b) => a.project.localeCompare(b.project, undefined, { numeric: true, sensitivity: 'base' }))
      return items
    },
    expensesGrouped () {
      const wkt = this.worktimes.filter(wt => wt.type === 3)
      const items = wkt.map((obj) => {
        const name = (this.getProjectById(obj.projectId) ? this.getProjectById(obj.projectId).name : this.$t('none'))
        return { date: obj.date, project: name, amount: obj.expenses, note: obj.note }
      })
      items.sort((a, b) => b.date - a.date)
      return items
    }
  },
  methods: {
    ...mapActions({
      getSaldo: 'getSaldo',
      loadWorktimes: 'worktimes/load',
      totalVacationForRange: 'worktimes/totalVacationForRange'
    }),
    runExportWith (item) {
      this.runExport(item.month, item.year)
    },
    async saldoOld () {
      if (this.month === 1) {
        const balance = this.getWorktimeByUserAndYear(this.userId, this.year)
        return this.round(balance)
      }
      return await this.getSaldo({ userId: this.userId, fromMonth: 1, toMonth: this.month - 1, year: this.year })
    },
    async saldoNew () {
      return await this.getSaldo({ userId: this.userId, fromMonth: 1, toMonth: this.month, year: this.year })
    },
    async vacationSaldo () {
      const balance = this.getVacationByUserAndYear(this.userId, this.year) * 3600
      let total = 0
      if (this.month > 1) {
        const date = dayjs(`${this.month - 1}-${this.year}`, 'M-YYYY')
        total = await this.totalVacationForRange({ userId: this.userId, startDate: date.startOf('year'), endDate: date.endOf('month') })
      }
      return this.roundSecondsToH(balance - total)
    },
    async vacationCurrentMonth () {
      const date = this.dateDayJs
      const total = await this.totalVacationForRange({ userId: this.userId, startDate: date.startOf('month'), endDate: date.endOf('month') })
      return this.roundSecondsToH(total)
    },
    roundSecondsToH (val) {
      return this.round(val / 3600)
    },
    round (n) {
      const d = 2
      return Number(Math.round(n + 'e' + d) + 'e-' + d)
    },
    async runExport (month, year) {
      this.month = month
      this.year = year
      const date = this.dateDayJs
      const query = {
        createdBy: this.userId,
        date: {
          $gte: date.startOf('month').toDate(),
          $lte: date.endOf('month').toDate()
        }
      }
      await this.loadWorktimes(query)
      const saldoOld = await this.saldoOld()
      const saldoNew = await this.saldoNew()
      const vacationSaldo = await this.vacationSaldo()
      const vacationCurrentMonth = await this.vacationCurrentMonth()

      const doc = jsPDF({
        orientation: 'l',
        unit: 'mm',
        format: 'a4'
      })
      // Header
      const headData = []
      headData.push({ content: this.$t('project'), styles: { halign: 'left' } })
      headData.push('Ist')

      // Body
      let row = []
      const data = []
      const bodyStyles = {}
      this.worktimesGrouped.forEach(wt => {
        row.push(wt.project)
        bodyStyles[0] = { cellWidth: 45, fontSize: 7, cellPadding: 2 }
        row.push(wt.total.toFixed(2))
        bodyStyles[1] = { cellWidth: 15, fontSize: 7, halign: 'center', valign: 'middle', cellPadding: { top: 2, bottom: 2 } }
        for (let d = 0; d < this.maxDays; d++) {
          if (headData.length < this.maxDays + 2) {
            headData.push(d + 1)
          }
          if (wt.data[d + 1] !== undefined) {
            const h = wt.data[d + 1]
            row.push(h.toFixed(2))
          } else {
            row.push('')
          }
          bodyStyles[d + 2] = { cellWidth: 7, fontSize: 6, halign: 'center', valign: 'middle', cellPadding: { top: 2, bottom: 2 } }
        }
        data.push(row)
        row = []
      })

      // Totals
      let colSum = []
      if (data.length > 1) {
        colSum = data.reduce((a, b) => a.map((x, i) => (parseFloat(x) || 0) + (parseFloat(b[i]) || 0)))
      } else if (data.length) {
        colSum = data[0].map(v => parseFloat(v) || 0)
      } else {
        colSum = [0, 0]
      }

      colSum = colSum.map(col => {
        const obj = { content: col > 0 ? col.toFixed(2) : '', styles: { fontStyle: 'bold', cellPadding: { top: 6, bottom: 2 } } }
        return obj
      })
      colSum[0] = { content: this.$t('total'), styles: { halign: 'left', fontStyle: 'bold', cellPadding: { top: 6, bottom: 2, left: 2 } } }
      data.push(colSum)

      // Summary
      doc.setFontSize(11)
      doc.text(`${this.$t('monthly-work-report-with-control')} ${this.startDate} - ${this.endDate}`, 10, 10)
      doc.line(10, 12, 287, 12)
      doc.setFontSize(11)
      doc.text(this.user.name, 10, 20)
      if (data.length > 1) {
        doc.autoTable({
          theme: 'grid',
          head: [headData],
          body: data,
          headStyles: { fillColor: null, textColor: 0, lineWidth: 0.1, fontSize: 8, fontStyle: 'normal', halign: 'center', valign: 'middle', cellPadding: 2 },
          columnStyles: bodyStyles,
          startY: 25,
          margin: { left: 10 }
        })
      } else {
        doc.text(this.$t('no-worktimes-entered'), 10, 30, { fontStyle: 'bold' })
      }

      let baseY = doc.lastAutoTable.finalY || 30
      if (baseY + 32 > 210) {
        doc.setFontSize(6)
        doc.text(275, 206, dayjs().format('HH:MM - DD.MM.YYYY'))
        doc.addPage()
        baseY = 10
      }
      let y = baseY

      let x = 10
      doc.setFontSize(11)
      y += 10
      doc.text(this.$t('balance'), x, y)
      y += 2
      doc.line(x, y, 110, y)
      y += 5
      doc.setFontSize(9)
      doc.text(this.$t('target'), x, y)
      doc.text(`${this.targetHour.toFixed(2)}`, x + 40, y, { align: 'right' })
      y += 5
      doc.text(this.$t('actual'), 10, y)
      doc.text(`${colSum[1].content}`, x + 40, y, { align: 'right' })
      y += 5
      doc.text(this.$t('over-under-time'), 10, y)
      doc.text(`${this.round(colSum[1].content - this.targetHour).toFixed(2)}`, x + 40, y, { align: 'right' })

      y = baseY
      x = 65
      doc.setFontSize(11)
      y += 17
      doc.setFontSize(9)
      doc.text(`${this.$t('employment')}:`, x, y)
      doc.text(`${this.currentEmploymentLevel} %`, x + 40, y, { align: 'right' })
      y += 5
      doc.text(this.$t('target-old'), x, y)
      doc.text(`${saldoOld.toFixed(2)}`, x + 40, y, { align: 'right' })
      y += 5
      doc.text(this.$t('target-new'), x, y)
      doc.text(`${saldoNew.toFixed(2)}`, x + 40, y, { align: 'right' })

      y = baseY
      x = 130
      doc.setFontSize(11)
      y += 10
      doc.text(this.$t('balance_vacation'), x, y)
      y += 2
      doc.line(x, y, x + 45, y)
      y += 5
      doc.setFontSize(9)
      doc.text(this.$t('balance-vacation-old'), x, y)
      doc.text(`${vacationSaldo.toFixed(2)}`, x + 40, y, { align: 'right' })
      y += 5

      doc.text(this.$t('vacation-taken'), x, y)
      doc.text(`${vacationCurrentMonth.toFixed(2)}`, x + 40, y, { align: 'right' })
      y += 5
      doc.text(this.$t('balance-vacation-new'), x, y)
      doc.text(`${this.round(vacationSaldo - vacationCurrentMonth).toFixed(2)}`, x + 40, y, { align: 'right' })

      // Visa
      y = baseY

      x = 195
      doc.setFontSize(11)
      y += 10
      doc.text('Visa', x, y)
      y += 2
      doc.line(x, y, x + 85, y)
      y += 5
      doc.setFontSize(9)
      doc.text(`${this.$t('employees')}:`, x, y)
      doc.line(x + 20, y + 0.25, x + 85, y + 0.25)
      y += 5
      doc.text(`${this.$t('supervisor')}:`, x, y)
      doc.line(x + 20, y + 0.25, x + 85, y + 0.25)
      y += 5
      doc.text(`${this.$t('date')}:`, x, y)
      doc.line(x + 20, y + 0.25, x + 85, y + 0.25)

      doc.setFontSize(6)
      doc.text(275, 206, dayjs().format('HH:MM - DD.MM.YYYY'))

      if (this.expensesGrouped.length > 0) {
        doc.addPage()
        doc.setFontSize(11)
        doc.text(`${this.$t('monthly-expenses-report')} ${this.startDate} - ${this.endDate}`, 10, 10)
        doc.line(10, 12, 287, 12)
        doc.setFontSize(11)
        doc.text(this.user.name, 10, 20)
        const headData = []
        headData.push({ content: this.$t('date'), styles: { halign: 'left' } })
        headData.push({ content: this.$t('project'), styles: { halign: 'left' } })
        headData.push(this.$t('amount'))
        headData.push(this.$t('note'))

        let row = []
        const data = []
        const bodyStyles = {}
        let total = 0.0
        this.expensesGrouped.forEach(wt => {
          row.push(dayjs(wt.date).format('DD.MM.YYYY'))
          bodyStyles[0] = { cellWidth: 17, fontSize: 7, cellPadding: 2 }
          row.push(wt.project)
          bodyStyles[1] = { cellWidth: 45, fontSize: 7, halign: 'left', valign: 'middle', cellPadding: { top: 2, bottom: 2, left: 2 } }
          row.push(`${wt.amount} CHF`)
          bodyStyles[2] = { cellWidth: 20, fontSize: 7, halign: 'right', valign: 'middle', cellPadding: { top: 2, bottom: 2, right: 2 } }
          row.push(wt.note)
          bodyStyles[3] = { fontSize: 7, halign: 'left', valign: 'middle', cellPadding: { top: 2, bottom: 2, right: 2, left: 2 } }
          data.push(row)
          row = []
          total += wt.amount
        })

        total = this.round(total)

        row.push({ content: this.$t('total'), styles: { halign: 'left', fontStyle: 'bold', cellPadding: { top: 6, bottom: 2, left: 2 } } })
        row.push('')
        row.push({ content: `${total} CHF`, styles: { halign: 'right', fontStyle: 'bold', cellPadding: { top: 6, bottom: 2, right: 2 } } })
        data.push(row)

        doc.autoTable({
          theme: 'grid',
          head: [headData],
          body: data,
          headStyles: { fillColor: null, textColor: 0, lineWidth: 0.1, fontSize: 8, fontStyle: 'normal', halign: 'center', valign: 'middle', cellPadding: 2 },
          columnStyles: bodyStyles,
          startY: 25,
          margin: { left: 10 }
        })

        doc.setFontSize(6)
        doc.text(275, 206, dayjs().format('HH:MM - DD.MM.YYYY'))
      }

      doc.save(`${this.$t('monthly-work-report')}_${this.months[this.month - 1]}_${this.year}.pdf`)
    }
  }
}
</script>
