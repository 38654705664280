<template>
<div>
  <div v-tooltip="'Löschen'" @click.stop.prevent="disabled ? null : confirm=true" class="bg-red-600 hover:bg-opacity-75 rounded-full w-8 h-8 flex items-center justify-center text-white cursor-pointer" :class="{'cursor-not-allowed opacity-25 hover:bg-opacity-100' : disabled}">
      <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
  </div>
  <div v-if="confirm" class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
    <div @click.stop="null" class="cursor-default modal-overlay absolute w-full h-full bg-gray-900 opacity-75"></div>
    <div class="modal-container bg-white dark:bg-black w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto px-5 -mt-64">
      <form>
        <div class="">
          <h2>{{$t('delete')}}</h2>
        </div>
        <div class="">
          {{text}}
        </div>
        <div class="flex justify-end pt-6 pb-4">
            <button class="" @click.stop="confirm=false" type="button">{{$t('cancel')}}</button>
            <button class="primary ml-2" @click.stop="done">{{$t('ok')}}</button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>

export default {
  props: {
    item: Object,
    text: String,
    disabled: Boolean,
    tooltip: String
  },
  data () {
    return {
      confirm: false
    }
  },
  methods: {
    done () {
      this.confirm = false
      this.$emit('on-item-deleted', this.item)
    }
  }
}
</script>
