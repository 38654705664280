<template>
<div>
  <div class="flex items-center">
    <router-link to="/app/team" class="p-2 bg-gray-200 dark:bg-gray-800 rounded-full mr-2 hover:bg-gray-400 dark:hover:bg-gray-600 cursor-pointer">
    <svg class="w-6 rounded-full text-black dark:text-white fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
    </router-link>
    <span class="text-4xl mr-2">{{user.name}}</span>
  </div>
  <Balances :user="user"></Balances>
  <EmploymentLevel :user="user"></EmploymentLevel>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Balances from '@/components/Balances.vue'
import EmploymentLevel from '@/components/EmploymentLevel.vue'

export default {
  components: {
    Balances,
    EmploymentLevel
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      userById: 'users/byId'
    }),
    user () {
      return this.userById(this.$route.params.id)
    }
  },
  methods: {

  }
}
</script>
