import { BSON } from 'realm-web'
import Vue from 'vue'

const sort = function (projects) {
  const hierarhySort = function (hashArr, key, result) {
    if (hashArr[key] === undefined) return
    const arr = hashArr[key].sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
    for (let i = 0; i < arr.length; i++) {
      result.push(arr[i])
      hierarhySort(hashArr, arr[i]._id, result)
    }
    return result
  }

  const hashArr = {}
  for (let i = 0; i < projects.length; i++) {
    const key = projects[i].parent.toString() !== '000000000000000000000000' ? projects[i].parent.toString() : null
    if (hashArr[key] === undefined) hashArr[key] = []
    hashArr[key].push(projects[i])
  }
  projects = hierarhySort(hashArr, null, [])
  return projects
}

// initial state
const state = {
  all: [],
  stream: null
}

// getters
const getters = {
  active: (state) => {
    return state.all.filter((project) => { return !project.isArchived && !project.isAbsence })
  },
  archived: (state) => {
    return state.all.filter((project) => { return project.isArchived && !project.isAbsence }).sort((a, b) => a.name.localeCompare(b.name))
  },
  getById: (state) => (id) => {
    return state.all.find(project => project._id.toString() === id.toString())
  },
  absences: (state) => {
    return state.all.filter((project) => { return project.isAbsence }).sort((a, b) => a.name.localeCompare(b.name))
  },
  children: (state) => (project) => {
    return state.all.filter((p) => { return p.parent === project._id })
  }
}

// actions
const actions = {
  async load ({ state, commit, rootGetters }) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const projects = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Project')
    projects.find({ _partition: `team=${rootGetters.team._id}` }).then(projects => {
      commit('setProjects', projects)
    })
    // const stream = projects.watch()
    // commit('setStream', stream)
    // for await (const change of state.stream) {
    //   const { operationType } = change
    //   switch (operationType) {
    //     case 'insert': {
    //       commit('addProject', change.fullDocument)
    //       break
    //     }
    //     case 'update': {
    //       commit('updateProject', change.fullDocument)
    //       break
    //     }
    //     case 'replace': {
    //       commit('updateProject', change.fullDocument)
    //       break
    //     }
    //     case 'delete': {
    //       commit('removeProject', change.documentKey)
    //       break
    //     }
    //   }
    // }
  },
  add ({ rootGetters, commit }, item) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const projects = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Project')
    const newProject = {
      _id: new BSON.ObjectID(),
      _partition: `team=${rootGetters.team._id}`,
      name: item.name,
      isAbsence: item.isAbsence || false,
      isArchived: false,
      isVacation: false,
      parent: item.parent ? item.parent : new BSON.ObjectId('000000000000000000000000')
    }
    projects.insertOne(newProject)
    commit('addProject', newProject)
  },
  remove ({ rootGetters, commit }, item) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const projects = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Project')
    projects.deleteOne({ _id: item._id })
    commit('removeProject', item._id)
  },
  import ({ rootGetters, commit }, payload) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const projects = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Project')
    const projectInputs = payload.names.map(name => {
      const newProject = {
        _id: new BSON.ObjectID(),
        _partition: `team=${rootGetters.team._id}`,
        name: name,
        isAbsence: false,
        isVacation: false,
        parent: payload.parent ? new BSON.ObjectId(payload.parent) : new BSON.ObjectId('000000000000000000000000'),
        isArchived: false
      }
      return newProject
    })
    projects.insertMany(projectInputs)
    projectInputs.forEach(p => {
      commit('addProject', p)
    })
  },
  update ({ rootGetters, commit }, item) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const projects = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Project')
    const project = {
      name: item.name,
      isAbsence: item.isAbsence || false,
      isArchived: item.isArchived,
      parent: item.parent ? new BSON.ObjectId(item.parent) : new BSON.ObjectId('000000000000000000000000')
    }
    projects.updateOne({ _id: new BSON.ObjectId(item._id) }, { $set: project })
    project._id = item._id
    commit('updateProject', project)
  },
  update_multiple ({ rootGetters, commit }, payload) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const projects = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Project')

    projects.updateMany({ _id: { $in: payload.ids } }, { $set: { isArchived: payload.isArchived } })
    commit('updateArchived', payload)
  }
}

// mutations
const mutations = {
  setProjects (state, projects) {
    if (projects.length === 0) {
      return
    }
    projects = sort(projects)
    state.all = projects
  },
  updateArchived (state, payload) {
    payload.ids.forEach(id => {
      const index = state.all.findIndex(p => p._id.toString() === id.toString())
      Vue.set(state.all[index], 'isArchived', payload.isArchived)
    })
    state.all = sort(state.all)
  },
  updateProject (state, project) {
    const index = state.all.findIndex(p => p._id.toString() === project._id.toString())
    Vue.set(state.all, index, project)
    state.all = sort(state.all)
  },
  addProject (state, project) {
    state.all.push(project)
    state.all = sort(state.all)
  },
  removeProject (state, key) {
    const index = state.all.findIndex(p => p._id.toString() === key.toString())
    state.all.splice(index, 1)
  },
  setStream (state, stream) {
    state.stream = stream
  },
  resetState (state) {
    if (state.stream) state.stream.return()
    state.all = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
