<template>
<div
  v-tooltip="'Export (*.csv)'"
  @click="runExport()"
  class="bg-secondary p-2 rounded-full cursor-pointer hover:bg-opacity-75"
  >
   <div class="flex">
   <svg class="fill-current h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7 2v-2h10v2h-10zm0 4h10v-2h-10v2zm10 7v-5h-10v5h-6l11 11 11-11h-6z"/></svg>
   <span class="md:hidden text-white text-xs px-2">{{$t('export-csv')}}</span>
   </div>
</div>
</template>

<script>
import dateMixin from '../../mixin/dateMixin.js'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  mixins: [dateMixin],
  props: {
    worktimes: Array
  },
  computed: {
    ...mapGetters({
      userById: 'users/byId',
      getProjectById: 'projects/getById',
      getTaskById: 'tasks/getById'
    })
  },
  methods: {
    addValue: function (str) {
      return str + ';'
    },
    runExport: function () {
      let csvContent = 'data:text/csv;charset=utf-8,'
      let row = 'Date;CreatedBy;Time;Hours;Seconds;Project;Task;Note;Type;Expenses\r\n'
      this.worktimes.forEach(wt => {
        row += this.addValue(dayjs(wt.date).format('DD.MM.YYYY'))
        row += this.addValue(this.userById(wt.createdBy) ? this.userById(wt.createdBy).name : '???')
        row += this.addValue(this.formatSecondsToHM(wt.seconds))
        row += this.addValue(parseFloat(wt.seconds / 3600).toFixed(2))
        row += this.addValue(wt.seconds)
        row += this.addValue(wt.projectId.toString() !== '000000000000000000000000' ? this.getProjectById(wt.projectId).name : 'Keines')
        row += this.addValue(wt.taskId.toString() !== '000000000000000000000000' ? this.getTaskById(wt.taskId).name : 'Keines')
        row += this.addValue(wt.note ? `"${wt.note.replace(/\n/g, ', ')}"` : '-')
        row += this.addValue(wt.type)
        row += wt.expenses
        row += '\r\n'
      })
      csvContent += row + '\r\n'
      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'samay_export.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>
