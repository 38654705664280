<template>
  <div
   class="bg-transparent text-white text-opacity-50 dark:border-gray-800"
   :class="{ 'border-t-2 border-gray-200 text-gray-600 ': isLoggedIn && team !== null }"
   >
    <div class="container mx-auto flex justify-between">
      <div></div>
      <p class="p-3 text-center">
        © {{new Date().getFullYear()}} samay.ch ({{version}})
         • <router-link :class="[isLoggedIn ? '' : 'text-green-400 hover:text-green-200']"  to="/privacy">{{$t('privacy')}}</router-link>
         • <router-link :class="[isLoggedIn ? '' : 'text-green-400 hover:text-green-200']" to="/terms">{{$t('terms')}}</router-link>
      </p>
      <LocaleChanger :class="[isLoggedIn ? 'text-gray-600 ' : 'text-gray-400 ']" class="text-sm"></LocaleChanger>
    </div>
  </div>
</template>

<script>
import LocaleChanger from '@/components/LocaleChanger'
import { mapGetters } from 'vuex'
export default {
  components: {
    LocaleChanger
  },
  data () {
    return {
      version: process.env.VUE_APP_VERSION
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'team'])
  }
}
</script>
