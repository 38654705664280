<style scoped>
.remove-background {
  background: none !important;
}
.editMode {
  margin-top: -5px;
  margin-left: 5px;
}
.viewMode{
  margin-left: 5px;
}

</style>
<template>
<div class="flex items-center justify-end">
  <div v-if="state === 1" v-on:click.stop="stateChanged(2)" class="cursor-pointer" :class="[{'text-gray-800 dark:text-gray-200': editMode, 'editMode': editMode, 'viewMode': !editMode}]">
    <svg class="fill-current w-8" :class="[editMode ? 'text-gray-800 dark:text-gray-200' : 'text-white dark:text-black']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 496">
      <path d="M248 0C111 0 0 111 0 248s111 248 248 248 248-111 248-248S385 0 248 0zm0 466c-120.445 0-218-97.555-218-218S127.555 30 248 30s218 97.555 218 218-97.555 218-218 218zm86-298v160c0 8.8-5.4 16-12 16h-36c-6.6 0-12-7.2-12-16V168c0-8.8 5.4-16 12-16h36c6.6 0 12 7.2 12 16zm-112 0v160c0 8.8-5.4 16-12 16h-36c-6.6 0-12-7.2-12-16V168c0-8.8 5.4-16 12-16h36c6.6 0 12 7.2 12 16z" fill-rule="nonzero"/>
    </svg>
  </div>
  <div v-else-if="(state === 2) || (editMode)" v-on:click.stop="stateChanged(1)" class="cursor-pointer" :class="[{'text-gray-800 dark:text-gray-200': editMode, 'editMode': editMode, 'viewMode': !editMode}]">
    <svg class="fill-current w-8" :class="[editMode ? 'text-gray-800 dark:text-gray-200' : 'text-white dark:text-black']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"/></svg>
  </div>
  <div v-if="state > 0" v-on:click.stop="stateChanged(0)" class="cursor-pointer" :class="[{'text-gray-800 dark:text-gray-200': editMode, 'editMode': editMode, 'viewMode': !editMode}]">
    <svg class="fill-current w-8" :class="[editMode ? 'text-gray-800 dark:text-gray-200' : 'text-white dark:text-black']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 496">
      <path d="M248 0C111 0 0 111 0 248s111 248 248 248 248-111 248-248S385 0 248 0zm0 466c-120.445 0-218-97.555-218-218S127.555 30 248 30s218 97.555 218 218-97.555 218-218 218zm86-298v160c0 8.8-5.4 16-12 16h-36c-6.6 0-12-7.2-12-16V168c0-8.8 5.4-16 12-16h36c6.6 0 12 7.2 12 16zm-112 0v160c0 8.8-5.4 16-12 16h-36c-6.6 0-12-7.2-12-16V168c0-8.8 5.4-16 12-16h36c6.6 0 12 7.2 12 16zm82 0v160c0 8.8-10.08 16-22.4 16h-67.2c-12.32 0-22.4-7.2-22.4-16V168c0-8.8 10.08-16 22.4-16h67.2c12.32 0 22.4 7.2 22.4 16z" fill-rule="nonzero"/>
    </svg>
  </div>
</div>
</template>

<script>

export default {
  props: {
    state: Number,
    editMode: Boolean
  },
  methods: {
    stateChanged: function (state) {
      this.$emit('on-state-changed', state)
    }
  }
}
</script>
