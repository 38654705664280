<template>
<div v-tooltip="'Wiederherstellen'" @click.stop="restoreItem(item)" class="bg-primary hover:bg-opacity-75 rounded-full w-8 h-8 flex items-center justify-center text-white cursor-pointer">
    <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.5 2c-5.629 0-10.212 4.436-10.475 10h-3.025l4.537 5.917 4.463-5.917h-2.975c.26-3.902 3.508-7 7.475-7 4.136 0 7.5 3.364 7.5 7.5s-3.364 7.5-7.5 7.5c-2.381 0-4.502-1.119-5.876-2.854l-1.847 2.449c1.919 2.088 4.664 3.405 7.723 3.405 5.798 0 10.5-4.702 10.5-10.5s-4.702-10.5-10.5-10.5z"/></svg>
</div>
</template>

<script>

export default {
  props: {
    item: Object
  },
  methods: {
    restoreItem: function (item) {
      this.$emit('on-item-restored', this.item)
    }
  }
}
</script>
