<template>
<div>
   <div class="flex items-center mt-4">
    <span class="text-lg block mr-2">{{$t('balances_start_year')}}</span>
    <a @click="addBalance(user._id)" class="cursor-pointer">
      <svg class="h-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"/></svg>
    </a>
  </div>
  <p>
    {{$t('balance-description')}}
  </p>
  <div v-if="balanceByUser(user._id).length === 0" class="w-full py-2 px-4 bg-gray-200  dark:bg-gray-800 rounded border border-gray-400 dark:border-gray-600 text-gray-600 dark:text-gray-300 mt-2">
    <span class="mt-1 block">{{$t('no-balances')}}</span>
    <p class="text-xs">{{$t('no-balances-hint')}}</p>
  </div>
  <table v-else class="table-auto w-full">
    <thead>
      <tr>
        <th class="p-1 text-left uppercase text-gray-600 dark:text-gray-400 text-sm font-normal py-3">{{$t('year')}}</th>
        <th class="p-1 text-left uppercase text-gray-600 dark:text-gray-400 text-sm font-normal py-3">{{$t('overtime')}}</th>
        <th class="p-1 text-left uppercase text-gray-600 dark:text-gray-400 text-sm font-normal py-3">{{$t('balance_vacation')}}</th>
        <th class="p-1 text-left uppercase text-gray-600 dark:text-gray-400 text-sm font-normal py-3"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(balance, index) in balanceByUser(user._id)" :key="index"
        class="border-t border-gray-400 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-800 even:bg-gray-100 dark:even:bg-gray-900"
      >
        <td class="p-2 text-xs w-24">
          <input  v-if="editRow===index" v-model="balance.year" class="p-1 text-xs">
          <span v-else>{{balance.year}}</span>
        </td>
        <td class="p-2 text-xs w-24">
          <input  v-if="editRow===index" v-model="balance.balanceWorktime" class="p-1 text-xs">
          <span v-else>{{balance.balanceWorktime}} {{$t('hour-short')}}</span>
        </td>
        <td class="p-2 text-xs w-24">
          <input  v-if="editRow===index" v-model="balance.balanceVacation" class="p-1 text-xs">
          <span v-else>{{balance.balanceVacation}} {{$t('hour-short')}}</span>
        </td>
        <td class="">
          <div class="flex">
          <div v-if="editRow===-1" @click="editBalance(balance, index)" class="bg-primary hover:bg-opacity-75 rounded-full w-6 h-6 flex items-center justify-center text-white cursor-pointer">
            <svg class="h-3 w-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"/></svg>
          </div>
          <div v-if="editRow===-1" @click="deleteBalance(balance)" class="ml-1 bg-red-600 hover:bg-opacity-75 rounded-full w-6 h-6 flex items-center justify-center text-white cursor-pointer">
            <svg class="h-3 w-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9 19c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5-17v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712zm-3 4v16h-14v-16h-2v18h18v-18h-2z"/></svg>
          </div>

          <div v-if="editRow===index" @click="cancelBalance(true)" class="bg-red-500 hover:bg-opacity-75 rounded-full w-6 h-6 flex items-center justify-center text-white cursor-pointer">
            <svg class="w-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
          </div>
          <div v-if="editRow===index" @click="updateBalance(balance);editRow = -1" class="ml-1 bg-primary hover:bg-opacity-75 rounded-full w-6 h-6 flex items-center justify-center text-white cursor-pointer">
            <svg class="w-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z"/></svg>
          </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      editRow: -1,
      editBalanceItem: {}
    }
  },
  computed: {
    ...mapGetters({
      balanceByUser: 'balances/getByUser'
    })
  },
  methods: {
    ...mapActions({
      addBalance: 'balances/add',
      deleteBalance: 'balances/remove',
      updateBalance: 'balances/update'
    }),
    editBalance (item, index) {
      this.editBalanceItem = JSON.parse(JSON.stringify(item))
      this.editRow = index
    },
    cancelBalance (reset) {
      if (reset) {
        this.$store.commit('balances/resetBalanceForUser', this.editBalanceItem)
      }
      this.editLevelItem = {}
      this.editRow = -1
    }
  }
}
</script>
