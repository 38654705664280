<template>
  <div class="bg-gradient-to-br from-primary to-secondary text-white">
    <div class="container mx-auto px-5">
        <div class="flex flex-wrap justify-between items-center py-5 text-2xl">
          <router-link to="/">Samay</router-link>

          <div class="flex items-center justify-between text-lg">
            <div class="w-full" v-if="$route.path === '/'">
              <a href="#webapp">{{$t('app')}}</a>
              <a class="ml-2" href="#price">{{$t('prices')}}</a>
              <a class="ml-2" href="#contact">{{$t('contact')}}</a>
            </div>
            <div class="flex items-center justify-end ml-3">
              <a class="bg-secondary w-12 h-12 rounded-full flex items-center justify-center hover:bg-opacity-75" href="https://www.instagram.com/8_samay_8/" rel="noreferrer" target="_blank">
                <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
              </a>
              <a class="ml-2 bg-secondary w-12 h-12 rounded-full flex items-center justify-center hover:bg-opacity-75" href="mailto:hello@samay.ch">
                <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"/></svg>
              </a>
            </div>
          </div>
        </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  components: {
  }
}
</script>
