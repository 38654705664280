var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"text-4xl"},[_vm._v(_vm._s(_vm.$t('team')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('invite-team-text'))+" "),_c('a',{staticClass:"text-primary hover:font-bold",attrs:{"href":_vm.mailToText()}},[_vm._v(_vm._s(_vm.$t('send-invitations')))])]),_c('Grid',{staticClass:"overflow-x-auto",attrs:{"canSelect":true,"items":_vm.users,"columns":_vm.columns},on:{"row-selected":_vm.onSelectRow},scopedSlots:_vm._u([{key:"row",fn:function(ref){
var item = ref.item;
var value = ref.value;
var keyName = ref.keyName;
return [(keyName=='isAdmin')?_c('input',{attrs:{"type":"checkbox","disabled":_vm.isMe(item)},domProps:{"checked":_vm.isAdmin(item)},on:{"click":function($event){$event.stopPropagation();return _vm.onIsAdminChanged(item)}}}):(keyName === 'percent')?_c('span',[_vm._v(_vm._s(_vm.activePercentForUser(item._id))+"%")]):_c('span',[_vm._v(_vm._s(value))])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [(_vm.isLoading(item))?_c('div',{attrs:{"uk-spinner":"ratio: 0.75"}}):_vm._e(),_c('div',{staticClass:"flex items-center"},[_c('MonthlyWorkReport',{attrs:{"userId":item._id}}),_c('NotifyButton',{staticClass:"ml-1",attrs:{"disabled":!item.fcmToken || _vm.isLoading(item),"item":item},on:{"on-item-notify":_vm.onItemNotify}}),_c('ArchiveButton',{staticClass:"ml-1",attrs:{"disabled":_vm.isMe(item) || _vm.isAdmin(item),"item":item,"tooltip":'Benutzerkonto deaktivieren'},on:{"on-item-archived":_vm.onItemArchived}}),_c('svg',{staticClass:"w-3 h-3 ml-4 fill-current text-gray-600",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"}})])],1)]}}])}),(_vm.lockedUsers.length > 0)?[_c('h2',[_vm._v(_vm._s(_vm.$t('deactivated-employees')))]),_c('Grid',{attrs:{"items":_vm.lockedUsers,"columns":_vm.columnsLocked},scopedSlots:_vm._u([{key:"row",fn:function(ref){
var item = ref.item;
var value = ref.value;
var keyName = ref.keyName;
return [(keyName=='isAdmin')?_c('input',{staticClass:"cursor-pointer",attrs:{"type":"checkbox","disabled":_vm.isMe(item)},domProps:{"checked":_vm.isAdmin(item)},on:{"click":function($event){$event.stopPropagation();return _vm.onIsAdminChanged(item)}}}):_c('span',[_vm._v(_vm._s(value))])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [(_vm.isLoading(item))?_c('div',{attrs:{"uk-spinner":"ratio: 0.75"}}):_vm._e(),_c('RestoreButton',{attrs:{"item":item},on:{"on-item-restored":_vm.onItemRestored}}),_c('DeleteButton',{staticClass:"ml-1",attrs:{"disabled":_vm.isMe(item) || _vm.isAdmin(item),"item":item,"tooltip":'Benutzerkonto löschen',"text":'Willst du der Mitarbeiter endgültig löschen?'},on:{"on-item-deleted":_vm.onItemDeleted}})]}}],null,false,3755231322)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }