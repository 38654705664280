import { BSON } from 'realm-web'
import Vue from 'vue'

// initial state
const state = {
  all: [],
  stream: null
}

// getters
const getters = {
  active: (state) => {
    return state.all.filter((task) => { return !task.isArchived }).sort((a, b) => a.name.localeCompare(b.name))
  },
  archived: (state) => {
    return state.all.filter((task) => { return task.isArchived }).sort((a, b) => a.name.localeCompare(b.name))
  },
  getById: (state) => (id) => {
    return state.all.find(task => task._id.toString() === id.toString())
  }
}

// actions
const actions = {
  async load ({ state, commit, rootGetters }) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const tasks = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Task')
    tasks.find({ _partition: `team=${rootGetters.team._id}` }).then(tasks => {
      commit('setTasks', tasks)
    })
    // const stream = tasks.watch()
    // commit('setStream', stream)
    // for await (const change of state.stream) {
    //   const { operationType } = change
    //   switch (operationType) {
    //     case 'insert': {
    //       commit('add', change.fullDocument)
    //       break
    //     }
    //     case 'update': {
    //       commit('update', change.fullDocument)
    //       break
    //     }
    //     case 'replace': {
    //       commit('update', change.fullDocument)
    //       break
    //     }
    //     case 'delete': {
    //       commit('remove', change.documentKey)
    //       break
    //     }
    //   }
    // }
  },
  add ({ rootGetters, commit }, item) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const tasks = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Task')
    const newTask = {
      _id: new BSON.ObjectID(),
      _partition: `team=${rootGetters.team._id}`,
      name: item.name,
      isArchived: false
    }
    tasks.insertOne(newTask)
    commit('add', newTask)
  },
  remove ({ rootGetters, commit }, item) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const tasks = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Task')
    tasks.deleteOne({ _id: item._id })
    commit('remove', item._id)
  },
  update ({ rootGetters, commit }, item) {
    const mongodb = rootGetters.app.currentUser.mongoClient('samay')
    const tasks = mongodb.db(process.env.VUE_APP_SAMAY_DB).collection('Task')
    const task = {
      name: item.name,
      isArchived: item.isArchived
    }
    tasks.updateOne({ _id: new BSON.ObjectId(item._id) }, { $set: task })
    task._id = item._id
    commit('update', task)
  }
}

// mutations
const mutations = {
  setTasks (state, tasks) {
    state.all = tasks
  },
  update (state, task) {
    const index = state.all.findIndex(p => p._id.toString() === task._id.toString())
    Vue.set(state.all, index, task)
  },
  add (state, task) {
    state.all.push(task)
  },
  remove (state, id) {
    const index = state.all.findIndex(p => p._id.toString() === id.toString())
    state.all.splice(index, 1)
  },
  setStream (state, stream) {
    state.stream = stream
  },
  resetState (state) {
    if (state.stream) state.stream.return()
    state.all = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
