<template>
  <div>
    <img v-if="avatar"
      class="rounded-full flex items-center justify-center"
      :class="[small ? 'w-8 h-8' : 'w-20 h-20']"
      :src="`data:image/jpeg;base64,${avatar}`"
    />
    <div v-else
      class="bg-primary border border-green-500 hover:bg-opacity-75 rounded-full flex items-center justify-center  text-white shadow-lg"
      :class="[small ? 'w-8 h-8 text-xl' : 'w-20 h-20 text-4xl']"
    >
      {{user ? user.name.charAt(0) : '?'}}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      avatarStr: null
    }
  },
  props: {
    user: Object,
    small: Boolean,
    image: String
  },
  computed: {
    ...mapGetters({
      app: 'app',
      findAvatar: 'findAvatar'
    }),
    avatar () {
      if (this.image) {
        return this.image
      }
      if (!this.user) {
        return null
      }
      const avatar = this.findAvatar(this.user._id)
      if (avatar) {
        return avatar.data
      }
      return null
    }
  },
  methods: {
    ...mapActions({
      addAvatar: 'addAvatar',
      updateAvatar: 'updateAvatar'
    })
  },
  watch: {
    // whenever question changes, this function will run
    user: {
      immediate: true,
      handler (newQuestion, oldQuestion) {
        if (!this.user) {
          return
        }
        const avatar = this.findAvatar(this.user._id)
        if (!this.user.avatar || this.user.avatar === '') {
          return
        }
        if (avatar && avatar.filename === this.user.avatar) {
          return
        }
        this.app.currentUser.functions.getAvatar(this.user.avatar).then(r => {
          this.avatarStr = r
          const payload = { userId: this.user._id, filename: this.user.avatar, data: r }
          this.updateAvatar(payload)
        })
      }
    }
  }
}
</script>
