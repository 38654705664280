<template>
  <div v-if="open" class="fixed w-full h-full top-0 left-0 flex items-center justify-center">
    <div class="absolute w-full h-full bg-gray-900 opacity-75"></div>
    <div class="bg-white dark:bg-black w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto px-5 -mt-64">

        <h2 class="">{{$t('new-absence')}}</h2>
        <form @submit.prevent="done">
          <div class="flex flex-col text-sm">
            <label class="text-sm mt-3">Von - Bis</label>
            <div class="font-bold">{{dates[0].format('DD.MM.YYYY')}}<span> - {{dates[dates.length - 1].format('DD.MM.YYYY')}} ({{dates.length}} Arbeitstage)</span></div>

            <label class="text-sm mt-3">{{$t('employment')}}</label>
            <span class="block font-bold"> {{activePercent}} %</span>

            <label class="text-sm mt-3">{{$t('absence')}}</label>
              <div class="relative">
                <select v-model="editItem.projectId" :required="true" :disabled="editItem.state > 0">
                  <option disabled value="undefined">{{$t('choose-project')}}</option>
                  <option
                    v-for="(project) in projects"
                    :key="project._id.toString()"
                    :value="project._id"
                    >{{project.name}}</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
              </div>

              <label class="text-sm mt-3">{{$t('time-per-day')}}</label>
              <div class="relative">
                <flat-pickr
                    v-model="currentTime"
                    :config="config"
                    name="date">
                </flat-pickr>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
              </div>

          </div>

        <div class="flex justify-end pt-6 pb-4">
            <button class="" @click="$emit('on-close')" type="button">{{$t('cancel')}}</button>
            <button class="primary ml-2" type="submit" :class="{'cursor-not-allowed opacity-25' : (this.editItem.projectId === undefined)}" :disabled="(this.editItem.projectId === undefined)">
              {{$t('save')}}
            </button>
        </div>
        </form>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'
import dateMixin from '@/mixin/dateMixin.js'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

function pad (value, size) {
  let s = String(value)
  while (s.length < (size || 2)) {
    s = '0' + s
  }
  return s
}

export default {
  mixins: [dateMixin],
  props: {
    range: Object,
    open: {
      type: Boolean,
      default: false
    }
  },
  components: {
    flatPickr
  },
  data () {
    return {
      config: {
        minuteIncrement: 1,
        time_24hr: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        minTime: '00:00'
      },
      time: 0,
      editItem: {}
    }
  },
  computed: {
    ...mapGetters({
      activePercentForUser: 'employmentlevel/activePercentForUser',
      projects: 'projects/absences',
      user: 'user',
      team: 'team'
    }),
    currentTime: {
      get: function () {
        const h = pad(Math.floor(this.time / 60 / 60), 2)
        const m = pad(this.time / 60 % 60, 2)
        return `${h}:${m}`
      },
      set: function (value) {
        const tmp = value.split(':')
        const h = parseInt(tmp[0])
        this.time = parseInt(tmp[1] * 60) + (h * 60 * 60)

        this.editItem.seconds = this.time
      }
    },
    dates () {
      let from = dayjs(this.range.from.toString(), 'YYYYMMDD')
      const to = dayjs(this.range.to.toString(), 'YYYYMMDD')
      const max = Math.abs(from.diff(to, 'day'))
      const result = []
      for (let index = 0; index <= max; index++) {
        if (from.day() !== 0 && from.day() !== 6) {
          result.push(from)
        }
        from = from.add(1, 'day')
      }
      return result
    },
    activePercent () {
      return this.activePercentForUser(this.user._id, this.dates[0])
    }
  },
  methods: {
    ...mapActions({
      addWorktimes: 'worktimes/add_absences'
    }),
    done () {
      const worktimes = []
      this.dates.forEach(d => {
        this.editItem.date = d.format('YYYY-MM-DD')
        this.editItem.isAbsence = true
        const tmpItem = JSON.parse(JSON.stringify(this.editItem))
        tmpItem.date = new Date(tmpItem.date)
        tmpItem.createdAt = new Date(tmpItem.createdAt)
        worktimes.push(tmpItem)
      })
      this.addWorktimes(worktimes)
      this.$emit('on-close')
    }
  },
  mounted () {
    this.$nextTick(() => {
      const hoursPerWeek = this.team.hoursPerWeek || 42
      this.time = hoursPerWeek / 5 * 3600
    })
  }
}
</script>
