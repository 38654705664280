<template>
    <div class="w-full border border-gray-200 dark:border-gray-800 h-6 flex items-center justify-center relative rounded"
    :class="[timeDifference > 0 ? 'bg-red-700 bg-opacity-50' : 'bg-green-700 bg-opacity-50']">
      <div class="absolute top-0 left-0 h-full flex items-center justify-center rounded"
        :class="[timeDifference > 0 ? 'bg-red-600 bg-opacity-75' : 'bg-primary bg-opacity-75']"
        :style="'width:' + currentPercent+ '%'"> </div>
      <div class="text-xs text-white z-10">{{timeDifferenceStr}}</div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import dateMixin from '@/mixin/dateMixin.js'

export default {
  mixins: [dateMixin],
  props: {
    date: {
      type: Object,
      required: true
    },
    // M = 0,W = 1,D = 2
    type: {
      type: Number,
      default: 0
    },
    year: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      totalMonthHours: 0,
      totalDayHours: 0,
      totalWeekHours: 0
    }
  },
  computed: {
    ...mapGetters({
      targetTimesByYear: 'targettimes/getByYear',
      activePercentForUser: 'employmentlevel/activePercentForUser',
      team: 'team',
      user: 'user'
    }),
    hoursPerWeek () {
      return this.team.hoursPerWeek
    },
    month () {
      const month = this.date.format('MMM').toLowerCase()
      return month
    },
    monthHours () {
      const tt = this.targetTimesByYear(this.year)
      if (tt === undefined) {
        return 0
      }
      return tt[this.month] * this.currentUserPercent
    },
    currentUserPercent () {
      return this.activePercentForUser(this.user._id, this.date) / 100
    },
    timeDifference () {
      if (this.type === 2) {
        return this.hoursPerWeek * 3600 / 5 - this.totalDayHours
      }
      if (this.type === 1) {
        return this.hoursPerWeek * 3600 * this.currentUserPercent - this.totalWeekHours
      }
      return this.monthHours * 3600 - this.totalMonthHours
    },
    timeDifferenceStr () {
      const strDiff = this.formatSecondsToHM(Math.abs(this.timeDifference))
      if (this.timeDifference > 0) {
        return `-${strDiff}`
      } else {
        return `+${strDiff}`
      }
    },
    currentPercent () {
      if (this.type === 2) {
        return Math.min(Math.round(100 / (this.hoursPerWeek * 3600 / 5) * this.totalDayHours), 100)
      }
      if (this.type === 1) {
        return Math.min(Math.round(100 / (this.hoursPerWeek * 3600 * this.currentUserPercent) * this.totalWeekHours), 100)
      }
      return Math.min(Math.round(100 / (this.monthHours * 3600) * this.totalMonthHours), 100)
    }
  },
  methods: {
    ...mapActions({
      totalForRange: 'worktimes/totalForRange'
    })
  },
  async mounted () {
    this.totalMonthHours = await this.totalForRange({ userId: this.user._id, startDate: this.date.startOf('month'), endDate: this.date.endOf('month') })
    this.totalDayHours = await this.totalForRange({ userId: this.user._id, startDate: this.date.startOf('day'), endDate: this.date.endOf('day') })
    this.totalWeekHours = await this.totalForRange({ userId: this.user._id, startDate: this.date.startOf('week'), endDate: this.date.endOf('week') })
    this.$root.$on('updateWorktime', async () => {
      this.totalMonthHours = await this.totalForRange({ userId: this.user._id, startDate: this.date.startOf('month'), endDate: this.date.endOf('month') })
      this.totalDayHours = await this.totalForRange({ userId: this.user._id, startDate: this.date.startOf('day'), endDate: this.date.endOf('day') })
      this.totalWeekHours = await this.totalForRange({ userId: this.user._id, startDate: this.date.startOf('week'), endDate: this.date.endOf('week') })
    })
  }
}
</script>
