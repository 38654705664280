<template>
<div class="">
  <div v-if="item.isApproved" v-on:click.stop="approveChanged(item)" class="cursor-pointer hover:opacity-75">
    <svg class="w-5 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10 0v-4c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-8z"/></svg>
  </div>
  <div v-else v-on:click.stop="approveChanged(item)" class="cursor-pointer hover:opacity-75">
    <svg class="w-5 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 10v-4c0-2.206 1.794-4 4-4 2.205 0 4 1.794 4 4v1h2v-1c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-13z"/></svg>
  </div>
</div>
</template>

<script>

export default {
  props: {
    item: Object
  },
  methods: {
    approveChanged: function (item) {
      if (item.isApproved) {
        this.$emit('on-item-unlock', this.item)
      } else {
        this.$emit('on-item-lock', this.item)
      }
    }
  }
}
</script>
