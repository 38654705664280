<template>
  <div>
    <div @click="open=true" class="bg-secondary p-2 rounded-full cursor-pointer hover:bg-opacity-75">
      <svg class="rotate-180 fill-current h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7 2v-2h10v2h-10zm0 4h10v-2h-10v2zm10 7v-5h-10v5h-6l11 11 11-11h-6z"/></svg>
    </div>
  <div v-if="open" class="fixed w-full h-full top-0 left-0 flex items-center justify-center">
    <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-75"></div>
    <div class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto px-5 -mt-64">
      <form @submit.prevent="done">
        <div class="">
          <h2 class="">{{$t('import')}}</h2>
        </div>
        <label class="text-sm">{{$t('main-project')}}</label>
        <v-select :options="projects" v-model="parent">
          <template v-slot:default="prop">
            {{prop.item ? prop.item.name : $t('none')}}
          </template>
        </v-select>
        <p class="p-0 m-0 mt-2 text-gray-500">
          {{$t('hint-add-projects')}}
        </p>
        <textarea v-model="text" class="w-full bg-gray-300 rounded h-64 p-2 text-sm whitespace-nowrap"></textarea>
        <div class="flex justify-end pt-6 pb-4">
            <button class="" @click="open=false" type="button">{{$t('cancel')}}</button>
            <button class="primary ml-2" type="submit">{{$t('save')}}</button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VSelect from '@/components/v-select'
export default {
  components: {
    'v-select': VSelect
  },
  props: {
  },
  data () {
    return {
      open: false,
      text: '',
      parent: null
    }
  },
  computed: {
    ...mapGetters({
      projects: 'projects/active'
    })
  },
  methods: {
    ...mapActions({
      import: 'projects/import'
    }),
    done () {
      this.open = false
      const lines = this.text.split('\n')
      const data = []
      lines.forEach(l => {
        data.push(l)
      })
      this.import({ names: data, parent: this.parent })
      this.text = ''
      this.parent = null
    }
  }
}
</script>
