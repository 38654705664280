<style scoped>
 .dropdown {
   max-height: 300px;
 }
</style>
<template>
<div>
  <div v-if="showMenu" class="top-0 left-0 z-50 fixed w-full h-screen" @click.stop="showMenu = false"></div>
  <div
    class="relative cursor-pointer"
    @click.stop="showMenu = !showMenu"
  >
    <div class="bg-blue-400 hover:bg-opacity-75 rounded-full w-8 h-8 flex items-center justify-center text-white cursor-pointer"  :class="{'ring-2 ring-blue-200 border-transparent': showMenu}">
      <svg class="fill-current h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 22v-20h16v11.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362zm18-7.614v-14.386h-20v24h10.189c3.163 0 9.811-7.223 9.811-9.614zm-5-1.386h-10v-1h10v1zm0-4h-10v1h10v-1zm0-3h-10v1h10v-1z"/></svg>
    </div>
    <div class="absolute left-0 z-50 shadow-lg mt-1 rounded bg-white dark:bg-black min-w-min w-full" :class="{ 'hidden': !showMenu }">
      <div class="relative">
        <div class="dropdown overflow-y-auto min-w-full border border-gray-300 dark:border-gray-700">
          <div
            v-for="(item, index) in options" :key="index"
            class="flex items-center even:bg-gray-100 dark:even:bg-gray-900  hover:bg-primary dark:hover:bg-primary hover:text-white"
          >
            <a
              class="text-xs px-2 py-1 block whitespace-nowrap cursor-pointer truncate w-full"
              href="#"
              @click.stop.prevent="itemChanged(item)"
            >
              {{item.label}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  data () {
    return {
      showMenu: false
    }
  },
  computed: {
    options () {
      const tmp = []
      const start = dayjs().month()
      for (let i = start; i >= 0; i--) {
        tmp.push({ month: i + 1, year: dayjs().year(), label: dayjs().month(i).format('MMM YYYY') })
      }
      if (tmp.length === 1) {
        tmp.push({ month: 12, year: dayjs().year() - 1, label: dayjs().year(dayjs().year() - 1).endOf('year').format('MMM YYYY') })
      }
      return tmp
    }
  },
  methods: {
    itemChanged (item) {
      this.showMenu = false
      this.$emit('input', item)
    }
  }
}
</script>
