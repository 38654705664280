<style scoped>
  a.is-active {
    @apply text-white bg-primary hover:bg-primary hover:text-white font-normal;
  }
  li a {
    @apply rounded-full px-2 py-1 text-sm mr-1;
  }
  li a:hover {
    @apply bg-gray-200 dark:text-gray-700;
  }
</style>
<template>
<div class="w-full h-full min-h-screen flex flex-col justify-between" :class="{ 'bg-gradient-to-br from-primary to-secondary': !isLoggedIn || !team }">
  <div class="h-full">
  <router-link v-if="isLoggedIn && team && !trialExpired && !team.activated" to="/app/settings" class="text-center">
  <div class="bg-primary bg-opacity-25 text-primary p-3 text-sm font-bold border-b-2 border-gray-300 dark:border-gray-600">
      {{trialText}}
  </div>
  </router-link>
  <div v-if="isLoggedIn && team" class="w-full bg-white dark:bg-black border-b-2 border-gray-300 dark:border-gray-600 mb-5 text-gray-500">
    <div class="container mx-auto">
      <div class="items-center flex justify-between py-2 px-4">
        <div class="flex items-center">
          <SamayLogo/>
          <ul class="hidden md:flex text-gray-800 dark:text-gray-200">
            <template v-if="isAdmin">
              <li>
                <router-link to="/app">{{$t('dashboard')}}</router-link>
              </li>
            </template>
            <li><router-link to="/app/worktimes">{{$t('worktimes')}}</router-link></li>
            <li><router-link to="/app/absences">{{$t('absences')}}</router-link></li>
            <template v-if="isAdmin">
              <li><router-link to="/app/team">{{$t('team')}}</router-link></li>
              <li><router-link to="/app/projects">{{$t('projects')}}</router-link></li>
              <li><router-link to="/app/tasks">{{$t('tasks')}}</router-link></li>
              <li>
                <router-link to="/app/confirm"
                  :class="{'text-red-800 bg-red-200 font-bold': hasOpenConfirmation}"
                >
                  {{$t('confirm')}}
                </router-link>
              </li>
            </template>
          </ul>
        </div>
        <div class="md:hidden text-gray-800 text-2xl">{{team.name}}</div>
        <AccountDropdown @show-change-profile="showChangeProfile=true" @show-change-password="showChangePassword=true"/>
      </div>
    </div>
  </div>

  <div>
    <div class="container mx-auto px-4">
        <div v-if="isLoading" class="backdrop-filter backdrop-blur-sm fixed z-30 top-0 left-0 h-full w-full bg-white bg-opacity-50 flex items-center justify-center">
          <svg class="animate-spin -ml-1 mr-3 h-12 w-12 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
        <router-view :key="$route.path"></router-view>
    </div>
  </div>
  <template v-if="isLoggedIn && team" >
    <ChangePasswordView :open="showChangePassword" @on-modal-closed="onModalClosed"/>
    <ChangeProfileView :open="showChangeProfile" :email="user.email" :name="user.name" @on-modal-closed="onModalClosed"/>
  </template>
  <div class="absolute bottom-0 mb-20 w-full">
    <div v-for="(notify, index) in notifications" :key="index" class="flex justify-center">
      <Alert :notify="notify" :index="index"></Alert>
    </div>
  </div>
  </div>
  <FooterView></FooterView>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChangePasswordView from './profile/ChangePasswordView.vue'
import ChangeProfileView from './profile/ChangeProfileView.vue'
import FooterView from './FooterView.vue'
import AccountDropdown from '@/components/AccountDropdown'
import SamayLogo from '@/components/SamayLogo'
import Alert from '@/components/Alert'
import dayjs from 'dayjs'
export default {
  components: {
    SamayLogo,
    ChangePasswordView,
    ChangeProfileView,
    FooterView,
    AccountDropdown,
    Alert
  },
  data () {
    return {
      showChangePassword: false,
      showChangeProfile: false
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      trialExpired: 'trialExpired',
      team: 'team',
      isAdmin: 'isAdmin',
      notifications: 'notifications',
      isLoggedIn: 'isLoggedIn',
      user: 'user',
      hasOpenConfirmation: 'worktimes/hasOpenConfirmation'
    }),
    trialText () {
      const daysleft = dayjs(this.team.trialEnds).diff(dayjs(), 'days') + 1
      if (this.isAdmin) {
        return this.$tc('trial-text-admin', daysleft, { count: daysleft })
      } else {
        return this.$tc('trial-text', daysleft, { count: daysleft })
      }
    }
  },
  methods: {
    ...mapActions({
      logoutUser: 'logoutUser',
      checkOpenConfirmation: 'worktimes/checkOpenConfirmation'
    }),
    onLogout () {
      this.logoutUser(this.$router)
    },
    onModalClosed () {
      this.showChangePassword = false
      this.showChangeProfile = false
    },
    onError (error) {
      console.log(error)
      return false
    }
  },
  mounted () {
    // if (this.trialExpired && !this.team.activated && this.$route.path !== '/app/settings') {
    //  this.$router.replace('/app/settings')
    // }
    this.checkOpenConfirmation()
  },
  beforeRouteUpdate (to, from, next) {
    if (this.isAdmin) {
      this.checkOpenConfirmation()
    }
    next()
  }
}
</script>
