<template>
  <div>
    <div class="shrink border-b-2 border-gray-400 mb-3 pb-2">
      <div
        class="
          cursor-pointer
          inline-block
          px-4
          py-1
          font-bold
          text-gray-800
          rounded-full
          hover:bg-primary hover:bg-opacity-25 hover:text-primary
        "
        @click="activeTab = 1"
        :class="{ 'text-primary': activeTab === 1 }"
      >
        {{ $t("user-account") }}
      </div>
      <div
        class="
          cursor-pointer
          inline-block
          px-4
          py-1
          font-bold
          text-gray-800
          rounded-full
          hover:bg-primary hover:bg-opacity-25 hover:text-primary
        "
        @click="activeTab = 2"
        :class="{ 'text-primary': activeTab === 2 }"
      >
        {{ $t("target-times") }}
      </div>
    </div>
    <div v-if="activeTab === 1" class="grow mx-4">
      <span class="text-4xl">{{ $t("user-account") }}</span>
      <div
        v-if="trialExpired && !accountActive"
        class="
          fixed
          w-full
          h-full
          top-0
          left-0
          flex
          items-center
          justify-center
          z-50
        "
      >
        <div
          class="
            absolute
            w-full
            h-full
            bg-gradient-to-br
            from-primary
            to-secondary
          "
        ></div>
        <div
          class="
            bg-white
            dark:bg-black
            w-11/12
            md:max-w-md
            mx-auto
            rounded
            shadow-lg
            z-50
            overflow-y-auto
            p-5
            -mt-32
            text-center
            flex flex-col
            items-center
            justify-center
          "
        >
          <SamayLogo />
          <div class="text-3xl font-bold mt-3">{{ $t("trial-expired") }}</div>
          <img
            class="my-5"
            src="https://media.giphy.com/media/3oEjI80DSa1grNPTDq/giphy.gif"
          />
          <div v-html="$t('trial-expired-text')"></div>
          <button
            v-if="!accountActive"
            role="link"
            type="button"
            class="primary w-full mt-4"
            @click="createAccount"
          >
            {{ $t("create-subscription") }}
          </button>

          <button
            v-if="!accountActive"
            role="link"
            type="button"
            class="primary w-full mt-4"
            @click="removeAccount"
          >
            {{ $t("remove-account") }}
          </button>

          <div
            class="
              text-primary
              hover:underline
              cursor-pointer
              font-semibold
              mt-6
            "
            @click="logoutUser($router)"
          >
            {{ $t("logout") }}
          </div>
        </div>
      </div>
      <template v-if="accountActive">
        <h3 class="font-semibold">{{ $t("abo") }}</h3>
        <div class="border-t border-b border-gray-400 text-sm px-2 py-4 mb-10">
          <div v-if="isLoading || !abo" class="animate-pulse flex space-x-4">
            <div class="flex-1 space-y-2 py-1">
              <div class="h-4 bg-gray-400 rounded w-32"></div>
              <div class="h-4 bg-gray-400 rounded w-6/12"></div>
            </div>
          </div>
          <template v-else>
            <div class="mb-1 flex">
              <span class="font-semibold">{{
                $t("account-info-payment", { quantity: abo.quantity })
              }}</span>
              <span
                v-if="abo.status === 'active'"
                class="
                  bg-green-200
                  text-green-700
                  py-0
                  flex
                  items-center
                  rounded
                  ml-2
                  px-1
                  text-xs
                "
                >{{ $t("active") }}</span
              >
              <span
                v-else
                class="
                  bg-gray-300
                  text-gray-700
                  py-0
                  flex
                  items-center
                  rounded
                  ml-2
                  px-1
                  text-xs
                "
                >{{ $t("canceled") }}</span
              >
              <span
                v-if="abo.cancel_at"
                class="
                  bg-gray-300
                  text-gray-700
                  py-1
                  rounded
                  ml-2
                  px-2
                  text-xs
                  flex
                  items-center
                  space-x-1
                "
              >
                <svg
                  aria-hidden="true"
                  class="
                    SVGInline-svg SVGInline--cleaned-svg
                    SVG-svg
                    Icon-svg Icon--clock-svg Icon-color-svg
                    Icon-color--gray500-svg
                  "
                  height="12"
                  width="12"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm1-8.577V4a1 1 0 1 0-2 0v4a1 1 0 0 0 .517.876l2.581 1.49a1 1 0 0 0 1-1.732z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span>{{ $t("cancel-at", { cancel_at: abo.cancel_at }) }}</span>
              </span>
            </div>
            <div v-if="upcoming" class="text-gray-600">
              {{
                $t("invoice-infos", {
                  nextPayment: upcoming.nextPayment,
                  amount: upcoming.amount,
                  currency: upcoming.currency,
                })
              }}
            </div>
            <div v-else class="text-gray-600">
              {{ $t("no-upcoming-text") }}
            </div>
          </template>
        </div>
        <h3 class="font-semibold">{{ $t("payment-details") }}</h3>
        <div class="border-t border-b border-gray-400 text-sm py-6 px-2">
          <div v-if="isLoading || !card" class="animate-pulse flex space-x-4">
            <div class="h-10 bg-gray-400 rounded w-16"></div>
            <div class="flex-1 space-y-2">
              <div class="h-4 bg-gray-400 rounded w-32"></div>
              <div class="h-4 bg-gray-400 rounded w-32"></div>
            </div>
          </div>
          <template v-else>
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <div><img :src="imageCardBrand" width="70" height="40" /></div>
                <div class="ml-4">
                  <div class="font-semibold">
                    <span class="capitalize">{{ card.brand }}</span> ••••
                    {{ card.last4 }}
                  </div>
                  <div class="text-gray-600">
                    {{
                      $t("expired-at", {
                        cardExprMonth: cardExprMonth,
                        exp_year: card.exp_year,
                      })
                    }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <p class="mt-10">
          {{ $t("abo-hint") }}
        </p>
        <button
          @click="manageSubscription()"
          class="primary whitespace-nowrap w-full"
        >
          {{ $t("manage-subscription") }}
        </button>
      </template>
      <template v-else>
        <button
          v-if="!accountActive"
          role="link"
          type="button"
          class="primary w-full mt-4"
          @click="createAccount"
        >
          {{ $t("create-subscription") }}
        </button>

        <button
          v-if="!accountActive"
          role="link"
          type="button"
          class="bg-red-600 text-white w-full mt-4"
          @click="removeAccount"
        >
          {{ $t("remove-account") }}
        </button>
      </template>

      <div id="error-message"></div>
    </div>
    <div v-if="activeTab === 2" class="grow mx-4">
      <TargetTime></TargetTime>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TargetTime from '@/views/settings/TargetTime'
import SamayLogo from '@/components/SamayLogo'
import dayjs from 'dayjs'

export default {
  components: {
    TargetTime,
    SamayLogo
  },
  data () {
    return {
      activeTab: 1,
      upcoming: null,
      abo: null,
      showPaymentModal: false,
      loading: 0,
      card: null
    }
  },
  computed: {
    ...mapGetters({
      app: 'app',
      user: 'user',
      team: 'team',
      account: 'teams/account',
      users: 'users/all',
      addNotification: 'addNotification',
      trialExpired: 'trialExpired'
    }),
    isLoading () {
      return this.loading > 0
    },
    imageCardBrand () {
      if (
        this.card &&
        (this.card.brand.toLowerCase() === 'visa' ||
          this.card.brand.toLowerCase() === 'mastercard')
      ) {
        return require('@/assets/' + this.card.brand.toLowerCase() + '.svg')
      }
      return ''
    },
    activeUsers () {
      return this.users.length
    },
    accountActive () {
      return this.team.activated && this.account
    },
    cardExprMonth () {
      return this.card
        ? dayjs(`${this.card.exp_month}`, 'M')
          .locale(this.$i18n.locale)
          .format('MMM')
        : ''
    }
  },
  methods: {
    ...mapActions(['setTeam', 'loadingBegin', 'loadingEnd', 'logoutUser']),
    manageSubscription () {
      this.app.currentUser.functions
        .stripeBillingPortal(
          this.account._id,
          process.env.VUE_APP_URL + '/app/settings'
        )
        .then((url) => {
          window.location = url
        })
    },
    removeAccount () {
      const name = prompt('Please enter your DELETE ALL:', '')
      if (name === 'DELETE ALL') {
        if (confirm('Are you sure?')) {
          this.app.currentUser.functions.removeAccount().then((result) => {
            alert('Done!').then(t => {

            })
            this.logoutUser()
          }).catch((error) => {
            this.addNotification({ message: error.error, error: true })
          })
        }
      }
    },
    createAccount () {
      const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK, {
        locale: this.$i18n.locale
      })
      stripe
        .redirectToCheckout({
          lineItems: [
            {
              price: process.env.VUE_APP_STRIPE_SUBSCRIPTION_PLAN,
              quantity: this.activeUsers
            }
          ],
          mode: 'subscription',
          customerEmail: this.user.email,
          clientReferenceId: this.team._id.toString(),
          successUrl: process.env.VUE_APP_URL + '/app/settings',
          cancelUrl: process.env.VUE_APP_URL + '/app/settings'
        })
        .then(function (result) {
          if (result.error) {
            const displayError = document.getElementById('error-message')
            displayError.textContent = result.error.message
          }
        })
    },
    fetchUpcoming () {
      this.upcoming = null
      this.loading++
      this.app.currentUser.functions
        .stripeUpcomingInvoice(this.account._id)
        .then((result) => {
          this.upcoming = {
            amount: result.amount,
            nextPayment: dayjs.unix(result.nextPayment).format('DD.MM'),
            currency: result.currency
          }
          this.loading--
        })
        .catch((error) => {
          console.log(error)
          this.loading--
        })
    },
    fetchStripeAccountInfos (value) {
      if (value) {
        this.loading++
        this.app.currentUser.functions
          .stripeSubscriptions(this.account.stripeSubscriptionId)
          .then((result) => {
            this.loading--
            this.abo = {
              quantity: result.quantity,
              status: result.status,
              cancel_at: result.cancel_at
                ? dayjs.unix(result.cancel_at).format('DD.MM')
                : null,
              ended_at: result.ended_at
                ? dayjs.unix(result.ended_at).format('DD.MM')
                : null
            }
            if (!result.cancel_at && !result.ended_at) {
              this.fetchUpcoming()
            } else {
              this.upcoming = null
            }
          })
          .catch((error) => {
            console.log(error)
            this.loading--
          })
        this.loading++
        this.app.currentUser.functions
          .stripeCard(this.account._id)
          .then((result) => {
            this.loading--
            this.card = result.card
          })
          .catch((error) => {
            console.log(error)
            this.loading--
          })
      }
    }
  },
  watch: {
    accountActive (newValue, oldValue) {
      this.fetchStripeAccountInfos(newValue)
    }
  },
  mounted () {
    this.fetchStripeAccountInfos(this.accountActive)
  },
  beforeRouteEnter (to, from, next) {
    next(async (vm) => {
      await vm.$store.dispatch('teams/load')
      await vm.$store.dispatch('teams/loadAccount')
      await vm.$store.dispatch('targettimes/load')
    })
  }
}
</script>
